import { CustomButton } from 'components/UI'
import { CustomForm } from 'components/UI'
import { formatNumber } from 'components/UI/helpers'
import React from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Card, CardTitle } from 'reactstrap'
import { _updateApi } from 'redux/actions/api'
import { CURRENCY } from 'variables'

function LoanSettings() {
  const { society } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(society)

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }))

  const handleSubmit = () => {
    setLoading(true)
    _updateApi(
      '/societies/info',
      form,
      (resp) => {
        setLoading(false)
        if (resp.success) {
          toast.success(resp.message)
        } else {
          toast.error(resp.message)
        }
      },
      (e) => {
        setLoading(false)
        toast.error(e.message)
      },
    )
  }

  return (
    <Card body>
      <CardTitle className="text-center h4 mb-2">Loan Setup</CardTitle>

      <CustomForm
        handleChange={handleChange}
        fields={[
          {
            label: `Maximum Loan Type`,
            type: 'select',
            options: ['Fixed', 'Percentage'],
            name: 'max_loan_amount_type',
            value: form.max_loan_amount_type,
            col: 6,
          },
          {
            label:
              form.max_loan_amount_type === 'Percentage'
                ? `${form.max_loan_amount}% Percentage of Savings`
                : `Amount (${CURRENCY} ${formatNumber(form.max_loan_amount)})`,
            name: 'max_loan_amount',
            value: form.max_loan_amount,
            col: 6,
          },
          {
            label: 'Loan Eligibility Period (in months)',
            name: 'loan_membership_eligibility',
            value: form.loan_membership_eligibility,
            col: 12,
          },
          {
            label: 'Select Loan Interest Deduction Option',
            type: 'select',
            name: 'loan_interest_option',
            options: [
              'Upfront Deduction',
              'Upfront Payment',
              'Monthly Deduction',
            ],
            value: form.loan_interest_option,
            col: 12,
          },
        ]}
      />
      <center>
        <CustomButton loading={loading} size="sm" onClick={handleSubmit}>
          Save Changes
        </CustomButton>
      </center>
    </Card>
  )
}

export default LoanSettings
