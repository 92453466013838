import React, { Component } from "react";
import "react-sortable-tree/style.css";
import SortableTree from "react-sortable-tree";
import Loading from "./Loading";
// import Loading from '../Loading';

class Tree extends Component {
  constructor() {
    super();
    this.state = {
      treeData: [],
    };
  }

  static defaultProps = {
    treeData: [],
    generateNodeProps: (f) => f,
    treeLoading: false,
  };

  componentDidMount() {
    this.setState({ treeData: this.props.treeData });
  }

  componentWillReceiveProps(next) {
    this.setState({ treeData: next.treeData });
  }

  render() {
    const { searchString, searchFocusIndex, searchFoundCount } = this.state;

    const customSearchMethod = ({ node, searchQuery }) =>
      searchQuery &&
      node.description.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    const selectPrevMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      });

    const selectNextMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      });

    if (this.props.treeLoading) {
      return <Loading />;
    }
    return (
      <div style={{ height: 500 }}>
        <SortableTree
          canDrag={false}
          treeData={this.state.treeData}
          onChange={(treeData) => this.setState({ treeData })}
          generateNodeProps={this.props.generateNodeProps}
          searchMethod={customSearchMethod}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={(matches) =>
            this.setState({
              searchFoundCount: matches.length,
              searchFocusIndex:
                matches.length > 0 ? searchFocusIndex % matches.length : 0,
            })
          }
          isVirtualized={false}
        />
      </div>
    );
  }
}

export default Tree;
