import { _warningNotify } from "utils/helpers";
import { _customNotify } from "utils/helpers";
import store from "../store";
import {
  GET_CLIENT_INFO,
  GET_CLIENT_STATEMENT,
  GET_DRUG_LIST,
  GET_INVENTORY_STORE,
  GET_PURCHASE_ITEM,
  GET_STOCK_INFO,
  GET_STOCK_INFO_STORE,
  GET_STOCK_INFO_STORE_SHELF,
  GET_SUPPLIER_INFO,
  GET_SUPPLIER_STATEMENT,
  GET_TOP_SALES,
  INVENTORY_LOADING,
  INVENTORY_USER,
  RECEIPT_DATA,
  SALES,
  SUPPLIER_COUNT,
  GET_REORDER_LEVEL,
  GET_EXPIRY_ALERT,
  GET_TOTAL_DRUG,
  SET_PENDING_INVENTORY_REQUEST,
  OUT_OF_STOCK_LIST,
} from "./types";
import {
  GETTING_ALL_SUPPLIERS,
  GET_ALL_SUPPLIERS,
  LOAD_EXPIRED,
  LOAD_EXPIRY_ALERT,
  LOAD_QTTY_ALERT,
  SUBMIT_DRUG_LOADING,
} from "./types";
import { uniqueNum } from "variables";
const { _fetchApi, _postApi } = require("./api");
export const inventoryDB = "";

export function getPendinginventoryRequests(
  { status = "request", from = "", to = "" },
  callback = (f) => f,
  error = (f) => f
) {
  return (dispatch) => {
    let route = `prescriptions/pending/${status}?from=${from}&to=${to}`;
    let success_callback = (data) => {
      // console.log(data)
      callback(data);
      if (data && data.results) {
        dispatch({
          type: SET_PENDING_INVENTORY_REQUEST,
          payload: data.results,
        });
      }
    };
    let error_callback = (err) => {
      console.log(err);
      error(err);
    };
    _fetchApi(`/${route}`, success_callback, error_callback);
  };
}

const baseAPI = `/drugs`;
export function init() {
  return (dispatch) => {
    // dispatch(showLoading('sectionBar'))
    _fetchApi(
      `${baseAPI}/alert/expiry`,
      ({ results }) => {
        if (results) {
          dispatch({ type: LOAD_EXPIRY_ALERT, payload: results });
        }
      },
      (error) => console.log(error)
    );
    _fetchApi(
      `${baseAPI}/alert/quantity`,
      ({ results }) => {
        if (results) {
          dispatch({ type: LOAD_QTTY_ALERT, payload: results });
        }
      },
      (error) => console.log(error)
    );
    _fetchApi(
      `${baseAPI}/expired`,
      ({ results }) => {
        if (results) {
          dispatch({ type: LOAD_EXPIRED, payload: results });
        }
      },
      (error) => console.log(error)
    );
  };
}

export const endpoint = `/inventory`;
export function getSupplierInfo() {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-suppliers`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_SUPPLIER_INFO, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getSupplierCount() {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-supplier-count`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: SUPPLIER_COUNT, payload: res.results[0].num });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getReceiptData(repno, cb = (f) => f) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-receipt-data?repno=${repno}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: RECEIPT_DATA, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
          cb();
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function deleteinventoryUsers(id) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/delete-inventory-users?id=${id}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: INVENTORY_USER, payload: res.results });
          dispatch(getinventoryUser());
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getinventoryUser() {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-inventory-users`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: INVENTORY_USER, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}
export function getPurchaseItem(
  society_id,
  from = "0",
  to = "50",
  cb = (f) => f,
  query_type = ""
) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-purchase-item?facilityId=${society_id}&from=${from}&to=${to}&query_type=${query_type}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_PURCHASE_ITEM, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }

        cb();
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
        cb();
      }
    );
  };
}

export function getinventoryStore(cb = (f) => f) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-inventory-store`;
    _fetchApi(
      url,
      (res) => {
        // console.log(res)
        if (res.success) {
          dispatch({ type: GET_INVENTORY_STORE, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
          cb(res.results);
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getClientInfo() {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-client-info`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_CLIENT_INFO, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getDrugView(
  store,
  item_code,
  from,
  to,
  drug_name,
  expiry_date
) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-drug-view?to=${to}&from=${from}&store=${store}&item_code=${item_code}&drug_name=${drug_name}&expiry_date=${expiry_date}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_PURCHASE_ITEM, payload: res.results });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
// export function getDrugList(society_id) {
//   return (dispatch) => {
//     let url = `${endpoint}/v1/get-drug-list?facilityId=${society_id}&query_type=stock`;
//     _fetchApi(
//       url,
//       (res) => {
//         if (res.success) {
//           dispatch({ type: GET_DRUG_LIST, payload: res.results });
//         }
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   };
// }
// export function getOutOfStock() {
//   return (dispatch) => {
//     let url = `${endpoint}/v1/get-drug-list?query_type=out_of_stock`;
//     _fetchApi(
//       url,
//       (res) => {
//         if (res.success) {
//           dispatch({ type: OUT_OF_STOCK_LIST, payload: res.results });
//         }
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   };
// }

export function getSupplierStatement(from, to, supplier_code) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-supplier-statement?supplier_code=${supplier_code}&to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_SUPPLIER_STATEMENT, payload: res.results });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getPatientAccountView(from, to, acct) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-patient-account-view?acct=${acct}&to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_CLIENT_STATEMENT, payload: res.results });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getStockInfo(from, to) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-stock-info?to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_STOCK_INFO, payload: res.results[0] });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function _getStockInfoStore(from, to) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-stock-n-info-store?to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_STOCK_INFO_STORE, payload: res.results[0] });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getStockInfoShelf(from, to) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-stock-info-shelf?to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({
            type: GET_STOCK_INFO_STORE_SHELF,
            payload: res.results[0],
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getTopSales(from, to) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-top-sales?to=${to}&from=${from}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_TOP_SALES, payload: res.results });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export function getReorderLevel() {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-reorder-level`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: GET_REORDER_LEVEL, payload: res.results });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getSalesDrugs(query_type, from = "0", to = "50") {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-sales-drugs?query_type=${query_type}&from=${from}&to=${to}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: SALES, payload: res.results });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function addSupplierInfo(
  data,
  callBack = (f) => f,
  fallBack = (f) => f
) {
  _postApi(
    `${endpoint}/v1/add-supplier-info`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function addNewinventoryStore(
  data,
  callBack = (f) => f,
  fallBack = (f) => f
) {
  const username = store.getState().auth.user.username;
  const busName = store.getState().facility.info.name;
  _postApi(
    `${endpoint}/v1/add-new-inventory-store?createdBy=${username}&INVENTORY_name=${busName}`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function addNewClent(data, callBack = (f) => f, fallBack = (f) => f) {
  const username = store.getState().auth.user.username;
  const busName = store.getState().auth.user.busName;
  _postApi(
    `${endpoint}/v1/add-new-client?createdBy=${username}&INVENTORY_name=${busName}&userId=${username}`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function addPurchase(
  data,
  user_id,
  society_id,
  callBack = (f) => f,
  fallBack = (f) => f
) {
  const arr = [];
  data.forEach((item) => {
    if (item.item_code === "") {
      arr.push({ ...item, item_code: uniqueNum });
    } else {
      arr.push(item);
    }
  });

  _postApi(
    `${endpoint}/v1/add-purchase?user_id=${user_id}&facilityId=${society_id}`,
    arr,
    (res) => {
      callBack(res);
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function supplierPayment(
  data,
  callBack = (f) => f,
  fallBack = (f) => f
) {
  _postApi(
    `${endpoint}/v1/pay-supplier`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function makeSale(data, callBack = (f) => f, fallBack = (f) => f) {
  _postApi(
    `${endpoint}/v1/batch-selling`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function createUser(data = {}, success = (f) => f, error = (f) => f) {
  return (dispatch) => {
    dispatch({ type: INVENTORY_LOADING, payload: true });

    const user = store.getState().auth.user;
    _postApi(
      `/users/create`,
      { ...data, busName: user.busName, address: user.address },
      (result) => {
        console.log(result);
        if (result.msg) {
          alert(result.msg);
          // error(result);
          dispatch({ type: INVENTORY_LOADING, payload: false });
        } else {
          dispatch({ type: INVENTORY_LOADING, payload: false });
          success();
        }
      },
      (err) => {
        error();
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}

export const searchTransactionByReceipt = (
  receiptNo,
  callback = (f) => f,
  error = (f) => f
) => {
  _fetchApi(
    `${endpoint}/v1/get-reciept-data/?repno=${receiptNo}`,
    (data) => {
      if (data && data.results) {
        callback(data.results);
      }
    },
    (err) => {
      error(err);
      console.log(err);
    }
  );
};

export function batchAddDrugs(data, callback) {
  return (dispatch) => {
    // console.log(data)
    dispatch({ type: SUBMIT_DRUG_LOADING });
    _postApi(
      `${baseAPI}/new/batch`,
      data,
      () => {
        // dispatch({ type: ADD_DRUG, payload: data })
        dispatch({ type: SUBMIT_DRUG_LOADING });
        _customNotify("Record Saved!");
        callback();
      },
      (err) => {
        dispatch({ type: SUBMIT_DRUG_LOADING });
        _warningNotify("An error occured!");
        console.log(err);
      }
    );
  };
}

export function getAllSuppliers() {
  return (dispatch) => {
    dispatch({ type: GETTING_ALL_SUPPLIERS });
    // inventoryDB
    //   .get('suppliers')
    //   .then(({ suppliers }) => {
    //     dispatch({ type: GET_ALL_SUPPLIERS, suppliers });
    //   })
    //   .catch(err => console.log(err));

    _fetchApi(
      `${baseAPI}/supplier/all`,
      ({ results }) => {
        if (results) {
          // console.log(results)
          dispatch({ type: GET_ALL_SUPPLIERS, suppliers: results });
          inventoryDB
            .get("suppliers")
            .then(({ _rev }) => {
              inventoryDB
                .put({ _id: "suppliers", _rev, suppliers: results })
                .then(() => console.log("update suppliers"))
                .catch((err) => console.log(err));
            })
            .catch(() => {
              inventoryDB
                .put({ _id: "suppliers", suppliers: results })
                .then(() => {
                  console.log("added to suppliers");
                })
                .catch((err) => console.log(err));
            });
        } else {
          dispatch({ type: GETTING_ALL_SUPPLIERS });
        }
      },
      (err) => {
        dispatch({ type: GETTING_ALL_SUPPLIERS });
        // _warningNotify("An error occured!");
        console.log(err);
      }
    );
  };
}

export function getAllReport(setter, from, to, query_type) {
  return (dispatch) => {
    _fetchApi(
      `${endpoint}/v1/get-all-report?from=${from}&to=${to}&query_type=${query_type}`,
      (data) => {
        // console.log({DDATTA:data})
        if (data && data.results) {
          dispatch({ type: "REPORT_LIST", payload: data.results });
          setter(data.results);
        }
      },
      (error) => {
        console.error({ error });
      }
    );
  };
}

export function getAllDrugs(success = (f) => f) {
  return (dispatch) => {
    _fetchApi(
      `${endpoint}/v1/get-all-drug-names`,
      (data) => {
        if (data && data.results) {
          dispatch({ type: GET_DRUG_LIST, payload: data.results });
          success(data.results);
        }
      },
      (error) => {
        console.error({ error });
      }
    );
  };
}

export function searchDrugSale(
  query_type,
  from,
  to,
  searchValue = "",
  callback = (f) => f
) {
  return (dispatch) => {
    let url = `${endpoint}/v1/get-sales-drugs-search?searchValue=${searchValue}&query_type=${query_type}&from=${from}&to=${to}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          dispatch({ type: SALES, payload: res.results });
          callback(res.results);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
// None dispatchable
export function getSupplier(
  { supplier_code },
  success = (f) => f,
  error = (f) => f
) {
  let url = `${endpoint}/v1/get-supplier?supplier_code=${supplier_code}&others=`;
  _fetchApi(
    url,
    (res) => {
      if (res.success) {
        success(res.results);
      }
    },
    (err) => {
      console.error(err);
      error(err);
    }
  );
}

export function getDrugSearch(searchValue, from, to) {
  return (dispatch) => {
    // console.log('searching',searchValue, from, to)
    dispatch({ type: INVENTORY_LOADING, payload: true });
    let url = `${endpoint}/v1/get-drug-search?searchValue=${searchValue}&from=${from}&to=${to}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          const arr = [];
          res.results &&
            res.results.forEach((state) => {
              arr.push({ ...state, enable: false });
            });
          dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
          dispatch({ type: INVENTORY_LOADING, payload: false });
        }
      },
      (err) => {
        console.log(err);
        dispatch({ type: INVENTORY_LOADING, payload: false });
      }
    );
  };
}
export function getTotalDrug(filterText = "") {
  return (dispatch) => {
    let url = `/${endpoint}/v1/get-total-drug?filterText=${filterText}`;
    _fetchApi(
      url,
      (res) => {
        if (res.success) {
          console.log(res);
          dispatch({
            type: GET_TOTAL_DRUG,
            payload: res.results[0].totalDrugs,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function updateStock(data, callBack = (f) => f, fallBack = (f) => f) {
  _postApi(
    `${endpoint}/v1/update-stock`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}

export function drugList(data, callBack = (f) => f, fallBack = (f) => f) {
  _postApi(
    `/drug-list/new`,
    data,
    (res) => {
      console.log(res);
      if (res.success) {
        callBack(res);
      }
    },
    (err) => {
      fallBack(err);
    }
  );
}
