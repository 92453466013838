import types from "../actions/types";
const defaultState = {
  "Total Contribution Summary": {},
  "Loans Summary": {},
  "Special contribution Summary": {},
  "Special contribution list Summary": [],
  "Total Contribution Graph": {},
  "Loans Graph": {},
  "Special contribution Graph": {},
  "Total Contribution": [],
  Loans: [],
  "Special contribution": [],
};

const reportsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.dashboard.UPDATE_DASHBOARD_SUMMARY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.dashboard.UPDATE_DASHBOARD_GRAPH:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.dashboard.REPORT_BY_TYPE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.dashboard.LOAN_REPORT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};

export default reportsReducer;
