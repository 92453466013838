import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import BackButton from "components/UI/BackButton";
import CustomModal from "components/UI/CustomModal";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { today } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import Loading from "components/UI/Loading";
import StatusBadge from "components/UI/StatusBadge";
import { useQuery } from "hooks";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Alert, Badge, Card, CardTitle, Col, Row } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import { getReports } from "redux/actions/reports";
import { CURRENCY } from "variables";
import { EmptyList } from "../Admin/ReportDetails";
import { getRoleLink } from "views/helper";
import validate from "helpers/useValidate";

function MessagesList() {
  const query = useQuery();
  const report_type = query.get("report_type");
  // const from = query.get('from')
  const to = query.get("to");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const today = moment().format("YYYY-MM-DD");
  const _from = moment(today).subtract(6, "day").format("YYYY-MM-DD");
  const isMemberPage = location.pathname.includes("/member/messages");
  const { society, user } = useSelector((state) => state.auth);

  const [list, setList] = useState([]);
  const [approving, setApproving] = useState(false);
  const [requestInfo, setRequestInfo] = useState({});
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState({
    from: _from,
    to: today,
  });

  const handleDateRangeChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));

  const toggleRequestModal = () => setRequestModalIsOpen((a) => !a);


  const getMessages = 
  useCallback(
    () => {
    setLoading(true);
    if (isMemberPage){
       _fetchApi(
         `/messages?query_type=select-member&society_id=${society.id}&member_id=${user.id}&from=${range.from}&to=${range.to}`,
         (d) => {
           if (d && d.data && d.data.length) {
             console.log(d.data);
             setList(d.data);
             setLoading(false);
           }
         },
         (e) => {
           console.log(e);
           setLoading(false);
         }
       );
    }
    else{
      _fetchApi(
        `/messages?query_type=select-admin&society_id=${society.id}&admin_id=${user.id}&from=${range.from}&to=${range.to}`,
        (d) => {
          if (d && d.data && d.data.length) {
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          console.log(e);
          setLoading(false);
        }
      );
    }

    setLoading(false);
  }
  , [society.id, user.id, range]);

  useEffect(() => {
    getMessages();
  }
  , []);

  const fields = [
    { title: "From", value: "member_name", hidden: isMemberPage },
    { title: "To", value: "admin_name", hidden : !isMemberPage },
    {
      title: `Message`,
      value: "message",
    },
    { title: "Status", value: "type" },

    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            onClick={() => {
              if (isMemberPage) {
                history.push(
                  getRoleLink(
                    `/messages/view?member_id=${item.admin_id}`
                  )
                );
              } else {
                history.push(
                  getRoleLink(`/messages/view?member_id=${item.member_id}`)
                );
              }
            }}
          >
            View
          </CustomButton>
          {/* {!isMemberPage && (
            <CustomButton
              size="sm"
              loading={approving}
              onClick={() => handleApproval(item)}
            >
              Approve
            </CustomButton>
          )} */}
        </div>
      ),
    },
  ];
  return (
    <>
      <Card body>
        {isMemberPage ? (
          <CustomButton
            className="px-6 mb-3"
            onClick={() => history.push(getRoleLink("/messages/new"))}
          >
            New Message
          </CustomButton>
        ) : null}
        <CardTitle className="h4 text-center mb-2">Messages</CardTitle>
        {/* <DaterangeSelector
          from={range.from}
          to={range.to}
          handleChange={handleDateRangeChange}
        /> */}
        {/* {JSON.stringify(list)} */}
        <CustomTable fields={fields} data={list} />
        {list.length ? null : (
          <EmptyList message="There are no Messages, check back later." />
        )}
        {loading && <Loading />}
        <ViewMessage
          isOpen={requestModalIsOpen}
          toggle={toggleRequestModal}
          info={requestInfo}
        />
      </Card>
      <Toaster top="center" />
    </>
  );
}

function ViewMessage({
  isOpen = false,
  toggle = (f) => f,
  info = {},
}) {
  const tableFields = [{ title: "Account Name" }, { title: "Amount" }];
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      header="View Message"
    >
      <Row>
        <Col md={4}>
          <div>Date:</div>
          <div>{info.created_at}</div>
        </Col>
        <Col md={4}>
          <div>Member Name:</div>
          <div>{info.member_name}</div>
        </Col>
        <Col md={4}>
          <div>Total Amount:</div>
          <div>{info.total_amount}</div>
        </Col>
        <Col md={4}>
          <div>Status:</div>
          <div>{info.req_status}</div>
        </Col>

        {/* {JSON.stringify(info)} */}
      </Row>

      <h4>Details</h4>
      <CustomTable fields={tableFields} />
    </CustomModal>
  );
}

export default MessagesList;
