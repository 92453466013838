import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "components/UI/CustomTable";
import { AiFillEye } from "react-icons/ai";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory } from "react-router";
import { BorderedCard } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import { useSelector } from "react-redux";
import { formatNumber } from "components/UI/helpers";
import moment from "moment";
import { Toaster } from "react-hot-toast";
import { EmptyList } from "../Admin/ReportDetails";
import StatusBadge from "components/UI/StatusBadge";
import { getRoleLink } from "views/helper";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { today } from "utils/helpers";

export default function PendingLoans() {
  const history = useHistory();
  const society = useSelector((state) => state.auth.society);

  const [pendingLoanList, setPendingLoanList] = useState([]);
  const [dateInfo, setDateInfo] = useState({ from: today, to: today });

  const handleChange = ({ target: { name, value } }) => {
    setDateInfo((p) => ({ ...p, [name]: value }));
  };

  const getPendingLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/by-status/pending/${society.id}?from=${dateInfo.from}&to=${dateInfo.to}`,
      (d) => {
        if (d && d.data) {
          setPendingLoanList(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society, dateInfo]);

  useEffect(() => {
    getPendingLoanList();
  }, [getPendingLoanList]);

  const approveLoan = () => {};

  const rejectLoan = () => {};

  const fields = [
    {
      title: "Application Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Loan No", value: "loan_id" },
    { title: "Applicant Name", value: "member_name" },
    {
      title: "Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.amount?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Total Interest",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.total_interest?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Total Admin Charges",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.total_admin_charge?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (i) => (
        <div className="text-right">
          <StatusBadge status={i.status} />
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <Button
            size="sm"
            color="info"
            className="mr-1"
            onClick={() => {
              if (i.status?.toLowerCase() === "pending") {
                history.push(
                  getRoleLink("/loan_approval?loan_id=" + i.loan_id)
                );
              } else {
                history.push(
                  getRoleLink("/loan/my-loan-details?loan_id=" + i.loan_id)
                );
              }
            }}
          >
            VIEW DETAILS
          </Button>
          {/* <Button size='sm' color='success' className='mr-1'
            onClick={() => approveLoan(i)}
          >
            APPROVE
          </Button>
          <Button size='sm' color='danger'
            onClick={() => rejectLoan(i)}
          >
            REJECT
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <>
      <Card body>
        <CardTitle className="h4 text-center">
          Pending Loans Applications
        </CardTitle>
        <SearchBar placeholder="Search for a member" className="mt-1" />
        {/* {JSON.stringify(pendingLoanList)} */}
        <DaterangeSelector
          from={dateInfo.from}
          to={dateInfo.to}
          handleChange={handleChange}
        />

        <CustomTable fields={fields} data={pendingLoanList} size="sm" />
        {pendingLoanList.length ? null : (
          <EmptyList message="There are no pending loans, check back later." />
        )}
      </Card>
    </>
  );
}
