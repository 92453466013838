import { error } from "jquery";
import { _fetchApi } from "redux/actions/api";

export function getTransactionHistory(
  q,
  callback = (f) => f,
  error = (f) => f
) {
  _fetchApi(
    `/user-transaction-history?${Object.keys(q)
      .map((k) => k + "=" + q[k])
      .join("&")}`,
    callback,
    error
  );
}

export function getSummaryTransaction(query, callback, error) {
  _fetchApi(
    `/summary-transaction-history?${Object.keys(query)
      .map((k) => k + "=" + query[k])
      .join("&")}`,
    callback,
    error
  );
}
