import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTransactionHistory } from "./api";
import moment from "moment";
import { Card, CardTitle } from "reactstrap";
import { CustomTable } from "components/UI";
import { SelectInput } from "components/UI";
import { formatNumber } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import MemberListInput from "../utilities/MemberListInput";
import { useDispatch } from "react-redux";
import { getMembers } from "redux/actions/society";
import { ExportCSV } from "../utilities/ExportCSV";
import DaterangeSelector from "components/UI/DaterangeSelector";

function TransactionHistory() {
  const today = moment().format("YYYY-MM-DD");
  const aYearAgo = moment(today).subtract(1, "year").format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const {
    auth: { user, society },
    society: { members },
  } = useSelector((s) => s);

  const [list, setList] = useState([]);
  const [form, setForm] = useState({
    user_id: user.id,
    from: aYearAgo,
    to: today,
  });

  const handleRange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [selectMembers, setSelectMembers] = useState({});
  const [selectDec, setSelectDec] = useState([]);

  useEffect(() => {
    if (!Object.keys(selectMembers).length) {
      setSelectMembers({ ...user, user_id: user.id });
    }
  }, [form.user_id]);

  const getHistory = useCallback(() => {
    getTransactionHistory(
      {
        society_id: society.id,
        user_id: selectMembers.id ? selectMembers.id : user.id,
        from: form.from,
        to: form.to,
        description: form.account_description,
      },
      (d) => {
        setList(d.data);
        const desc = d?.data?.map((e) => e?.account_description);
        var unique = desc.filter(
          (value, index, array) => array.indexOf(value) === index
        );
        setSelectDec(unique);
      }
    );
  }, [society, selectMembers.id, form.to, form.from, form.account_description]);

  const _getMembers = useCallback(() => {
    dispatch(getMembers(society.id));
  }, [society.id]);

  useEffect(() => {
    getHistory();
    _getMembers();
  }, [getHistory, getHistory]);

  const tableFields = [
    {
      title: "Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Name", value: "member_name" },
    { title: "Description", value: "account_description" },
    {
      title: "Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(
            i.credit.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (i) => (
        <div className="text-center">
          <StatusBadge status={i.status} />
        </div>
      ),
    },
  ];

  const rows = [];
  list?.forEach((p) => {
    if (
      p.account_description &&
      p.account_description
        .toLowerCase()
        .indexOf(form.account_description?.toLowerCase()) === -1
    )
      return;
    else rows.push(p);
  });
  const data = form.account_description ? rows : list;
  const total =
    data && data.reduce((item, id) => item + parseFloat(id.credit), 0);

  const groupedData = data.reduce((acc, current) => {
    const key = current.account_description;
    const amount = current.credit;

    if (!acc[key]) {
      // If the group doesn't exist, create it and initialize the total
      acc[key] = { key, totalAmount: 0 };
    }

    // Add the current amount to the total for the group
    acc[key].totalAmount += amount;

    return acc;
  }, {});

  // Convert the grouped data object back to an array
  const groupedBal = Object.values(groupedData).filter(
    (grouped) => grouped.totalAmount !== 0
  );

  let fullName =
    selectMembers.firstname +
    " " +
    selectMembers.lastname +
    " " +
    selectMembers.middlename;

  return (
    <>
      {/* {JSON.stringify(fullName)} */}
      <Card body>
        <CardTitle className="text-center h4 mb-2">
          <div className="row">
            <div className="col-md-3">Transaction History</div>
            <div className="col-md-3">
              <SelectInput
                first_item="Filter by"
                // style={{ width: society.role === "Admin" ? "100%" : "170%" }}
                placeholder="Account Description"
                options={selectDec || []}
                onChange={(e) =>
                  setForm((p) => ({
                    ...p,
                    account_description: e.target.value,
                  }))
                }
                value={form.account_description}
                name="account_description"
                width={250}
              />
            </div>
            <div className="col-md-3">
              {" "}
              {society.role === "Admin" && (
                <div className="m-2">
                  <MemberListInput
                    label={false}
                    value={form.user_id}
                    onChange={(e) => {
                      let value = e.target.value;
                      const selected = members.find((e) => e.user_id === value);

                      setForm((p) => ({ ...p, user_id: value }));
                      setSelectMembers({ ...selected, id: selected.user_id });
                    }}
                  />
                </div>
              )}
              <div />
            </div>
            <div className="col-md-3">
              <ExportCSV
                csvData={data}
                fileName={`${fullName} transactions history from ${form.from} - ${form.to}`}
              />
            </div>
          </div>
        </CardTitle>
        <div className="row border rounded p-2">
          {groupedBal?.map((item, id) => (
            <div className="col-md-3" key={item.key}>
              {item.key}: {formatNumber(item.totalAmount)}
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-8">
            <DaterangeSelector
              from={form.from}
              to={form.to}
              handleChange={handleRange}
              space={false}
            />
          </div>
          <div className="col-md-4 text-right mt-3">
            Total: <b>{formatNumber(total)}</b>
          </div>
        </div>

        <CustomTable fields={tableFields} data={data || []} />
      </Card>
    </>
  );
}

export default TransactionHistory;
