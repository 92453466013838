import types from "redux/actions/types";
const defaultState = {
  user: {},
  society: {},
  errors: [],
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.auth.AUTH_USER:
      return {
        ...state,
        user: action.payload.user,
        societies: action.payload.societies,
        society: action.payload.societies[0],
        config_key: action.payload.config_key,
      };
    case types.auth.UPDATE_ROLE:
      return {
        ...state,
        society: { ...state.society, role: action.payload },
      };

      case types.auth.UPDATE_USER:
        return {
          ...state,
          society: action.payload,
        };

      
    case types.auth.AUTH_SOCIETY:
      return { ...state, errors: [...state.errors, action.payload] };
    case types.auth.LOGOUT:
      return { ...state, user: {} };
    default:
      return state;
  }
};

export default authReducer;
