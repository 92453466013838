import { Switch, Route } from "react-router";
import BankDetailsList from "../bank-details/BankDetailsList";
import New_Announcement from "./New_Announcement";
import Announcement_Details from "./Announcement_Details";
import EditAnnouncement from "./EditAnnouncement";
// import ViewAccountReport from "./ViewAccountReport";

function Announcement() {
    return (
        <>
            <Switch>
                {/* <Route exact path={"/admin/bank-accounts"} component={BankDetailsList} />
            <Route
                exact
                path={"/admin/bank-accounts/new"}
                component={NewAccountForm}
            />
            <Route exact path={"/member/bank-accounts"} component={BankDetailsList} /> */}
                {/* <Route
        exact
        path={"/member/bank-accounts/new"}
        component={NewAccountForm}
      /> */}
                {/* <Route
        exact
        path={"/admin/setup-account/view-report"}
        component={ViewAccountReport}
      /> */}
                {/* <h1>ugdfyhkjermdxuiafehbdujhkednfvj</h1> */}

                <Route exact path={"/admin/announcement"} component={Announcement_Details} />
                <Route exact path={"/admin/announcement/new"} component={New_Announcement} />
                <Route path={"/admin/announcement/edit/:id"} component={EditAnnouncement} />
            </Switch>
            {/* <Announcement_Details />
            <New_Announcement  /> */}
        </>
    );
}

export default Announcement;
