import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory } from "react-router";
import { BorderedCard } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import CustomCard from "components/UI/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoanDetails,
  getLoanSchedule,
  getLoanScheduleByStatus,
  newLoanRepayment,
} from "./api";
import { Checkbox } from "components/UI";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { CURRENCY } from "variables";
import toast from "react-hot-toast";
import { getPendingLoanSchedule } from "redux/actions/account";
import types from "redux/actions/types";
import { getRoleLink } from "views/helper";
import { EmptyList } from "../Admin/ReportDetails";
import { useQuery } from "hooks";
import BackButton from "components/UI/BackButton";

function LoanRecover() {
  const sections = [
    "Member contribution balance",
    "Special contribution balance",
    "Shares balance",
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment },
  } = useSelector((s) => s);
  // const [scheduleData, setScheduleData] = useState({})

  const [member, setMember] = useState({});

  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const member_id = query.get("member_id");
  const loan_id = query.get("loan_id");
  // const application_date = query.get('application_date')
  const grantor1_id = query.get("grantor1_id");
  const grantor2_id = query.get("grantor2_id");

  const [activeTab, setActiveTab] = useState("");
  const [selectedSection, setSection] = useState(null);
  const [guarantors, setGuarantors] = useState([]);
  const [loanInfo, setLoanInfo] = useState({});
  const [accountList, setAccountList] = useState([]);

  const getGuarantorsBalance = (i) => {
    _fetchApi(
      `/membership/info-by-users?users=${i.grantor1_id}&users=${i.grantor2_id}&society_id=${society.id}`,
      (d) => {
        let arr = [];
        d.data.forEach((item) => {
          arr.push({ ...item, enable: false });
        });
        setGuarantors(arr);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  const getMember = useCallback(() => {
    _fetchApi(
      `/societies/by-member?user_id=${member_id}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          setMember(resp.data[0]);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [society.id]);

  useEffect(() => {
    getMember();
    getAccountList();
  }, [getMember]);

  useEffect(() => {
    dispatch(
      getPendingLoanSchedule(
        (defaultTab, listByLoan) => {
          setActiveTab(defaultTab);
          // Object.values(listByLoan)[0].forEach(d => handleChecked(d))
          // dispatch({ type: types.loan.SET_SELECTED_LOAN_PAYMENT, payload: Object.values(listByLoan)[0] })
        },
        member_id,
        "check"
      )
    );
  }, [dispatch]);

  // useEffect(() => {
  //   getGuarantorsBalance()
  // }, [getGuarantorsBalance])

  useEffect(() => {
    getLoanDetails({ loan_id, society_id: society.id }, (d) => {
      if (d && d.data && d.data.length) {
        setLoanInfo(d.data[0]);
        getGuarantorsBalance(d.data[0]);
      }
    });
  }, [loan_id, society.id]);

  const totalAmountSelected = selectedLoanPayment.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  const fundValidate = (member, section) => {
    switch (section) {
      case sections[0]:
        if (
          parseFloat(totalAmountSelected).toFixed(2) >
          getSectionBalance(member, section)
        ) {
          alert("Insufficient amount");
          return false;
        }
      default:
        return true;
    }
  };

  const getSectionBalance = (member, section) => {
    switch (section) {
      case sections[0]:
        return member.contribution_balance;
      case sections[1]:
        return member.special_saving_balance;
      case sections[2]:
        return member.share_balance;
      default:
        return 0;
    }
  };

  const getAcc = (section) => {
    switch (section) {
      case sections[0]:
        if (
          accountList.findIndex(
            (a) => a.type === "Contribution" && a.is_default === 1
          ) === -1
        ) {
          return accountList.find(
            (a) => a.type === "Contribution" && a.is_default === 1
          );
        } else return accountList.find((a) => a.type === "Contribution");
      case sections[1]:
        if (
          accountList.findIndex(
            (a) => a.type === "Special Contribution" && a.is_default === 1
          ) === -1
        ) {
          return accountList.find(
            (a) => a.type === "Special Contribution" && a.is_default === 1
          );
        } else
          return accountList.find((a) => a.type === "Special Contribution");
      case sections[2]:
        if (
          accountList.findIndex(
            (a) => a.type === "Shares" && a.is_default === 1
          ) === -1
        ) {
          return accountList.find(
            (a) => a.type === "Shares" && a.is_default === 1
          );
        } else return accountList.find((a) => a.type === "Shares");
      default:
        return 0;
    }
  };

  const getAccountList = () => {
    _fetchApi(
      `/accounts/all-list/${society.id}`,
      (resp) => {
        if (resp && resp.success) {
          setAccountList(resp.data);
        } else {
        }
      },
      (e) => {
        console.log(e);
        // alert(resp.message)
      }
    );
  };

  const loanBalance =
    parseFloat(loanInfo.amount_collected) -
    parseInt(loanInfo.amount_paid) -
    parseFloat(totalAmountSelected);

  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 mb-4 text-center">
          Initiate Loan Recovery
        </CardTitle>
        <div>
          {Object.keys(loanScheduleByLoan).length ? (
            <>
              <Row>
                <Col md={8}>
                  <h3>Contribution sources</h3>
                  {sections.map((sec, ix) => {
                    let checked =
                      selectedLoanPayment.findIndex(
                        (a) => a.identifier === sec
                      ) !== -1;
                    let bal = getSectionBalance(member, sec);
                    let amount =
                      parseFloat(bal) > parseFloat(loanBalance)
                        ? parseFloat(loanBalance)
                        : parseFloat(bal);
                    return (
                      <>
                        <Checkbox
                          id={ix + 1}
                          key={ix}
                          name={ix}
                          label={`${sec}: ${CURRENCY} ${formatNumber(
                            getSectionBalance(member, sec).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                              }
                            )
                          )} `}
                          checked={checked}
                          onChange={() => {
                            if (getSectionBalance(member, sec) <= 0) {
                              toast.error(
                                `${sec} is ${getSectionBalance(member, sec)} `
                              );
                            } else {
                              if (
                                selectedLoanPayment.findIndex(
                                  (a) => a.identifier === sec
                                ) === -1
                              ) {
                                let paymentInfo = {
                                  amount,
                                  _column: sec,
                                  description: `Loan Recovery from ${sec} (${loan_id})`,
                                  identifier: sec,
                                  loan_id,
                                  _status: "member",
                                  head: getAcc(sec) ? getAcc(sec).head : 0,
                                  account_description: getAcc(sec)
                                    ? getAcc(sec).description
                                    : "",
                                };
                                let list = [
                                  ...selectedLoanPayment,
                                  paymentInfo,
                                ];
                                dispatch({
                                  type: types.loan.SET_SELECTED_LOAN_PAYMENT,
                                  payload: list,
                                });
                              } else {
                                let list = selectedLoanPayment.filter(
                                  (a) => a.identifier !== sec
                                );
                                dispatch({
                                  type: types.loan.SET_SELECTED_LOAN_PAYMENT,
                                  payload: list,
                                });
                              }
                            }
                          }}
                        />
                      </>
                    );
                  })}
                  <h5>Deduct from guarantors</h5>
                  {guarantors.map((sec, ix) => {
                    return (
                      <>
                        <Checkbox
                          id={ix + 1}
                          key={ix}
                          name={ix}
                          label={`Guarantor ${ix + 1} - ${sec.fullname}`}
                          checked={sec.enable}
                          onChange={() => {
                            let arr = [];
                            guarantors.forEach((item, ind) => {
                              if (ind === ix) {
                                arr.push({ ...item, enable: !sec.enable });
                              } else {
                                arr.push(item);
                              }
                            });
                            setGuarantors(arr);
                          }}
                        />
                        <div className="m-3">
                          {sec.enable &&
                            sections.map((_sec, _ix) => {
                              let checked =
                                selectedLoanPayment.findIndex(
                                  (a) =>
                                    a.identifier ===
                                    `guarantor(${ix + 1}) ${_sec}`
                                ) !== -1;
                              let bal = getSectionBalance(sec, _sec);
                              let amount =
                                parseFloat(bal) > parseFloat(loanBalance)
                                  ? parseFloat(loanBalance)
                                  : parseFloat(bal);
                              return (
                                <>
                                  <Checkbox
                                    key={ix === 0 ? _ix + 1 : _ix + 3}
                                    name={ix === 0 ? _ix + 1 : _ix + 3}
                                    label={`${_sec}: ${CURRENCY} ${formatNumber(
                                      getSectionBalance(
                                        sec,
                                        _sec
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    )} `}
                                    checked={checked}
                                    onChange={() => {
                                      if (getSectionBalance(sec, _sec) <= 0) {
                                        toast.error(
                                          `Guarantor ${_sec} is ${getSectionBalance(
                                            sec,
                                            _sec
                                          )}`
                                        );
                                      } else {
                                        if (
                                          selectedLoanPayment.findIndex(
                                            (a) =>
                                              a.identifier ===
                                              `guarantor(${ix + 1}) ${_sec}`
                                          ) === -1
                                        ) {
                                          let paymentInfo = {
                                            member_id: sec.id,
                                            user_id: sec.id,
                                            email: sec.email,
                                            phone: sec.phone,
                                            member_name: sec.fullname,
                                            amount,
                                            _column: _sec,
                                            description: `Loan Recovery from guarantor ${_sec} with id (${sec.membership_id})`,
                                            _status: "guarantor",
                                            identifier: `guarantor(${
                                              ix + 1
                                            }) ${_sec}`,
                                            loan_id,
                                            head: getAcc(_sec)
                                              ? getAcc(_sec).head
                                              : 0,
                                            account_description: getAcc(_sec)
                                              ? getAcc(_sec).description
                                              : "",
                                          };
                                          let list = [
                                            ...selectedLoanPayment,
                                            paymentInfo,
                                          ];
                                          dispatch({
                                            type: types.loan
                                              .SET_SELECTED_LOAN_PAYMENT,
                                            payload: list,
                                          });
                                        } else {
                                          let list = selectedLoanPayment.filter(
                                            (a) =>
                                              a.identifier !==
                                              `guarantor(${ix + 1}) ${_sec}`
                                          );
                                          dispatch({
                                            type: types.loan
                                              .SET_SELECTED_LOAN_PAYMENT,
                                            payload: list,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                </>
                              );
                            })}
                        </div>
                      </>
                    );
                  })}
                </Col>
                <Col md={4}>
                  <CustomButton
                    disabled={!selectedLoanPayment.length}
                    onClick={() => {
                      if (fundValidate(member, selectedSection)) {
                        history.push(
                          getRoleLink(
                            `/loan-retrieval?action=recovery&member_id=${member_id}&status=${selectedSection}&loan_id=${loan_id}&loan_head=${loanInfo.account_head}&loan_title=${loanInfo.loan_title}`
                          )
                        );
                      }
                    }}
                    loading={loading}
                  >
                    Save Selection
                    {/* ({CURRENCY} */}
                    {/* {parseFloat(totalAmountSelected).toFixed(2)}) */}
                  </CustomButton>

                  <h4>Recovery Balance: {formatNumber(loanBalance)}</h4>
                </Col>
              </Row>
              {/* {JSON.stringify(accountList)} */}
              {/* <Nav tabs>
                {Object.keys(loanScheduleByLoan).map((i, id) => (
                  <NavItem>
                    <NavLink
                      className={activeTab === i ? 'active' : ''}
                      onClick={() => setActiveTab(i)}
                      key={id}
                    >
                      Unpaid ({i})
                    </NavLink>
                  </NavItem>
                ))}
              </Nav> */}
              {/* <TabContent activeTab={activeTab}>
                {Object.keys(loanScheduleByLoan).map((i, id) => {
                  return (
                    <TabPane tabId={i}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Month, Year</th>
                            <th>Amount Due</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanScheduleByLoan[i].map((item, ix) => {
                            let checked =
                              selectedLoanPayment.findIndex(
                                (a) =>
                                  a.loan_id === item.loan_id &&
                                  a.schedule_date === item.schedule_date,
                              ) !== -1
                            return (
                              <tr key={ix}>
                                <td>
                                  <Checkbox
                                    id={ix}
                                    name={i + ix}
                                    checked={checked}
                                    onChange={() => handleChecked(item)}
                                  />
                                </td>
                                <td>
                                  {moment(item.schedule_date).format(
                                    'MMM, YYYY',
                                  )}
                                </td>
                                <td>{formatNumber(item.amount)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </TabPane>
                  )
                })}
              </TabContent> */}
            </>
          ) : (
            <EmptyList message='You currently do not have any loan to repay, kindly click "My Loans" on the menu to apply for loan.' />
          )}
        </div>
        {/* <CustomTable fields={fields} data={scheduleData} size="sm" /> */}
      </Card>
    </>
  );
}

export default memo(LoanRecover);
