import { themeClass } from "variables";
import { Card, CardBody } from "reactstrap";
import { CustomTable } from "components/UI";
import { SearchBar } from "components/UI";
import { CustomButton } from "components/UI";
import { useHistory } from "react-router";
import { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { _fetchApi } from "redux/actions/api";
import { formatNumber } from "components/UI/helpers";
import CustomModal from "components/UI/CustomModal";
import { useSelector } from "react-redux";
import NewAccountForm, { AccountFormComponent } from "./NewAccountForm";
import { _updateApi } from "redux/actions/api";
import { themePaper } from "variables";
import { EmptyList } from "../Admin/ReportDetails";
import toast, { Toaster } from "react-hot-toast";
import Loading from "components/UI/Loading";
import { enableStatus } from "redux/actions/account";
import { disabledStatus } from "redux/actions/account";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { getRoleLink } from "views/helper";

function AccountSetupList() {
  const history = useHistory();
  const [accountList, setAccountList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const society = useSelector((state) => state.auth.society);
  const [fetching, setFetching] = useState(false);

  const toggleEditModal = () => setModalIsOpen((p) => !p);

  const handleChange = ({ target: { name, value } }) =>
    setAccountInfo((p) => ({ ...p, [name]: value }));
  const getAccountList = useCallback(() => {
    setFetching(true);
    _fetchApi(
      `/accounts/all-list/${society.id}`,
      (resp) => {
        setFetching(false);
        if (resp && resp.success) {
          setAccountList(resp.data);
        } else {
          toast.error(resp.message);
        }
      },
      (e) => {
        setFetching(false);
        console.log(e);
        toast.error(e.message);
        // alert(resp.message)
      }
    );
  }, [society.id]);

  useEffect(() => getAccountList(), []);
  const fields = [
    {
      title: "Head",
      custom: true,
      component: (item) => <div>{item.head}</div>,
      value: "head",
    },
    { title: "Type", value: "type" },
    { title: "Description", value: "description" },
    {
      title: "Amount",
      value: "amount",
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(
            item.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
    },
    { title: "Account No.", value: "account_no" },
    { title: "Status", value: "account_status" },

    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            onClick={() => {
              setModalIsOpen(true);
              setAccountInfo(item);
            }}
          >
            Edit
          </CustomButton>
          <CustomButton
            size="sm"
            onClick={() => {
              enableStatus(
                item.head,
                item.account_status === "active" ? "inactive" : "active",
                (res) => {
                  toast.success(res.message);
                  getAccountList();
                },
                (err) => {
                  toast.error(err.message);
                }
              );
            }}
          >
            {item.account_status === "active" ? "Disable" : "Enable"}
          </CustomButton>
          <CustomButton
            size="sm"
            onClick={() =>
              history.push(
                getRoleLink(
                  `/setup-account/view-report?account_head=${item.head}`
                )
              )
            }
          >
            View Payments
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <div className={themeClass}>
      <Card color="white">
        <CardBody>
          <CustomButton
            className="px-6 mb-3"
            onClick={() => history.push(getRoleLink("/setup-account/new"))}
          >
            Create New Account
          </CustomButton>
          {/* <SearchBar placeholder="Search for an account" /> */}
          {fetching && <Loading size="sm" />}
          <CustomTable fields={fields} data={accountList} size="sm" />
          {accountList.length ? null : (
            <EmptyList message="No account has been created yet, click on the button above to get started." />
          )}
        </CardBody>
      </Card>

      <CustomModal
        header="Edit Account Information"
        isOpen={modalIsOpen}
        toggle={toggleEditModal}
      >
        {/* {JSON.stringify({accountInfo})} */}

        <EditModalContent
          form={accountInfo}
          setForm={setAccountInfo}
          handleChange={handleChange}
          toggleEditModal={toggleEditModal}
          society={society}
          getAccountList={getAccountList}
        />
      </CustomModal>
      <Toaster position="top-center" />
    </div>
  );
}

function EditModalContent({
  form = {},
  handleChange = (f) => f,
  toggleEditModal = (f) => f,
  society = {},
  getAccountList = (f) => f,
  setForm = (f) => f,
}) {
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setLoading(true);
    _updateApi(
      `/accounts/update/${society.id}/${form.head}`,
      form,
      (d) => {
        setLoading(false);
        if (d && d.success) {
          toggleEditModal();
          getAccountList();
          toast.success(d.message);
        } else {
          // alert(d.message)
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <>
      <AccountFormComponent
        form={form}
        handleChange={handleChange}
        setForm={setForm}
      />
      <center>
        <CustomButton loading={loading} onClick={handleUpdate}>
          Update
        </CustomButton>
      </center>
    </>
  );
}

export default AccountSetupList;
