import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle, FormGroup } from "reactstrap";
import { getBankListFW } from "redux/actions/account";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import toast, { Toaster } from "react-hot-toast";
import { CURRENCY } from "variables";
import { formatNumber } from "components/UI/helpers";
import { getRoleLink } from "views/helper";
export default function NewAccountForm() {
  const defaultForm = {
    head: "",
    description: "",
    amount: "",
    account_status: "active",
    type: "",
    maximum_amount_type: "Fixed",
    maximum_amount: "",
  };
  const [form, setForm] = useState(defaultForm);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const society = useSelector((state) => state.auth.society);

  const history = useHistory();

  const tableFields = [
    // { title: "Head", value: "head" },
    { title: "Description", value: "description" },
    {
      title: "Amount " + CURRENCY,
      custom:true,
      component:(item)=>(
        <div  className="text-right">{formatNumber(item.amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }))}</div>
      ),
      className: "text-right",
    },
  ];

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleAdd = () => {
    if (
      // form.head &&
      // form.head !== "" &&
      form.description &&
      form.description !== "" &&
      form.amount &&
      form.amount !== ""
    ) {
      setList((p) => [...p, form]);
      setForm(defaultForm);
    } else {
      // alert('Please complete the form')
      toast.error("Please complete the form");
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let finalList = list.map((a) => ({ ...a, user_phone: user.phone }));
    _postApi(
      `/accounts/new`,
      {
        created_by: user.id,
        user_phone: user.phone,
        society_id: society.id,
        data: finalList,
      },
      (d) => {
        setLoading(false);
        toast.success(d.message);
        history.push(getRoleLink("/setup-account"));
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };

  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">
          Setup new account
        </CardTitle>
        <AccountFormComponent
          form={form}
          setForm={setForm}
          handleChange={handleChange}
        />

        <center className="mb-4">
          <CustomButton onClick={handleAdd}>Add to list</CustomButton>
        </center>

        {list.length ? (
          <>
            <CustomTable fields={tableFields} data={list} />

            <CustomButton loading={loading} onClick={handleSubmit}>
              Submit
            </CustomButton>
          </>
        ) : null}
        <Toaster position="top-center" />
      </Card>
    </>
  );
}

export const AccountFormComponent = ({
  form = {},
  setForm = (f) => f,
  handleChange = (f) => f,
}) => {
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    getBankListFW((l) => setBanks(l.data));
  }, []);

  const formFields = [
    {
      label: "Type",
      type: "custom",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Type*</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>
              setForm((p) => ({ ...p, type: value }))
            }
            value={form.type}
            name="type"
          >
            <option>--select---</option>
            {["Contribution", "Special Contribution", "Shares", "Others"].map(
              (item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              )
            )}
          </select>
        </FormGroup>
      ),
    },
    // {
    //   label: "Head",
    //   value: form.head,
    //   name: "head",
    //   required: true,
    // },
    {
      label: "Description",
      value: form.description,
      name: "description",
      required: true,
    },
    {
      label: "Minimum Amount",
      value: form.amount,
      required: true,
      name: "amount",
      type:"number"
    },
    {
      type: "custom",
      col: "3",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Miximum amount type</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>
              setForm((p) => ({ ...p, maximum_amount_type: value }))
            }
            value={form.maximum_amount_type}
          >
            <option>--select---</option>
            {["Fixed", "Percentage"].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
    {
      label: "Maximum Amount",
      value: form.maximum_amount,
      name: "maximum_amount",
      col: 3,
      type: "number",
    },
    {
      label: "Account No.(optional)",
      value: form.account_no,
      name: "account_no",
      col: 3,
    },
    {
      type: "custom",
      col: 3,
      component: (item) => (
        <FormGroup>
          <label className="font-weight-bold">Bank Name (optional)</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>
              setForm((p) => ({ ...p, bank: value }))
            }
          >
            <option>--select---</option>
            {banks.map((item, index) => (
              <option key={index} value={item.code}>
                {item.name}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
  ];

  return <CustomForm fields={formFields} handleChange={handleChange} />;
};
