import { _fetchApi } from 'redux/actions/api'
import store from 'redux/store'

export const getLoanTypesList = (callback = (f) => f, error = (f) => f) => {
  const society_id = store.getState().auth.society.id
  _fetchApi(
    `/loan-setup/list?society_id=${society_id}`,
    (d) => {
      if (d && d.data) {
        callback(d.data)
      }
    },
    (e) => {
      error(e)
    },
  )
}
