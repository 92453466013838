import React, { useState } from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Col, Form, Row, Spinner } from "reactstrap";
import { apiURL } from "variables";
import cover from "../../../images/hands2.jpg";

export default function ForgetPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // const submitEmail = () => {
  //     console.log(forgetPasswordData)
  // }

  const submitEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${apiURL}/users/initiate-password-reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email}),
    })
      .then((raw) => raw.json())
      .then((resp) => {
        if (resp.success) {
          setLoading(false);
          history.push("/auth/email-sent");
          console.log(resp);
        } else {
          alert(resp.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    console.log(email);
  };
  return (
    <div className="login-div">
      <Row className="m-0 login-row">
        <Col
          md={6}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
          }}
          className="img-col"
        >
          <div className="title-wrap">
            <h3 className="bits-head">BitsCoops</h3>
            <p>Automated cooperative society system</p>
            <input
              type="submit"
              onClick={() => history.push("/auth/login")}
              className="goto"
              name=""
              value={`Goto Log in`}
            />
          </div>
        </Col>
        <Col md={6} className="login-col">
          <div className="mobile-head">
            <h3 className="bits-head">BitsCoops</h3>
            <p className="bits-slo">Automated cooperative society system</p>
          </div>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <div className="login-form">
                {/* <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> */}
                {/* {JSON.stringify(email)} */}
                <Form onSubmit={submitEmail}>
                  <Card className="login-card shadow-lg">
                    <CardBody className="">
                      <p className="login-text">Reset Password</p>
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        Enter your email, we will send a link that will help you
                        reset your password
                      </p>
                      <hr style={{ padding: 0 }}></hr>
                      <div className="form-group" style={{ paddingTop: 7 }}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          // value={forgetPasswordData.email}
                          name="email"
                          onChange={handleEmail}
                        />
                      </div>
                      <div className="text-center">
                        <button className="auth-btn">
                          {loading && <Spinner size="sm" />}
                          Send Email
                        </button>
                        <hr style={{ padding: 0 }}></hr>
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: 5,
                            color: "grey",
                          }}
                        >
                          Already a member?{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              color: "rgb(96, 14, 230)",
                            }}
                            onClick={() => history.push("/auth/login")}
                          >
                            Login here!
                          </span>{" "}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
                {/* </div> */}
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
