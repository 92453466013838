import types  from "redux/actions/types";

const initState = {
    members:[],
    my_society:{},
    payments:[]
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case types.society.MEMBERS:
      return {...state, members:action.payload}
    case types.auth.AUTH_SOCIETY:
      return  {...state, errors:[...state.errors,action.payload]}
    default:
      return state;
  }
};

export default authReducer;
