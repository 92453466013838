import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle, FormGroup } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { _postApi } from "redux/actions/api";
import { today } from "components/UI/helpers";

export default function New_Announcement() {
  const defaultForm = {
    date_from: today,
    date_to: today,
    message: "",
  };
  const [form, setForm] = useState(defaultForm);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // To track the index of the item being edited
  const user = useSelector((state) => state.auth.user);
  const society = useSelector((state) => state.auth.society);

  const history = useHistory();

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleAdd = () => {
    if (form.date_from && form.date_to && form.message) {
      if (editIndex !== null) {
        // Update existing item
        const updatedList = list.map((item, index) =>
          index === editIndex ? form : item
        );
        setList(updatedList);
        setEditIndex(null);
      } else {
        // Add new item
        setList((p) => [...p, form]);
      }
      setForm(defaultForm);
    } else {
      toast.error("Please complete the form");
    }
  };

  const handleEdit = (index) => {
    setForm(list[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const confirmed = window.confirm("Are you sure you want to delete this?");
    if (confirmed) {
      setList((prevList) => prevList.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = () => {
    if (list.length === 0) {
      toast.error("Please add at least one announcement.");
      return;
    }
    setLoading(true);
    _postApi(
      `/insertannouncement?society_id=${society.id}`,
      { announcements: list },
      (response) => {
        setLoading(false);
        if (response.success) {
          toast.success("Announcements submitted successfully!");
          history.push("/admin/announcement");
          // history.push(getRoleLink("/announcements"));
        } else {
          toast.error(response.message || "Failed to submit announcements.");
        }
      },
      (error) => {
        setLoading(false);
        toast.error("Error submitting announcements!");
      }
    );
  };

  const tableFields = [
    { title: "Date From", value: "date_from" },
    { title: "Date To", value: "date_to" },
    { title: "Message", value: "message" },
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <div className="text-center">
          <CustomButton size="sm" onClick={() => handleEdit(index)}>
            Edit
          </CustomButton>
          <CustomButton
            size="sm"
            color="danger"
            onClick={() => handleDelete(index)}
          >
            Delete
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">
          Setup new announcement
        </CardTitle>
        <AnnouncementComponent
          form={form}
          setForm={setForm}
          handleChange={handleChange}
        />

        <center className="mb-4">
          <CustomButton onClick={handleAdd}>
            {editIndex !== null ? "Update" : "Add to list"}
          </CustomButton>
        </center>

        {list.length > 0 && (
          <>
            <CustomTable fields={tableFields} data={list} />

            <center className="mt-4">
              <CustomButton loading={loading} onClick={handleSubmit}>
                Submit
              </CustomButton>
            </center>
          </>
        )}
        <Toaster position="top-center" />
      </Card>
    </>
  );
}

export const AnnouncementComponent = ({
  form = {},
  setForm = (f) => f,
  handleChange = (f) => f,
}) => {
  const formFields = [
    {
      label: "Date From",
      value: form.date_from,
      name: "date_from",
      type: "date",
      required: true,
      col: "6",
    },
    {
      label: "Date To",
      value: form.date_to,
      name: "date_to",
      type: "date",
      required: true,
      col: "6",
    },
    {
      label: "Message",
      value: form.message,
      name: "message",
      type: "textarea",
      required: true,
      col: "12",
      custom: true,
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Message*</label>
          <textarea
            className="form-control"
            name="message"
            value={form.message}
            onChange={handleChange}
            rows="15"
            style={{ width: "100%", height: "50vh" }}
          />
        </FormGroup>
      ),
    },
  ];

  return (
    <CustomForm
      fields={formFields}
      handleChange={handleChange}
      layout="inline"
    />
  );
};
