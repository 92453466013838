import { CustomTable } from "components/UI";
import CustomCard from "components/UI/CustomCard";
import useQuery from "helpers/useQuery";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import moment from "moment";
import { CURRENCY } from "variables";
import { Toaster } from "react-hot-toast";
import { useHistory, useLocation } from "react-router";
import { EmptyList } from "../Admin/ReportDetails";
import BackButton from "components/UI/BackButton";
import { today } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import { _formatNumber } from "components/UI/helpers";
import { CustomButton } from "components/UI";
import { getRoleLink } from "views/helper";
import { TextInput } from "components/UI";
import { _postApi } from "redux/actions/api";
import { CustomForm } from "components/UI";

function ViewMessage() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  let req_id = query.get("req_id");
  let member_id = query.get("member_id");
  const { society, user } = useSelector((state) => state.auth);
  const isMemberPage = location.pathname.includes(
    "/member/messages/view"
  );
console.log(isMemberPage )
  const [requestInfo, setRequestInfo] = useState({
    summary: {
      created_at: today,
    },
    details: [],
    attachments: [],
  });
  const [approving, setApproving] = useState(false);
    const defaultForm = {
      type: isMemberPage ? "member" : "admin",
      message: "",

  
    };
  const [form, setForm] = useState(defaultForm)

  const [messages, setMessages] = useState([
    { from: "admin", to: "member", message: "Hello User", date: moment.now() },
    { from: "member", to: "admin", message: "Hello Admin", date: moment.now() },
    { from: "member", to: "admin", message: "Hello Admin from user", date: moment.now() },
  ]);

  const getMessages = useCallback(() => {
    if (isMemberPage){
   _fetchApi(
     `/messages/view?query_type=view-member&member_id=${user.id}&society_id=${society.id}&admin_id=${member_id}`,
     (d) => {
       if (d.success) {
         setMessages(d.data);
       }
     },
     (e) => {
       console.log(e);
     }
   );
    } else {
   _fetchApi(
     `/messages/view?query_type=view-admin&admin_id=${user.id}&society_id=${society.id}&member_id=${member_id}`,
     (d) => {
       if (d.success) {
         setMessages(d.data);
       }
     },
     (e) => {
       console.log(e);
     }
   );
    }
 
  }, [society.id]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  console.log(messages);
  const tableFields = [
    { title: "Account Name", value: "account_description" },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {_formatNumber(isWithdrawal ? item.debit : item.credit)}
        </div>
      ),
    },
  ];

const handleAdd = (e) => {
 e.preventDefault();
 setMessages(p => [...p, form ])
 setForm(defaultForm);
 _postApi(
   "/messages/new?query_type=create",
   {
     ...form,
     message_id: moment(new Date()).format("YYYYMMDDHHSS"),
     member_name: `${messages[0].member_name}`,
     member_id: messages[0].member_id,
     admin_id: user.id,
     admin_name: `${messages[0].admin_name}`,
     society_id: society.id,
   },
   (d) => {
     if (d.success) {
       setForm(defaultForm);
     }
   },
   (e) => {
     console.log(e);
   }
 );

  }
    const handleChange = ({ target: { name, value } }) =>
      setForm((p) => ({ ...p, [name]: value }));

    const formFields = [
    
    {
      label: "Messages",
      col:"10",
      value: form.message,
      name: "message",
      required: true,
    },
    {
      type: "custom",
      col: "2",
      component: () => (
        <Col  className="pl-0 pt-4">
              <CustomButton className="w-100 " onClick={handleAdd}>
                Send
              </CustomButton>
            </Col>
      ),
    },
    ]
  return (
    <>
      <BackButton />
      <CustomCard
      // height="65vh"
      // footer={<CustomForm fields={formFields} handleChange={handleChange} />}
      >
        <div className="d-flex flex-direction-row justify-content-between align-items-center">
          <CardTitle className="h3 mb-4 text-center">
            View Message Information
          </CardTitle>
        </div>

        {/* <CustomTable fields={tableFields} data={requestInfo.details} /> */}

        <div>
          {messages?.map((message, id) => (
            <>
              <Card
                key={id}
                className={`w-75 ${
                  isMemberPage
                    ? message.type === "member"
                      ? "ml-auto"
                      : ""
                    : message.type === "admin"
                    ? "ml-auto"
                    : "text-right "
                }`}
              >
                <CardTitle className="pl-1 text-left ">
                  <i>{
                  message.type === "member" ? message.member_name : isMemberPage ?
                     message.type === "admin" ? message.admin_name : "me" : "me"}</i>
                </CardTitle>
                <CardBody>{message.message}</CardBody>
                <span
                  className="text-right pr-1 text-black-50"
                  style={{ fontSize: 12 }}
                >
                  {moment(message.created_at).calendar()}
                </span>
              </Card>
            </>
          ))}
        </div>

        {/* {requestInfo.attachments.length ? null : (
          <EmptyList message="No attachment found for this request." />
        )} */}
        <CustomForm fields={formFields} handleChange={handleChange} />
        <Toaster top="center" />
      </CustomCard>
    </>
  );
}

export default ViewMessage;
