import types from "./types";
require("dotenv").config();

import { apiURL } from "variables";
import { getRoleLink } from "views/helper";
import toast from "react-hot-toast";

export function signup(objs = {}, success = (f) => f, error = (f) => f) {
  return (dispatch) => {
    //   dispatch({ type: types.LOADING });

    fetch(`${apiURL}/users/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objs),
    })
      .then((raw) => raw.json())
      .then((data) => {
        console.log(data);
        //   dispatch({ type: LOADING_SIGNUP });
        if (data.success) {
          dispatch(
            login(
              objs,
              (data) => {
                console.log("success", data);
                success(data);
                const { user, token } = data;
                dispatch({ type: types.auth.AUTH_USER, payload: data });
                // dispatch({ type: types.society.CURRENT, payload: data.societies });
                console.log("Register submitted");
                if (token) {
                  localStorage.setItem("@@token", JSON.stringify(token));
                }
              },
              (err) => {
                console.log("error", err);
                error(err);
                if (err && err.message)
                  dispatch({
                    type: types.auth.AUTH_ERROR,
                    payload: err,
                  });
              }
            )
          );
        } else {
          dispatch({
            type: types.auth.AUTH_ERROR,
            payload: "Server error, try again.",
          });
        }
      })
      .catch((error) => {
        //   dispatch({ type: LOADING_SIGNUP });
        console.log({ error });
      });
  };
}

export function login({ email, password }, success, error) {
  return (dispatch) => {
    //   dispatch({ type: types.LOADING });

    fetch(`${apiURL}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((raw) => raw.json())
      .then((data) => {
        // console.log(data);
        //   dispatch({ type: LOADING_LOGIN });
        if (data.success) {
          const { token } = data;
          getUserProfile(token)
            .then((data) => {
              if (data.success) {
                /**
                 * Token is valid
                 * navigate user to dashboard */
                //  flash.success('Login success')
                console.error({ data });
                // const { user } = data
                dispatch({ type: types.auth.AUTH_USER, payload: data });

                // console.log('got here', user.id);
                if (token) {
                  localStorage.setItem("@@token", JSON.stringify(token));
                }
                success(data);
              }
            })
            .catch((err) => {
              error(err);
            });
        } else {
          dispatch({ type: types.auth.AUTH_ERRORS, payload: data.message });
          error(data);
          console.log(data);
        }
      })
      .catch((err) => {
        //   dispatch({ type: LOADING_LOGIN });
        // console.log(err)
        error(err);
      });
  };
}

export async function getUserProfile(_token) {
  try {
    // console.log(_token);
    let response = await fetch(`${apiURL}/users/verify-token`, {
      method: "GET",
      headers: {
        authorization: _token,
      },
    });
    let data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

export function init(history, callback = (f) => f, location) {
  return (dispatch) => {
    let token = localStorage.getItem("@@token");
    // dispatch({ type: START_LOADING_APP });
    if (token) {
      token = JSON.parse(token);
      /**
       * Token present
       * verifyToken */
      getUserProfile(token)
        .then((data) => {
          if (data.success) {
            /**
             * Token is valid
             * navigate user to dashboard */
            callback();
            //   dispatch({ type: STOP_LOADING_APP });
            // const { user } = data

            dispatch({ type: types.auth.AUTH_USER, payload: data });
            // alert(JSON.stringify(data))
            if (data && data.societies && data.societies.length) {
              if (location.pathname.split("/").includes("membership")) {
              } else if (
                location.pathname.split("/").includes("account-switch")
              ) {
              } else {
                history.push(getRoleLink("/dashboard"));
              }

              //
            } else {
              toast.error(
                "There is a problem with your account, contact customer support!"
              );
              // history.push(`/member/dashboard`)
            }
          } else {
            /**
             * Token is invalid
             * navigate user to auth */
            // dispatch({ type: STOP_LOADING_APP });
            callback();
            // console.log(err)
            localStorage.removeItem("@@token");
            history.push("/auth/login");
            console.log("Token expired");
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: types.LOADING });
          history.push("/auth/login");
        });
    } else {
      /**
       * No token found
       * navigate user to auth page
       */
      callback();
      // history.push("/auth/login");
      // dispatch({ type: STOP_LOADING_APP });
    }
  };
}

export function logout(history) {
  return (dispatch) => {
    localStorage.removeItem("@@token");
    localStorage.removeItem("@@role");
    dispatch({ type: types.auth.LOGOUT });
    history.push("/auth/login");
  };
}

export function submitBasicInfo(data, cb) {
  return async (dispatch) => {
    protectedPost("api/users/basicinfo", data)
      .then((resp) => {
        // console.log(resp);
        cb();
        if (resp.success) {
          toast.success("Profile Updated");
          // dispatch(initUser());
        } else {
          console.log(resp.error);
          toast.error(resp.error);
        }
      })
      .catch((err) => {
        console.log("caught error", err);
        toast.error("An error occured");
        _warningNotify("An error occured");
      });
  };
}

export async function protectedPost(route, dataObj) {
  try {
    const token = localStorage.getItem("@@token");
    // console.log(token);
    const response = await fetch(`${apiURL}/${route}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dataObj),
    });
    let data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
