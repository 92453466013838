import React, { useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import CandidateList from "./CandidateList";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { _warningNotify } from "utils/helpers";
import moment from "moment";
import { _postApi } from "redux/actions/api";
import { _customNotify } from "utils/helpers";
import { useSelector } from "react-redux";
import BackButton from "components/UI/BackButton";

const CandidateForm = () => {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [candidates, setCandidates] = useState([]);
  const vote_id = moment().format("YYYYMMDDHHSS");
  const { society } = useSelector((a) => a.auth);

  const addCandidate = (candidate) => {
    setCandidates([
      ...candidates,
      {
        ...candidate,
        votes: 0,
        query_type: "create",
        vote_id: `CD-${vote_id}`,
        society_id: society.id,
      },
    ]);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (name === "" || position === "") {
      _warningNotify("Invalid Form");
    } else {
      addCandidate({ name, position });
      setName("");
      setPosition("");
    }
  };

  const handleDelete = (index) => {
    let newA = candidates.filter((item, id) => id !== index);
    setCandidates(newA);
  };

  const handleSubmit = () => {
    _postApi(
      "/create-candidate",
      candidates,
      (e) => {
        _customNotify("Candidate created successful");
        setCandidates([]);
      },
      () => {
        _warningNotify("Unable to create candidate");
      }
    );
  };

  return (
    <div className="m-2 ">
      <BackButton />
      <Form onSubmit={handleAdd} className="row">
        <FormGroup className="col-md-6">
          <Label for="name">Full Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Enter candidate's name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <Label for="position">Position</Label>
          <Input
            type="text"
            name="position"
            id="position"
            placeholder="Enter candidate's position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </FormGroup>
      </Form>
      <center>
        <CustomButtonAdmin
          onClick={handleAdd}
          color="primary"
          // type="submit"
          className="px-5 ml-5"
        >
          Add Candidate
        </CustomButtonAdmin>
      </center>
      <CandidateList candidates={candidates} handleDelete={handleDelete} />
      <center>
        <CustomButtonAdmin onClick={handleSubmit}>Submit</CustomButtonAdmin>
      </center>
    </div>
  );
};

export default CandidateForm;
