import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { CustomButton } from "components/UI";
import {
  AccountFormComponent,
  AnnouncementComponent,
} from "./New_Announcement";
import { _fetchApi, _postApi } from "redux/actions/api";
import { Card, CardBody, CardTitle } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import BackButton from "components/UI/BackButton";
import { useSelector } from "react-redux";

const EditAnnouncement = () => {
  const { id } = useParams();
  const society = useSelector((state) => state.auth.society);
  const history = useHistory();
  const [form, setForm] = useState({
    date_from: "",
    date_to: "",
    message: "",
    id: id,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _postApi(
      `/getannouncement?query_type=getbyid&society_id=${society.id}`,
      { id },
      (response) => {
        if (response.success && response.results.length) {
          const data = response.results[0];
          setForm({
            date_from: data.date_from,
            date_to: data.date_to,
            message: data.message,
          });
        } else {
          toast.error("Failed to fetch announcement details.");
        }
      },
      (error) => console.log("Error fetching announcement details.")
    );
  }, [id]);

  const handleChange = ({ target: { name, value } }) =>
    setForm((prev) => ({ ...prev, [name]: value }));

  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      `/getannouncement?query_type=update&society_id=${society.id}`,
      { ...form, id: id }, // wrap in an array to match backend expectation
      (response) => {
        setLoading(false);
        if (response.success) {
          toast.success("Announcement updated successfully!");
          history.push("/admin/announcement");
        } else {
          toast.error(response.message || "Failed to update announcement.");
        }
      },
      (error) => {
        setLoading(false);
        toast.error("Error updating announcement!");
      }
    );
  };

  return (
    <>
      <BackButton />
      <Card>
        {/* {JSON.stringify(id)} */}
        <CardBody>
          <CardTitle className="h4 text-center mb-4 mt-0">
            Edit Announcement
          </CardTitle>
          <AnnouncementComponent
            form={form}
            setForm={setForm}
            handleChange={handleChange}
          />
          <center className="mt-4">
            <CustomButton loading={loading} onClick={handleSubmit}>
              Update Announcement
            </CustomButton>
          </center>
          <Toaster position="top-center" />
        </CardBody>
      </Card>
    </>
  );
};

export default EditAnnouncement;
