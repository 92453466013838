import React from "react";
import Structure1 from "./Structure";

function AccountChart() {
  return (
    <Structure1
      type="structure"
      prefix={false}
    />
  );
}

export default AccountChart;
