import React, { useCallback, useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import MemberCustomCard from "components/UI/MemberCustomCard";
import CustomBarChart from "components/UI/CustomBarChart";
import DaterangeSelector from "components/UI/DaterangeSelector";
import { CustomButton } from "components/UI";
import { Toaster } from "react-hot-toast";
import ContributionSchedule from "./app/monthlyContribution/ContributionSchedule";
import AdminCustomCard2 from "components/UI/AdminCustomCard2";
import Defaulters from "./app/loanApplication/Defaulters";
import { useHistory } from "react-router-dom";
import { queryDefaulting } from "redux/actions/society";
import { useSelector } from "react-redux";
import { _fetchApi } from "redux/actions/api";
import { getRoleLink } from "./helper";

const scrollView = (id) => {
  // document.getElementById(id).scrollIntoView();
};

function Dashboard() {
  const isMemberDashboard =
    location.pathname.includes("/member-dashboard") ||
    location.pathname.includes("/member/dashboard");
  const today = moment().format("YYYY-MM-DD");
  // const startOfYear = moment().startOf("y").format("YYYY-MM-DD");
  const startOfYear = "2000-01-01";
  const [range, setRange] = useState({ from: startOfYear, to: today });
  const [displayState, setDisplayState] = useState("contribution_chart");
  const [numberOfDefaulter, setNumberOfDefaulter] = useState(0);
  const history = useHistory();

  const handleChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));
  const dateFrom = moment(today).subtract(12, "month").format("YYYY-MM-DD");
  const { society } = useSelector((s) => s.auth);
  const getDefaultersList = useCallback(() => {
    // setLoading(true);
    _fetchApi(
      `/loan-repayment/defaulters/${society.id}?query_type=no_of_defaulters`,
      (resp) => {
        // setLoading(false);
        console.log(resp);
        console.log("resp");
        if (resp && resp.data && resp.data.length) {
          setNumberOfDefaulter(resp.data[0].number_of_defaulter);
        }
      },
      (err) => {
        console.log(err);
        // setLoading(false);
      }
    );
  }, [society.id]);
  useEffect(() => {
    getDefaultersList();
  }, [getDefaultersList]);

  return (
    <>
      <Container fluid>
        {/* <DaterangeSelector
          noLabel
          from={range.from}
          to={range.to}
          handleChange={handleChange}
        /> */}
        {/* <h1>KKKK</h1> */}
        <Row id="_top-cards">
          <AdminCustomCard2
            title="Total Contribution"
            _title="Ordinary Savings"
            range={range}
            onDefaultersClick={() => {
              history.push(
                getRoleLink(
                  `/report-details?report_type=Total Contribution&from=${dateFrom}&to=${today}`
                )
              );
            }}
            index={0}
          />
          <AdminCustomCard2
            card_target="scrol-2"
            title="Loans"
            numberOfDefaulter={numberOfDefaulter}
            link="Navigate"
            action={() => scrollView("scrol-2")}
            range={range}
            onDefaultersClick={() => {
              setDisplayState("defaulters");
            }}
            index={2}
          />
          <MemberCustomCard
            title="Special loans"
            action={() => scrollView("scrol-3")}
            range={range}
            index={1}
          />
          <AdminCustomCard2
            title="Special contribution"
            _title="Special Savings"
            action={() => scrollView("scrol-4")}
            range={range}
            onDefaultersClick={() => {
              history.push(
                getRoleLink(
                  `/report-details?report_type=Special contribution&from=${dateFrom}&to=${today}`
                )
              );
            }}
            index={3}
          />
          <AdminCustomCard2
            title="Shares contributions"
            _title="Shares contributions"
            action={() => scrollView("scrol-4")}
            range={range}
            onDefaultersClick={() => {
              history.push(
                getRoleLink(
                  `/report-details?report_type=Shares contributions&from=${dateFrom}&to=${today}`
                )
              );
            }}
            index={4}
          />
          <AdminCustomCard2
            title="Others contribution"
            _title="Others contribution"
            action={() => scrollView("scrol-4")}
            range={range}
            onDefaultersClick={() => {
              history.push(
                getRoleLink(
                  `/report-details?report_type=Others contribution&from=${dateFrom}&to=${today}`
                )
              );
            }}
            index={1}
          />
        </Row>

        {displayState === "defaulters" ? (
          <Defaulters />
        ) : (
          <CustomBarChart
            card_id="scrol-1"
            title="Total Contribution"
            sub_title="Contribution Chart"
            items={[{ name: "Total Loan" }, { name: "Loan repayment" }]}
            action={() => scrollView("top-cards")}
            footer={
              <CustomButton
                size="sm"
                color="default"
                onClick={() => scrollView("scrol-2")}
              >
                Next
              </CustomButton>
            }
            range={range}
          />
        )}

        {/* <CustomBarChart
          card_id="scrol-2"
          title="Loan & loan repayment"
          sub_title="Loan transactions"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-3')}
            >
              Next
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-3"
          title="Special loan"
          sub_title="Special loan chart"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-4')}
            >
              Next
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-4"
          title="Special contribution"
          sub_title="Special contribution chart"
          items={[{ name: 'Total Loan' }, { name: 'Loan repayment' }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView('scrol-3')}
            >
              Prev
            </CustomButton>
          }
          action={() => scrollView('top-cards')}
          range={range}
        /> */}
      </Container>
      {/* <Toaster /> */}
    </>
  );
}

export default Dashboard;
