import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory } from "react-router";
import { BorderedCard } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import CustomCard from "components/UI/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoanSchedule,
  getLoanScheduleByStatus,
  newLoanRepayment,
} from "./api";
import { Checkbox } from "components/UI";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { CURRENCY } from "variables";
import toast from "react-hot-toast";
import { getPendingLoanSchedule } from "redux/actions/account";
import types from "redux/actions/types";
import { getRoleLink } from "views/helper";
import { EmptyList } from "../Admin/ReportDetails";
import { useQuery } from "hooks";
import { _formatNumber } from "components/UI/helpers";

export default function LoanRecover() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment },
  } = useSelector((s) => s);
  // const [scheduleData, setScheduleData] = useState({})

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const member_id = useQuery().get("member_id");
  const [activeTab, setActiveTab] = useState("");

  const handleChecked = (i) => {
    // console.log(i)
    if (
      selectedLoanPayment.findIndex(
        (a) => a.loan_id === i.loan_id && a.schedule_date === i.schedule_date
      ) === -1
    ) {
      let list = [...selectedLoanPayment, i];
      dispatch({ type: types.loan.SET_SELECTED_LOAN_PAYMENT, payload: list });
      // setSelected((p) => [...p, i])
    } else {
      let filtered = selectedLoanPayment.filter(
        (a) => a.loan_id === i.loan_id && a.schedule_date !== i.schedule_date
      );
      dispatch({
        type: types.loan.SET_SELECTED_LOAN_PAYMENT,
        payload: filtered,
      });
      // setSelected(filtered)
    }
  };

  // const getData = useCallback(() => {
  //   getLoanScheduleByStatus(
  //     { user_id: user.id, society_id: society.id },
  //     (d) => {
  //       if (d && d.data && d.data.length) {
  //         let listByLoan = {}
  //         d.data.forEach((sch) => {
  //           let loan_id = sch.loan_id
  //           if (Object.keys(listByLoan).includes(loan_id)) {
  //             listByLoan[loan_id] = [...listByLoan[loan_id], sch]
  //           } else {
  //             listByLoan[loan_id] = [sch]
  //           }
  //         })
  //         setScheduleData(listByLoan)
  //         setActiveTab(Object.keys(listByLoan)[0])
  //       }
  //     },
  //   )
  // }, [user.id, society.id])

  useEffect(() => {
    dispatch(
      getPendingLoanSchedule(
        (defaultTab) => setActiveTab(defaultTab),
        member_id
      )
    );
  }, [dispatch]);

  const totalAmountSelected = selectedLoanPayment.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  return (
    <>
      <Card body>
        <CardTitle className="h4 mb-4 text-center">Loan Recovery</CardTitle>
        {/* {JSON.stringify(selected)} */}
        <div>
          {Object.keys(loanScheduleByLoan).length ? (
            <>
              <div className="d-flex flex-direction-row justify-content-end align-items-center">
                <CustomButton
                  onClick={() =>
                    history.push(getRoleLink("/loan-repayment-confirm"))
                  }
                  loading={loading}
                >
                  Recover ({CURRENCY}
                  {_formatNumber(totalAmountSelected)})
                </CustomButton>
              </div>
              <Nav tabs>
                {Object.keys(loanScheduleByLoan).map((i, id) => (
                  <NavItem>
                    <NavLink
                      className={activeTab === i ? "active" : ""}
                      onClick={() => setActiveTab(i)}
                      key={id}
                    >
                      {i}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {Object.keys(loanScheduleByLoan).map((i, id) => {
                  return (
                    <TabPane tabId={i}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Month, Year</th>
                            <th>Amount Due</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanScheduleByLoan[i].map((item, ix) => {
                            let checked =
                              selectedLoanPayment.findIndex(
                                (a) =>
                                  a.loan_id === item.loan_id &&
                                  a.schedule_date === item.schedule_date
                              ) !== -1;
                            return (
                              <tr key={ix}>
                                <td>
                                  <Checkbox
                                    id={ix}
                                    name={i + ix}
                                    checked={checked}
                                    onChange={() => handleChecked(item)}
                                  />
                                </td>
                                <td>
                                  {/* {item.schedule_month}, */}
                                  {moment(item.schedule_date).format(
                                    "MMM, YYYY"
                                  )}
                                </td>
                                <td>{_formatNumber(item.amount)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </TabPane>
                  );
                })}
              </TabContent>
            </>
          ) : (
            <EmptyList message='You currently do not have any loan to repay, kindly click "My Loans" on the menu to apply for loan.' />
          )}
        </div>
        {/* <CustomTable fields={fields} data={scheduleData} size="sm" /> */}
      </Card>
    </>
  );
}
