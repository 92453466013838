import React, { useCallback, useState, useRef } from "react";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import { CustomButton } from "components/UI";
import CustomCard from "components/UI/CustomCard";
// import CustomModal from "components/UI/CustomModal";
import CustomTypeahead from "components/UI/CustomTypeahead";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router";
// import { getSupplierInfo } from "redux/actions/inventory";
// import { getPurchaseItem } from "redux/actions/inventory";
// import { getAllDrugs } from "redux/actions/inventory";
import { _warningNotify } from "utils/helpers";
import { _customNotify } from "utils/helpers";
import { formatNumber } from "utils/helpers";
import { uniqueNum } from "variables";
import { addPurchase } from "redux/actions/inventory";

export default function AddnewDrug() {
  const navigate = useHistory();
  const drugNameRef = useRef();
  const drugNameRef1 = useRef();
  // const storeRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const supplierList = useSelector((state) => state.inventory.supplierInfo);
  // const inventoryStores = useSelector((state) => state.inventory.inventoryStore);
  // const purchaseItems = useSelector((state) => state.inventory.drugList);
  const { members } = useSelector((state) => state.society);
  const { user, society } = useSelector((state) => state.auth);
  let user_id = user.id;
  let society_id = society.id;
  const [form, setForm] = useState({
    supplierName: "1",
    supplier_code: "1",
    store_code: "1",
    store_name: "1",
    sourceAcc: "1",
    truckNo: "1",
    drugCategory: "1",
    selling_price: "",
    expiry_date: "",
    modeOfPayment: "",
    transfer_from: "Purchase order",
    branch_name: "",
    transfer_to: "",
    drugCode: "",
    barcode: "1",
    drugName: "",
    genericName: "",
    uom: "1",
    otherDetails: "1",
    waybillNo: "1",
    quantity: 0,
    unitCost: 0,
    query_type: "received",
    cost: 0,
    expiryDate: "1",
    store: "1",
    markup: 0,
    payment: "1",
    status: "pending",
    item_code: uniqueNum,
    reorder: "3",
    generic_name: "1",
    userId: user.id,
  });
  const [tbls, setTbls] = useState([]);
  // const [drugs, setDrugs] = useState([]);

  // const _getDrugs = useCallback(() => {
  //   if (!drugs.length) {
  //     dispatch(getAllDrugs(setDrugs));
  //   }
  // }, [drugs, dispatch]);

  // useEffect(() => {
  //   _getDrugs();
  // }, [0]);
  // const _getSupplierInfo = useCallback(() => {
  //   dispatch(getSupplierInfo());
  //   // dispatch(getinventoryStore());
  //   dispatch(getPurchaseItem(0, 0, "all"));
  // }, [dispatch]);

  // useEffect(() => {
  //   _getSupplierInfo();
  // }, [_getSupplierInfo]);

  // useEffect(() => {
  //   dispatch(
  //     getinventoryStore((d) =>
  //       setForm((p) => ({ ...p, receivedTo: d[0].store_name }))
  //     )
  //   );
  // }, [dispatch]);

  const fields = [
    // {
    //   label: "Supplier Name",
    //   name: "supplier_name",
    //   type: "custom",
    //   component: () => (
    //     <CustomTypeahead
    //       label="Select Supplier"
    //       labelKey="supplier_name"
    //       options={supplierList}
    //       onChange={(s) => {
    //         if (s.length) {
    //           console.log(s);
    //           setForm((p) => ({
    //             ...p,
    //             supplierName: s[0].supplier_name,
    //             supplier_code: s[0].supplier_code,
    //           }));
    //         }
    //       }}
    //       onInputChange={(v) => {
    //         if (v.length) {
    //           console.log(v, "KDDDDDDDK");
    //         }
    //       }}
    //     />
    //   ),
    //   col: 3,
    // },
    // {
    //   label: "Drug Category",
    //   type: "text",
    //   labelkey: "item",
    //   name: "drugCategory",
    //   value: form.drugCategory,
    //   col: 3,
    // },
    // {
    //   type: "custom",
    //   component: () => (
    //     <CustomTypeahead
    //       label="Generic Name"
    //       labelKey="generic_name"
    //       options={drugs && drugs}
    //       _ref={drugNameRef1}
    //       allowNew
    //       onChange={(s) => {
    //         if (s && s.length) {
    //           console.log(s);
    //           setForm((p) => ({
    //             ...p,
    //             generic_name: s && s[0].generic_name,
    //           }));
    //         }
    //       }}
    //       onInputChange={(v) => {
    //         if (v && v.length) {
    //           setForm((p) => ({
    //             ...p,
    //             generic_name: v,
    //           }));
    //         }
    //       }}
    //     />
    //   ),
    //   col: 3,
    // },
    {
      type: "custom",
      component: () => (
        <CustomTypeahead
          label="Item Name"
          labelKey="drug_name"
          options={[]}
          _ref={drugNameRef}
          allowNew
          onChange={(s) => {
            if (s.length) {
              console.log(s);
              setForm((p) => ({
                ...p,
                description: s[0].drug_name,
                drugName: s[0].drug_name,
                item_code: s[0].item_code ? s[0].item_code : "",
              }));
            }
          }}
          onInputChange={(v) => {
            if (v.length) {
              setForm((p) => ({
                ...p,
                description: v,
                drugName: v,
              }));
            }
          }}
        />
      ),
      col: 3,
    },
    // {
    //   label: "Unit of Measurement",
    //   labelkey: "label",
    //   options: [{ label: "--unit--" }, { label: "Other" }],
    //   name: "uom",
    //   value: form.uom,
    //   col: 3,
    // },
    // {
    //   label: "Barcode",
    //   name: "barcode",
    //   value: form.barcode,
    //   col: 3,
    //   type: "text",
    // },
    {
      label: "Cost Price",
      type: "number",
      name: "cost",
      value: form.cost,
      col: 3,
    },
    {
      label: "Quantity",
      type: "number",
      name: "quantity",
      placeholder: "QTY",
      value: form.quantity,
      required: true,
      col: 3,
    },
    {
      label: "Selling Price",
      type: "number",
      name: "selling_price",
      value: form.selling_price,
      required: true,
      col: 3,
    },
    // {
    //   label: "Reorder Level",
    //   type: "number",
    //   name: "reorder",
    //   value: form.reorder,
    //   placeholder: "0",
    //   col: 3,
    // },
    {
      label: "Expiry Date",
      type: "date",
      name: "expiry_date",
      value: form.expiry_date,
      col: 3,
    },
    // {
    //   label: "Receiving Store",
    //   type: "select",
    //   options: inventoryStores.map((a) => a.store_name),
    // component: () => (
    // <CustomTypeahead
    //   label="Receiving Store"
    //   labelKey="store_name"
    //   options={inventoryStores}
    //   _ref={storeRef}
    //   // defaultInputValue={inventoryStores[0] ? inventoryStores[0].store_name : ""}
    //   allowNew
    //   onChange={(s) => {
    //     if (s.length) {
    //       console.log(s);
    //       setForm((p) => ({
    //         ...p,
    //         branch_name: s[0].store_name,
    //         store_code: s[0].store_code,
    //         transfer_to: s[0].store_name,
    //       }));
    //     }
    //   }}
    //   onInputChange={(v) => {
    //     if (v.length) {
    //       console.log(v, "KDDDDDDDK");
    //     }
    //   }}
    // />
    // ),
    // name: "receivedTo",
    // value: form.receivedTo,
    // col: 3,
    // },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleAdd = () => {
    if (form.cost === "" || form.quantity === "" || form.selling_price === "") {
      _warningNotify("Please Complete the Form");
    } else {
      setTbls((p) => [...p, form]);
      // setShow(true);
      setForm((p) => ({
        ...p,
        waybillNo: "",
        quantity: 0,
        unitCost: 0,
        cost: 0,
        expiryDate: "",
        store: "",
        markup: 0,
        payment: "",
        status: "",
        reorder: "",
        uom: "",
        otherDetails: "",
        truckNo: "",
        expiry_date: "",
        modeOfPayment: "",
        barcode: "",
        selling_price: "",
        userId: user.id,
      }));
      // drugNameRef.current.clear();
      // drugNameRef1.current.clear();
      // drugNameRef.current.focus();
      // storeRef.current.clear();
    }
  };
  const handleDelete = (idx) => {
    const data = tbls.filter((item, index) => idx !== index);

    setTbls(data);
  };

  const tbl = [
    {
      title: "S/N",
      custom: true,
      component: (item, index) => index + 1,
      className: "text-center",
    },
    {
      title: "Name",
      value: "description",
    },
    {
      title: "Unit Cost",
      custom: true,
      component: (item) => formatNumber(item.cost),
      className: "text-right",
    },
    {
      title: "Quantity",
      custom: true,
      component: (item) => item.quantity,
      className: "text-center",
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => formatNumber(item.quantity * item.cost),
      className: "text-right",
    },
    {
      title: "Action",
      custom: true,
      component: (item, idx) => (
        <div>
          <center>
            <CustomButton
              className="btn-danger"
              size="sm"
              onClick={() => {
                handleDelete(idx);
              }}
            >
              X
            </CustomButton>
          </center>
        </div>
      ),
    },
  ];
  const handleSubmit = () => {
    setLoading(true);
    addPurchase(
      tbls,
      user_id,
      society_id,
      (res) => {
        console.log(res);
        setLoading(false);
        _customNotify("Item Purchase recorded successfully");
        navigate.push(-1);
        // alert("submitted successfully");
        console.log(res, "LSLSL");
        setTbls([]);
        // }
      },
      (err) => {
        console.log(err);
        _warningNotify("Error Occured");
        setLoading(false);
      }
    );
    setTbls([]);
    _customNotify("Item Purchase recorded successfully");
    navigate.push(-1);
    setLoading(false);
  };

  // const preventNavigation = tbls?.length;
  return (
    <div className="p-1">
      {/* {JSON.stringify(user)} */}
      <CustomCard back header="Purchase Item">
        <CustomForm fields={fields} handleChange={handleChange} />
        <center>
          <CustomButton className="m-3" onClick={handleAdd}>
            Add to List
          </CustomButton>
        </center>
        {/* {show && ( */}
        <>
          <CustomTable bordered size="sm" fields={tbl} data={tbls} />{" "}
          <center>
            <CustomButton loading={loading} onClick={handleSubmit}>
              Submit
            </CustomButton>
          </center>
        </>
        {/* )} */}
      </CustomCard>
    </div>
  );
}
