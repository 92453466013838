import Loading from "components/UI/Loading";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { getMembers } from "redux/actions/society";

export default function MemberListInput(props) {
  const { members } = useSelector((o) => o.society);
  const { society } = useSelector((o) => o.auth);
  const { label = true } = props;
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);

  const allMembers = useCallback(() => {
    setFetching(true);
    dispatch(
      getMembers(
        society.id,
        () => setFetching(false),
        () => setFetching(false)
      )
    );
  }, [society.id, dispatch]);

  useEffect(() => {
    allMembers();
  }, [allMembers]);

  return (
    <FormGroup style={{ marginTop: -10 }} className={props.container}>
      {fetching && <Loading size="sm" />}
      {label && <label>Select member</label>}
      <select
        className="form-control"
        type="select"
        onChange={props.onChange}
        value={props.value}
      >
        <option>---select---</option>
        {members.map((item) => (
          <option
            labelkey={item.user_id}
            key={item.user_id}
            value={item.user_id}
          >
            {item.firstname} {item.lastname} ({item.user_id})
          </option>
        ))}
      </select>
    </FormGroup>
  );
}
