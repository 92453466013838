import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import CustomCard from "components/UI/CustomCard";
import CustomModal from "components/UI/CustomModal";
import { _formatNumber } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Card, CardTitle, Col, Row } from "reactstrap";
import { getImageUrl } from "redux/actions/api";
import { rejectStatus } from "redux/actions/society";
import { getMemberView } from "redux/actions/society";
import { updateStatus } from "redux/actions/society";
import { getGrantorReqs } from "redux/actions/society";
import { theme } from "variables";
import { CURRENCY } from "variables";
import { EmptyList } from "../Admin/ReportDetails";

function GrantorList() {
  const { user } = useSelector((state) => state.auth);
  const [list, setList] = useState([]);
  const [member, setMember] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const _getGrantorReqs = useCallback(() => {
    getGrantorReqs(
      user.id,
      (data) => {
        console.log(data);
        console.log("data");
        setList(data);
      },
      (err) => {
        toast.error(err.message);
      }
    );
  }, []);
  const _getMemberView = useCallback((id) => {
    getMemberView(
      id,
      (data) => {
        setMember(data[0]);
        toggle();
      },
      (err) => {
        toast.error(err.message);
      }
    );
  }, []);
  const _updateStatus = useCallback((id, loan_application_id, grantor_type) => {
    updateStatus(
      id,
      loan_application_id,
      grantor_type,
      (data) => {
        toast.success(data.message);
        _getGrantorReqs();
      },
      (err) => {
        toast.error(err.message);
        console.log(err);
      }
    );
  }, []);
  const _rejectStatus = useCallback((id, loan_id, grantor_type) => {
    rejectStatus(
      id,
      loan_id,
      grantor_type,
      (data) => {
        toast.success(data.message);
        _getGrantorReqs();
      },
      (err) => {
        toast.error(err.message);
      }
    );
  }, []);
  useEffect(() => {
    _getGrantorReqs();
  }, [_getGrantorReqs]);
  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
      ),
    },
    { title: "Member Name", value: "member_name" },
    {
      title: `Loan Amount (${CURRENCY})`,
      custom: true,
      component: (item) => (
        <div className="text-right">{_formatNumber(item.loan_amount)}</div>
      ),
    },
    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <StatusBadge status={item.status} />
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            size="sm"
            onClick={() => {
              _getMemberView(item.id);
            }}
          >
            View Member
          </CustomButton>
          <CustomButton
            size="sm"
            onClick={() => {
              _updateStatus(
                item.id,
                item.loan_application_id,
                item.grantor_type
              );
            }}
          >
            Approve
          </CustomButton>
          <CustomButton
            size="sm"
            color="danger"
            onClick={() => {
              _rejectStatus(
                item.id,
                item.loan_application_id,
                item.grantor_type
              );
            }}
          >
            Reject
          </CustomButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Card body>
        <CardTitle className="h4 text-center mb-2">Guarantor Request</CardTitle>
        <CustomTable fields={fields} data={list} />
        {list.length ? null : (
          <EmptyList message="There are no pending guarantor request at this time, check back later." />
        )}
      </Card>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        footerButton={
          <div className="m-2">
            <CustomButton outline color="danger" onClick={toggle}>
              Cancel
            </CustomButton>
            <CustomButton
              onClick={() => {
                _updateStatus(
                  member.id,
                  member.loan_application_id,
                  member.grantor_type
                );
                setIsOpen(false);
              }}
            >
              Approve
            </CustomButton>
          </div>
        }
      >
        <div className="text-center">
          {member?.profile_pic && member?.profile_pic !== "" ? (
            <img
              src={getImageUrl(member?.profile_pic)}
              style={{ height: 90, width: 80 }}
              className="rounded"
            />
          ) : (
            <FaUser size={90} color={theme.primary.main.color} />
          )}
        </div>
        <Row className="ml-3 mt-4">
          <Col md={6}>
            <b>Member Name</b>: {member?.member_name}
          </Col>
          <Col md={6}>
            <b>Loan Amount</b>: {formatNumber(member?.loan_amount)}
          </Col>

          <Col md={6}>
            <b>Email</b>: {member?.email}
          </Col>
          <Col md={6}>
            <b>Phone Number</b>: {member?.phone}
          </Col>

          <Col md={6}>
            <b>Application Date </b>:{" "}
            {moment(member?.created_at).format("YYYY-MM-DD")}
          </Col>
          <Col md={6}>
            <b>Status</b>: <StatusBadge status={member?.status} />
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
}

export default GrantorList;
