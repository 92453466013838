import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// import toast, { Toaster } from 'react-hot-toast';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactGA from "react-ga";
import AccountSwitch from "views/examples/AccountSwitch";
const TRACKING_ID = "3984783540"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
import './assets/css/animate.min.css'
import './assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0'
import './assets/css/demo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import AdminLayout from 'layouts/Admin'
import MemberLayout from 'layouts/Member'
import OrgSignup from 'views/app/membership/AdminForm'
import MemberAuth from 'views/app/membership/MemberForm'
import { init } from 'redux/actions/auth'
import store from 'redux/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import Login from 'views/app/auth/Login'
import Signup from 'views/app/auth/Signup'
import { Toaster } from 'react-hot-toast'
import ForgetPassword from 'views/app/auth/ForgetPassword'
import EmailSent from 'views/app/auth/EmailSent'
import ResetPassword from 'views/app/auth/ResetPassword'
import EnterCode from 'views/app/auth/EnterCode'

export default function App() {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!authenticated) {
      dispatch(init(history, (f) => f, location));
    }
  }, [authenticated]);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/auth/login" />
        <Route path="/auth/forget-password/" render={(props) => <ForgetPassword {...props} />} />
        <Route path="/admin/" render={(props) => <AdminLayout {...props} />} />
        <Route
          path="/member/"
          render={(props) => <MemberLayout {...props} />}
        />
        <Route
          path="/membership/:id"
          render={(props) => <MemberAuth {...props} />}
        />
        <Route
          path="/auth/signup"
          render={(props) => <OrgSignup {...props} />}
        />
        <Route path="/auth/login" render={(props) => <Login {...props} />} />
        <Route path="/auth/forget-password" render={(props) => <ForgetPassword {...props} />} />
        <Route path="/auth/email-sent" render={(props) => <EmailSent {...props} />} />
        <Route path="/auth/reset-password" render={(props) => <ResetPassword {...props} />} />
        <Route path="/auth/enter-code" render={(props) => <EnterCode {...props} />} />
        <Route
          path="/auth/register"
          render={(props) => <Signup {...props} />}
        />
        <Route
          path="/auth/account-switch"
          render={(props) => <AccountSwitch {...props} />}
        />
        
      </Switch>
      <Toaster
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
        }}
      />
    </>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);