// app
export default {
  auth: {
    AUTH_USER: "AUTH_USER-P32",
    AUTH_SOCIETY: "AUTH_SOCITY",
    AUTH_ERRORS: "AUTH_ERRORS",
    LOGOUT: "AUTH-LOGOUT",
    AUTH_ERROR: "AUTH-ERROR",
    UPDATE_ROLE: "UPDATE_ROLE",
    UPDATE_USER: "UPDATE_USER",
  },
  dashboard: {
    UPDATE_DASHBOARD_SUMMARY: "UPDATE_DASHBOARD_SUMMARY",
    UPDATE_DASHBOARD_GRAPH: "UPDATE_DASHBOARD_GRAPH",
    REPORT_BY_TYPE: "REPORT_BY_TYPE",
    LOAN_REPORT: "LOAN_REPORT",
    USER_SUMMARY: "USER_SUMMARY",
  },
  society: {
    MEMBERS: "MEMBERS-LIST",
    EXCOS: "EXCOS-ADMINS",
    CURRENT: "MY-CURRENT-SOCIETY",
  },
  loan: {
    SET_LOAN_SCHEDULE_BY_LOAN: "SET_LOAN_SCHEDULE_BY_LOAN",
    SET_SELECTED_LOAN_PAYMENT: "SET_SELECTED_LOAN_PAYMENT",
    CLEAR_LOAN_PAYMENT_SELECTION: "CLEAR_LOAN_PAYMENT_SELECTION",

    SET_PAYMENT_LIST: "SET_PAYMENT_LIST",
    SET_SELECTED_PAYMENT_LIST: "SET_SELECTED_PAYMENT_LIST",
    CLEAR_SELECTED_PAYMENT_LIST: "CLEAR_SELECTED_PAYMENT_LIST",
  },
  contribution: {
    SET_CONTRIBUTION_SCHEDULE: "SET_CONTRIBUTION_SCHEDULE",
  },
  ERROR: "ERROR-ENCOUNTERED",

  LOADING: "LOADING-DATA",
};

// inventory action types
export const LOAD_EXPIRED = "LOAD_EXPIRED";
export const LOAD_EXPIRY_ALERT = "LOAD_EXPIRY_ALERT";
export const LOAD_QTTY_ALERT = "LOAD_QTTY_ALERT";
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_PURCHASE_RECORDS = "GET_PURCHASE_RECORDS";
export const GET_PENDING_PURCHASES = "GET_PENDING_PURCHASES";
export const SET_INVENTORY = "SET_INVENTORY";
export const SET_PURCHASE_RECORDS = "SET_PURCHASE_RECORDS";
export const ADD_DRUG = "ADD_DRUG";
export const PURCHASE_RECORDS_LOADING = "PURCHASE_RECORDS_LOADING";
export const SUBMIT_DRUG_LOADING = "SUBMIT_DRUG_LOADING";
export const UPDATE_DRUG = "UPDATE_DRUG";
export const UPDATING_DRUG = "UPDATING_DRUG";
export const PENDING_PURCHASES_LOADING = "PENDING_PURCHASES_LOADING";
export const DISPENSING_DRUGS = "DISPENSING_DRUGS";
export const DISPENSE_DRUGS = "DISPENSE_DRUGS";
export const GETTING_ALL_SUPPLIERS = "GETTING_ALL_SUPPLIERS";
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATING_SUPPLIERS = "CREATING_SUPPLIERS";
export const UPDATING_SUPPLIER = "UPDATING_SUPPLIER";
export const DELETING_SUPPLIER = "DELETING_SUPPLIER";
export const GET_DRUGS = "GET_DRUGS";
export const ADDING_DRUG = "ADDING_DRUG";
export const DRUGS_LOADING = "DRUGS_LOADING";
export const DELETING_DRUG = "DELETING_DRUG";
export const GET_CUSTOMERS = "GET_CUSTMERS";
export const GETTING_CUSTOMERS = "GETTING_CUSTOMERS";
export const GET_OTHER_EXPENSES = "GET_OTHER_EXPENSES";

export const GET_SUPPLIER_INFO = "GET_SUPPLIER_INFO";
export const GET_SUPPLIER_STATEMENT = "GET_SUPPLIER_STATEMENT";
export const GET_DRUG_LIST = "GET_DRUG_LIST";
export const OUT_OF_STOCK_LIST = "OUT_OF_STOCK_LIST";
export const GET_PURCHASE_ITEM = "GET_PURCHASE_ITEM";
export const GET_INVENTORY_STORE = "GET_INVENTORY_STORE";
export const INVENTORY_LOADING = "INVENTORY_LOADING";
export const GET_CLIENT_INFO = "GET_CLIENT_INFO";
export const GET_CLIENT_STATEMENT = "GET_CLIENT_STATEMENT";
export const SALES = "SALES";
export const INVENTORY_USER = "INVENTORY_USER";
export const RECEIPT_DATA = "RECEIPT_DATA";
export const SUPPLIER_COUNT = "SUPPLIER_COUNT";
export const GET_STOCK_INFO = "GET_STOCK_INFO";
export const GET_STOCK_INFO_STORE = "GET_STOCK_INFO_STORE";
export const GET_STOCK_INFO_STORE_SHELF = "GET_STOCK_INFO_STORE_SHELF";
export const GET_TOP_SALES = "GET_TOP_SALES";
export const GET_REORDER_LEVEL = "GET_REORDER_LEVEL";
export const GET_EXPIRY_ALERT = "GET_EXPIRY_ALERT";
export const GET_TOTAL_DRUG = "GET_TOTAL_DRUG";
export const CLEAR_INVENTORY_SALES_SUMMARY = "CLEAR_INVENTORY_SALES_SUMMARY";
export const GETTING_TOP_5_POPULAR_DRUGS = "GETTING_TOP_5_POPULAR_DRUGS";
export const GET_INVENTORY_SALES_SUMMARY = "GET_INVENTORY_SALES_SUMMARY";
export const GET_TOP_5_POPULAR_DRUGS = "GET_TOP_5_POPULAR_DRUGS";
export const GETTING_INVENTORY_SALES_SUMMARY =
  "GETTING_INVENTORY_SALES_SUMMARY";
// export const SET_INVENTORY = 'SET_INVENTORY';
// export const SET_PURCHASE_RECORDS = 'SET_PURCHASE_RECORDS';
// export const ADD_DRUG = 'ADD_DRUG';
// export const INVENTORY_LOADING = 'INVENTORY_LOADING';
// export const PURCHASE_RECORDS_LOADING = 'PURCHASE_RECORDS_LOADING';
// export const SUBMIT_DRUG_LOADING = 'SUBMIT_DRUG_LOADING';
// export const UPDATE_DRUG = 'UPDATE_DRUG';
// export const UPDATING_DRUG = 'UPDATING_DRUG';
// export const PENDING_PURCHASES_LOADING = 'PENDING_PURCHASES_LOADING';
// export const DISPENSING_DRUGS = 'DISPENSING_DRUGS';
// export const DISPENSE_DRUGS = 'DISPENSE_DRUGS';
// export const GETTING_ALL_SUPPLIERS = 'GETTING_ALL_SUPPLIERS';
// export const GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS';
// export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
// export const CREATING_SUPPLIERS = 'CREATING_SUPPLIERS';
// export const UPDATING_SUPPLIER = 'UPDATING_SUPPLIER';
// export const DELETING_SUPPLIER = 'DELETING_SUPPLIER';
// export const GET_DRUGS = 'GET_DRUGS';
// export const ADDING_DRUG = 'ADDING_DRUG';
// export const DRUGS_LOADING = 'DRUGS_LOADING';
// export const DELETING_DRUG = 'DELETING_DRUG';
// export const GET_CUSTOMERS = 'GET_CUSTMERS';
// export const GETTING_CUSTOMERS = 'GETTING_CUSTOMERS';
export const SET_BED_LIST = "SET_BED_LIST";
export const SET_AVAILABLE_BED_LIST = "SET_AVAILABLE_BED_LIST";
export const SET_BED_OBJ = "SET_BED_OBJ";
export const SET_AVAILABLE_BED_OBJ = "SET_AVAILABLE_BED_OBJ";
export const SET_IN_PATIENT_LIST = "SET_IN_PATIENT_LIST";
export const SET_PATIENTS_DISCHARGED_BY_DOCTOR =
  "SET_PATIENTS_DISCHARGED_BY_DOCTOR";
export const SET_PENDING_INVENTORY_REQUEST = "SET_PENDING_INVENTORY_REQUEST";
export const PATIENT_PASSPORT = "PATIENT_PASSPORT";
