import React from "react";
import { Input } from "reactstrap";
// import { themeClass } from "variables";

function Checkbox(props) {
  const {
    container = "",
    label = "",
    key = "1",
    checked = false,
    onChange = (f) => f,
    mb = "",
  } = props;

  return (
    <div
      className={`custom-control custom-checkbox ${
        mb ? mb : "mb-3"
      } ${container}`}
    >
      <label
        className="custom-control-label font-weight-boldb "
        htmlFor={`${props.label}${props.name}-${key}`}
      >
        <Input
          className="custom-control-input"
          id={`${props.label}${props.name}-${key}`}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />

        <span>{label}</span>
      </label>
    </div>
  );
}

export default Checkbox;
