import { _postApi } from "redux/actions/api";

export function getRequest(
  data = {},
  query_type = "select",
  society_id,
  cb = (f) => f
) {
  _postApi(
    `/inventory/v1/get-request?query_type=${query_type}&society_id=${society_id}`,
    data,
    (e) => cb(e),
    (e) => console.log(e)
  );
}

export function expensesApi(
  data = {},
  query_type = "select",
  society_id,
  cb = (f) => f
) {
  _postApi(
    `/inventory/v1/expenses?query_type=${query_type}&society_id=${society_id}`,
    data,
    (e) => cb(e),
    (e) => console.log(e)
  );
}
