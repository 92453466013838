import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Card, CardTitle } from "reactstrap";
import { updateApprovalLevel } from "redux/actions/society";

function LevelOfApproval({ title = "" }) {
  const { society, user } = useSelector((state) => state.auth);
  const [form, setForm] = useState({
    level_of_approval: society.approval_level,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { value, name } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleUpate = () => {
    setLoading(true);
    updateApprovalLevel(
      user.id,
      society.id,
      form.level_of_approval,
      (resp) => {
        setLoading(false);
        console.log(false);
        // if (resp) {
        //   toast.success(resp.message);
        // } else {
        //   toast.error(resp.message);
        // }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <Card body>
      <CardTitle className="text-center h4 mb-2">{title}</CardTitle>

      <CustomForm
        handleChange={handleChange}
        fields={[
          {
            label: "Level Of Approval",
            name: "level_of_approval",
            col: 12,
            type: "number",
            value: form.level_of_approval,
          },
        ]}
      />
      {/* {JSON.stringify(loading)} */}
      <center>
        <CustomButton
          size="sm"
          loading={loading}
          onClick={() => {
            handleUpate();
          }}
        >
          Save Changes
        </CustomButton>
      </center>
    </Card>
  );
}

export default LevelOfApproval;
