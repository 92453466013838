import React, { useState, useEffect } from "react";
import { FormGroup, Card, Button, Input } from "reactstrap";
import { MdUpdate } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton } from "components/UI";
import { submitBasicInfo } from "redux/actions/auth";
import { _updateApi } from "redux/actions/api";
import toast from "react-hot-toast";
// import moment from 'moment';
import { getImageUrl } from "redux/actions/api";
import { apiURL } from "variables";

function ProfilePicture() {
  const user = useSelector((state) => state.auth.user);
  const { society } = useSelector((state) => state.auth);

  const [loading, toggle] = useState(false);
  const [imgfile, uploadimg] = useState([]);
  const dispatch = useDispatch();
  const [profileImage,setProfileImage] =useState({})

  const handleSubmit = () => {
    const userId = user.id;
    let formData = new FormData();
    formData.append("profile_pic", profileImage);
    toggle(true);
    fetch(`${apiURL}/update-profile-picture/${userId}`, {
      method: "POST",
      body: formData,
    })
      .then((raw) => raw.json())
      .then((resp) => {
        if (resp.success) {
          toast.success("Picture Update Successfully");
          toggle(false);
        } else {
          toast.error("Error Occur");
          toggle(false);
        }
      })
      .catch((e) => {
        toast.error("Error Occur");
        toggle(false);
      });
    console.log(formData);
  };

  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      setProfileImage(e.target.files[0])
      uploadimg((imgfile) => [
        ...imgfile,
        URL.createObjectURL(e.target.files[0]),
      ]);
    }
  };

  return (
    <Card className="p-2 mb-2">
      <h6 className="font-weight-bold">Profile Picture</h6>
      <center>
        {/* {JSON.stringify(user.profile_pic)} */}
        <label>
          {/* {JSON.stringify(imgfile)} */}
          {!imgfile.length && (
            <span>
              <img
                src={getImageUrl(
                  user.profile_pic
                )}
                height="200"
                width="200"
                alt="med1"
                style={{ borderRadius: 30 }}
              />
            </span>
          )}
          {/* {JSON.stringify(imgfile)} */}
          {imgfile.map((elem) => {
            return (
              <>
                <span key={elem}>
                  <img
                    src={elem}
                    height="200"
                    width="200"
                    alt="med1"
                    style={{ borderRadius: 30 }}
                  />
                </span>
              </>
            );
          })}
          <br />
          <input
            type="file"
            onChange={imgFilehandler}
            height="100"
            width="100"
            style={{ display: "none" }}
          />
        </label>
        <br />
      </center>
      <div className="d-flex flex-row justify-content-center">
        <CustomButton
          color="primary"
          className="m-2"
          loading={loading}
          onClick={handleSubmit}
        >
          <MdUpdate size={20} className="mr-1" />
          Update Profile Picture
        </CustomButton>
      </div>
    </Card>
  );
}

export default ProfilePicture;
