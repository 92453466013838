import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import React from "react";

function StructureForm({
  handleSubmit,
  loading,
  fields,
  handleChange,
  form,
  handleUpdate,
}) {
  return (
    <div>
      <CustomForm fields={fields} handleChange={handleChange} />
      <div className="my-2 mx-3">
        <CustomButton
          loading={loading}
          onClick={() =>
            form.update ? handleUpdate(form) : handleSubmit(form)
          }
        >
          {form.update ? "Update" : "Create"}
        </CustomButton>
      </div>
    </div>
  );
}

export default StructureForm;
