import { useEffect } from "react";
import {
  Card,
  Navbar,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import { getUserDashboardSummary } from "redux/actions/reports";
import { getDashboardSummary } from "redux/actions/reports";
import { theme } from "variables";
import { CURRENCY } from "variables";
import { formatNumber, _formatNumber } from "./helpers";
import "./style.css";

export default function CustomLoanStatCard({
  title,
  onDefaultersClick = (f) => f,
  range = {},
  index = 0,
}) {
  const getBackgroundColor = (index) => {
    const colors = ["#3ecd5e", "#e44002", "#952aff", "#cd3e94", "#4c49ea"];
    return colors[index % colors.length];
  };

  const location = useLocation();
  const isMembersDashboard =
    location.pathname.includes("/member-dashboard") ||
    location.pathname.includes("/member/dashboard");
  const dispatch = useDispatch();
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);
  const reports =
    useSelector((state) => state.reports)[title + " Summary"] || {};

  useEffect(() => {
    if (isMembersDashboard) {
      dispatch(
        getUserDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user.id,
        })
      );
    } else {
      dispatch(
        getDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
        })
      );
    }
  }, [society.id, dispatch, range.from, range.to]);

  // let eligibleAmount =
  //   society.max_loan_amount_type === "Percentage"
  //     ? parseInt(society.contribution_balance) *
  //       (parseFloat(society.max_loan_amount) / 100)
  //     : society.max_loan_amount - parseInt(reports.balance || 0);

  let eligibleAmount = society.contribution_balance * 2;
  return (
    <div className="ag-format-container col-md-4" onClick={onDefaultersClick}>
      {/* {JSON.stringify(reports)} */}
      <div className="ag-courses_box">
        <div className={`ag-courses_item`}>
          <a href="#" className="ag-courses-item_link">
            <div
              className="ag-courses-item_bg"
              style={{
                backgroundColor: getBackgroundColor(index),
                zIndex: 1,
                position: "absolute",
                top: "-75px",
                right: "-75px",
                borderRadius: "50%",
              }}
            ></div>

            <div className="ag-courses-item_date-box">
              <span className="ag-courses-item_date">
                {" "}
                Loan Eligibility Amount
              </span>
            </div>
            <div className="ag-courses-item_title">
              {CURRENCY} {_formatNumber(eligibleAmount)}
            </div>

            <center>
              <p
                className={` text-sm ag-courses-item_title`}
                style={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Outstanding Loan{" "}
                <span className="">
                  {CURRENCY} {_formatNumber(reports.balance)}
                </span>
              </p>
            </center>
          </a>
        </div>
      </div>
    </div>
  );
}
