import React from "react";
import { useSelector } from "react-redux";
import PasswordDetails from "../examples/PasswordDetails";
import BankDetails from "../examples/BankDetails";
import { Col, Row } from "reactstrap";
import EmployerInfo from '../examples/EmployerInfo'
import NextOfKin from '../examples/NextOfKin'
import DefaultContribution from "views/examples/DefaultContribution";
import CooperateAccountDetails from "views/examples/CooperateAccountDetails";

function Profile() {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <Row>
        <Col sm={12} md={6} xl={6}>
          <DefaultContribution />
        </Col>
        <Col sm={12} md={6} xl={6}>
         <CooperateAccountDetails />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} xl={6}>
          <PasswordDetails />
        </Col>
        <Col sm={12} md={6} xl={6}>
          {" "}
          <BankDetails />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} xl={6}>
          <EmployerInfo />
        </Col>
        <Col sm={12} md={6} xl={6}>
          {" "}
          <NextOfKin />
        </Col>
      </Row>
    </>
  );
}

export default Profile;
