import { CustomButton } from "components/UI";
import { Checkbox } from "components/UI";
import { SelectInput } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { formatNumber } from "components/UI/helpers";
import ModalNotification from "components/UI/ModalNotification";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { getPendingLoanSchedule } from "redux/actions/account";
import { _fetchApi } from "redux/actions/api";
import { _postApi } from "redux/actions/api";
import { getDashboardSummary } from "redux/actions/reports";
import { _getUserDashboardSummary } from "redux/actions/reports";
import types from "redux/actions/types";
import { CURRENCY } from "variables";
import { themeClass } from "variables";
import { getRoleLink } from "views/helper";
import { EmptyList } from "../Admin/ReportDetails";

function Withdrawal() {
  const today = moment().format("YYYY-MM-DD");
  const startOfYear = moment().startOf("y").format("YYYY-MM-DD");
  const [range, setRange] = useState({ from: startOfYear, to: today });
  const dispatch = useDispatch();
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment },
  } = useSelector((s) => s);
  const title = "Special contribution list";
  const reports =
    useSelector((state) => state.reports[title + " _Summary"]) || [];
  const [selectedAccount, setSelectedAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const isMembersDashboard = true;
  const [activeTab, setActiveTab] = useState("");

  const handleChecked = (i) => {
    // console.log(i)
    if (
      selectedLoanPayment.findIndex(
        (a) => a.loan_id === i.loan_id && a.schedule_date === i.schedule_date
      ) === -1
    ) {
      let list = [
        ...selectedLoanPayment,
        { ...i, account_description: i.loan_title },
      ];
      dispatch({ type: types.loan.SET_SELECTED_LOAN_PAYMENT, payload: list });
      // setSelected((p) => [...p, i])
    } else {
      let filtered = selectedLoanPayment.filter(
        (a) => a.loan_id === i.loan_id && a.schedule_date !== i.schedule_date
      );
      dispatch({
        type: types.loan.SET_SELECTED_LOAN_PAYMENT,
        payload: filtered,
      });
      // setSelected(filtered)
    }
  };

  useEffect(() => {
    dispatch(getPendingLoanSchedule((defaultTab) => setActiveTab(defaultTab)));
  }, [dispatch]);

  const totalAmountSelected = selectedLoanPayment.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  useEffect(() => {
    if (isMembersDashboard) {
      dispatch(
        _getUserDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
          user_id: user.id,
        })
      );
    } else {
      dispatch(
        getDashboardSummary({
          query_type: title,
          society_id: society.id,
          from: range.from,
          to: range.to,
        })
      );
    }
  }, [society.id, dispatch, range.from, range.to]);
  const [form, setForm] = useState({
    members: "",
    date: today,
    member: "",
    special_saving_balance: "",
    total_balance: "",
    amount_request: "",
    member_code: "",
    withdrawal_type: "Cash Withdrawal",
  });

  const [results, setResults] = useState();
  const fields = [
    // {
    //   name: "date",
    //   label: "Date",
    //   type: "date",
    //   col: 6,
    // },
    // {
    //   name: "members",
    //   label: "Select member",
    //   type: "select",
    //   options: results && results.map((item) => item.member),
    //   col: 6,
    // },
    {
      type: "custom",
      label: "Select Account:",
      // value: user.firstname + " " + user.lastname,
      // value: form.account,
      // options: reports?.map(a => a.account_description),
      offset: 0,
      col: 6,
      component: () => (
        <>
          <SelectInput
            label="Select the account you want to withdraw from"
            options={reports.map(
              (a) => a.account_head + " - " + a.account_description
            )}
            value={form.account}
            onChange={({ target: { value } }) => {
              let selectedHead = value.split(" - ")[0];
              let selectedAcc = reports.find(
                (a) => a.account_head === selectedHead
              );
              setSelectedAccount(selectedAcc);
              setForm((p) => ({ ...p, account: value }));
            }}
          />
        </>
      ),
    },
    // {
    //   type: 'label',
    //   label: 'Special Saving Balance:',
    //   value: formatNumber(selectedAccount.total_deposit),
    //   offset: 0,
    //   col: 4,
    // },
    {
      type: "label",
      label: "Balance:",
      value: `${CURRENCY}${formatNumber(
        selectedAccount.balance
      )}`,
      offset: 0,
      col: 3,
    },
    {
      type: "custom",
      offset: 0,
      col: 6,
      component: () => (
        <>
          <SelectInput
            label="Withdraw type"
            options={["Cash Withdrawal", "Loan Repayment"]}
            value={form.withdrawal_type}
            name="withdrawal_type"
            onChange={({ target: { value, name } }) => {
              setForm((p) => ({ ...p, [name]: value }));
            }}
          />
        </>
      ),
    },
    {
      name: "amount_request",
      label: "Enter the amount you want to request",
      type: "number",
      value: form.amount_request,
      col: 6,
    },
  ];

  const handleChange = ({ target: { value, name } }) => {
    if (parseFloat(value) > parseFloat(selectedAccount.balance)) {
      toast.error("Amount Requested should not be more than balance");
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
      if (name === "members") {
        let selected = results.find((a) => a.member === value);
        if (selected) {
          setForm({
            ...form,
            member: value,
            members: selected.name,
            credit: selected.credit,
            debit: selected.debit,
            member_code: selected.member_code,
          });
        }
        console.log(selected);
      }
    }
  };
  const history = useHistory();
  const handleLoanRepayment = () => {
    setLoading(true);
    _postApi(
      `/new-withdrawal-repayment`,
      {
        selectedLoanPayment,
        selectedAccount,
        totalAmountSelected,
        member_name: `${user.firstname} ${user.lastname}`,
        society_id: society.id,
        member_id: user.id,
      },
      (resp) => {
        setLoading(false);
        if (resp.success) {
          toast.success(resp.message);
          history.push(getRoleLink("/my-pending-payments"));
        } else {
          toast.error(resp.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      `/withdrawal/new-request`,
      {
        total_amount: form.amount_request,
        society_id: society.id,
        member_id: user.id,
        member_name: user.firstname + " " + user.lastname,
        account_head: selectedAccount.account_head,
        account_description: selectedAccount.account_description,
      },
      (resp) => {
        setLoading(false);
        if (resp.success) {
          toast.success(resp.message);
          history.push(getRoleLink("/my-pending-payments"));
        } else {
          toast.error(resp.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  const handleModalOn = () => {
    if (!Object.keys(selectedAccount).length) {
      toast.error("Please select the account you want to withdraw from");
    } else {
      if (form.amount_request === "") {
        toast.error("Please put the amount");
      } else if (
        parseFloat(form.amount_request) > parseFloat(selectedAccount.balance)
      ) {
        toast.error("Amount Requested should not be more than balance");
      } else {
        setConfirmationModalIsOpen(true);
      }
    }
  };

  return (
    <div>
      <BackButton className="" />
      <Card>
        <CardBody>
          <CustomForm
            fields={
              form.withdrawal_type === "Cash Withdrawal"
                ? fields
                : fields.filter((item) => item.name !== "amount_request")
            }
            handleChange={handleChange}
          />
          {form.withdrawal_type !== "Cash Withdrawal" ? (
            <LoanReplayment
              handleChecked={handleChecked}
              loanScheduleByLoan={loanScheduleByLoan}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              totalAmountSelected={totalAmountSelected}
              loading={loading}
              selectedLoanPayment={selectedLoanPayment}
              handleSubmit={() => setConfirmationModalIsOpen((p) => !p)}
            />
          ) : (
            ""
          )}
          {form.withdrawal_type === "Cash Withdrawal" ? (
            <center>
              <CustomButton
                className="text-center"
                loading={loading}
                onClick={() => handleModalOn()}
              >
                Submit Request
              </CustomButton>
            </center>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
      <ModalNotification
        isOpen={confirmationModalIsOpen}
        toggle={() => setConfirmationModalIsOpen((p) => !p)}
        confirmText="YES"
        loading={loading}
        confirm={
          form.withdrawal_type !== "Cash Withdrawal"
            ? handleLoanRepayment
            : handleSubmit
        }
      >
        <h4>
          Are you sure you want to
          {form.withdrawal_type !== "Cash Withdrawal"
            ? " " +
              ` Withdrawal the sum of ${CURRENCY} ${parseFloat(
                totalAmountSelected
              ).toFixed(2)} from ${
                selectedAccount.account_description
              } and make a Loan Repayment`
            : ` ${CURRENCY}` +
              formatNumber(
                form.amount_request
              ) +
              " " +
              ` send a withdrawal request of`}
          ?
        </h4>
      </ModalNotification>
    </div>
  );
}

const LoanReplayment = ({
  setActiveTab = (f) => f,
  activeTab = false,
  loanScheduleByLoan = [],
  handleChecked = (f) => f,
  totalAmountSelected = 0,
  selectedLoanPayment = [],
  handleSubmit = (f) => f,
  loading = false,
}) => {
  return (
    <>
      <div>
        {Object.keys(loanScheduleByLoan).length ? (
          <>
            <div className="d-flex flex-direction-row justify-content-end align-items-center">
              <CustomButton
                disabled={totalAmountSelected > 0 ? false : true}
                onClick={handleSubmit}
                // loading={loading}
              >
                Save ({CURRENCY}
                {parseFloat(totalAmountSelected).toFixed(2)})
              </CustomButton>
            </div>
            <Nav tabs>
              {Object.keys(loanScheduleByLoan).map((i, id) => (
                <NavItem>
                  <NavLink
                    className={activeTab === i ? "active" : ""}
                    onClick={() => setActiveTab(i)}
                    key={id}
                  >
                    {i}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {Object.keys(loanScheduleByLoan).map((i, id) => {
                return (
                  <TabPane tabId={i}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>Month, Year</th>
                          <th>Amount Due</th>
                          <th>Fine</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loanScheduleByLoan[i].map((item, ix) => {
                          let checked =
                            selectedLoanPayment.findIndex(
                              (a) =>
                                a.loan_id === item.loan_id &&
                                a.schedule_date === item.schedule_date
                            ) !== -1;
                          return (
                            <tr key={ix}>
                              <td>
                                <Checkbox
                                  id={ix}
                                  name={i + ix}
                                  checked={checked}
                                  onChange={() => handleChecked(item)}
                                />
                              </td>
                              <td>
                                {/* {item.schedule_month}, */}
                                {/* {JSON.stringify(item)} */}
                                {moment(item.schedule_date).format("MMM, YYYY")}
                              </td>
                              <td>{formatNumber(item.amount)}</td>
                              {/* check */}
                              <td>{formatNumber(0)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TabPane>
                );
              })}
            </TabContent>
          </>
        ) : (
          <EmptyList message='You currently do not have any loan to repay, kindly click "My Loans" on the menu to apply for loan.' />
        )}
      </div>
    </>
  );
};
export default Withdrawal;
