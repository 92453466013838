import React, { useState, useEffect } from "react";
import { FormGroup, Card, Button, Input, CardHeader } from "reactstrap";
import { MdUpdate } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton } from "components/UI";
import { submitBasicInfo } from "redux/actions/auth";
import { _updateApi } from "redux/actions/api";
import toast from "react-hot-toast";
import { AiOutlineDown } from "react-icons/ai";
import { theme } from "variables";
import { CustomCotrolCard } from "components/UI/CustomCard";

function ProfileDetails() {
  const profile = useSelector((state) => state.auth.user);
  const { society } = useSelector((state) => state.auth);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    address: "",
    // email: "",
    phone: "",
    state: "",
  });
  const [loading, toggle] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const userId = user.id;
    toggle(true);
    _updateApi(
      `/users/${userId}`,
      user,
      () => {
        toggle(false);
        toast.success("Profile Updated");
      },
      (err) => {
        toggle(false);
        toast.error("An error occured");
        console.log("caught error", err);
      }
    );
    // dispatch(submitBasicInfo(user, () => toggle(false)));
  };

  useEffect(() => {
    setUser(profile);
  }, [profile]);

  const onInputChange = (key, value) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <CustomCotrolCard className=" " title="Basic Profile Information">
      <div className="p-3">
        <FormGroup row className="mt-3">
          <div className="col-md-6">
            <span className="">Title</span>
            <input
              onChange={(e) => onInputChange("title", e.target.value)}
              className="form-control"
              type="text"
              value={user.title}
            />
          </div>
          <div className="col-md-6">
            <span className="">First Name </span>
            <input
              onChange={(e) => onInputChange("firstname", e.target.value)}
              className="form-control"
              type="text"
              value={user.firstname}
            />
          </div>
        </FormGroup>

        <FormGroup row className="mt-3">
          <div className="col-md-6">
            <span className="">Middle Name</span>
            <input
              onChange={(e) => onInputChange("middlename", e.target.value)}
              className="form-control"
              type="text"
              value={user.middlename}
            />
          </div>
          <div className="col-md-6">
            <span className="">Last Name </span>
            <input
              onChange={(e) => onInputChange("lastname", e.target.value)}
              className="form-control"
              type="text"
              value={user.lastname}
            />
          </div>
        </FormGroup>
        <FormGroup row>
          <div className="col-md-6">
            <span className="">Phone: </span>
            <input
              onChange={(e) => onInputChange("phone", e.target.value)}
              className="form-control"
              type="tel"
              value={user.phone}
            />
          </div>

          <div className="col-md-6">
            <span className="">Address (Location):</span>
            <input
              onChange={(e) => onInputChange("address", e.target.value)}
              className="form-control"
              type="address"
              value={user.address}
            />
          </div>
        </FormGroup>
        <FormGroup row className="mb-0">
          <div className="col-md-6">
            <span className="">Email: </span>
            <input
              onChange={(e) => onInputChange("email", e.target.value)}
              className="form-control"
              type="email"
              value={user.email}
              disabled
            />
          </div>
          <div className="col-md-6">
            <span className="">State</span>
            <input
              onChange={(e) => onInputChange("state", e.target.value)}
              className="form-control"
              type="text"
              value={user.state}
            />
          </div>
        </FormGroup>

        <FormGroup row className="mb-0">
          <div className="col-md-6">
            <span className=""> LGA</span>
            <input
              onChange={(e) => onInputChange("lga", e.target.value)}
              className="form-control"
              type="lga"
              value={user.lga}
              // disabled
            />
          </div>
          <div className="col-md-6">
            <span className="">DOB</span>
            <input
              onChange={(e) => onInputChange("dob", e.target.value)}
              className="form-control"
              type="date"
              value={user.dob}
            />
          </div>
        </FormGroup>

        <div className="d-flex flex-row justify-content-center">
          <CustomButton
            color="primary"
            className="m-2"
            loading={loading}
            onClick={handleSubmit}
          >
            <MdUpdate size={20} className="mr-1" />
            Update Profile
          </CustomButton>
        </div>
      </div>
    </CustomCotrolCard>
  );
}

export default ProfileDetails;
