import { Switch, Route } from 'react-router'
import AccountSetupList from './AccountSetupList'
import NewAccountForm from './NewAccountForm'
import ViewAccountReport from './ViewAccountReport'

function AccountSetup() {
  return (
    <Switch>
      <Route exact path={'/admin/setup-account'} component={AccountSetupList} />
      <Route
        exact
        path={'/admin/setup-account/new'}
        component={NewAccountForm}
      />
      <Route
        exact
        path={'/admin/setup-account/view-report'}
        component={ViewAccountReport}
      />
    </Switch>
  )
}

export default AccountSetup
