import store from "redux/store";

const { api_url } = require("redux/actions");
const { _postApi } = require("redux/actions/api");

export const contribution = (cd = (f) => f, obj = {}, query_type = "") => {
  _postApi(
    `${api_url}/contribution?query_type=${query_type}`,
    obj,
    ({ results }) => {
      cd(results);
    },
    (err) => console.log(err)
  );
};

export const getRoleLink = (url) => {
  // const role = localStorage.getItem("@@role");
  const society = store.getState().auth.society;
  const role = store.getState().auth.society.role;
  return role
    ? `${role.toLowerCase() === "member" ? "/member" : "/admin"}${url}`
    : `${society.role.toLowerCase() === "member" ? "/member" : "/admin"}${url}`;
};
