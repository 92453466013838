import { CustomTable } from "components/UI";
import BackButton from "components/UI/BackButton";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardTitle, Row, Col, Input } from "reactstrap";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { CustomButton } from "components/UI";
import { CURRENCY } from "variables";
import ModalNotification from "components/UI/ModalNotification";
import { newLoanRecovery } from "./api";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { getRoleLink } from "views/helper";
import types from "redux/actions/types";
import { useQuery } from "hooks";
import { _fetchApi } from "redux/actions/api";
import { Checkbox } from "components/UI";
import { _formatNumber } from "components/UI/helpers";

function LoanRecoverPost() {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const loan_id = query.get("loan_id");
  const action = query.get("action");
  const status = query.get("status");
  const loan_head = query.get("loan_head");
  const loan_title = query.get("loan_title");
  const member_id = query.get("member_id");
  const [member, setMember] = useState({});
  const [checkallState, setCheckAllState] = useState(false);
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment },
  } = useSelector((s) => s);
  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [affectedMonths, setAffectedMonths] = useState([]);

  const handleAttachment = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files;
      setAttachment(Object.values(fileObj));
      //   console.log(Object.values(fileObj))
    }
  };

  const handleSubmit = () => {
    if (selectedLoanPayment.length) {
      setLoading(false);
      let obj = {
        payment_items: selectedLoanPayment,
        member_id: member_id ? member_id : user.id,
        member_name: member_id
          ? `${member.firstname} ${member.lastname}`
          : `${user.firstname} ${user.lastname}`,
        society_id: society.id,
        reference: Date.now(),
        affectedMonths,
        status,
        loan_id: loan_id,
        loan_head_description: loan_title,
        loan_head,
        total_amount_recovered: totalAmount,
      };

      newLoanRecovery(
        obj,
        (resp) => {
          console.log(resp);
          setApprovalConfirmation(false);
          setLoading(false);
          toast.success(resp.message);
          history.push(getRoleLink("/dashboard"));
          dispatch({ type: types.loan.CLEAR_LOAN_PAYMENT_SELECTION });
        },
        (e) => {
          setLoading(false);
          toast.error(e.message);
        }
      );
    }
  };

  const removeItem = (i) => {
    let list = selectedLoanPayment.filter((a) => a.identifier !== i.identifier);
    dispatch({
      type: types.loan.SET_SELECTED_LOAN_PAYMENT,
      payload: list,
    });
  };

  const tableFields = [
    {
      title: "Loan ID",
      custom: true,
      component: (i) => <div>{i.loan_id}</div>,
    },
    {
      title: "Date",
      custom: true,
      component: (i) => (
        <div>{moment(i.schedule_date).format("MMM, YYYY")}</div>
      ),
    },
    // {
    //   title: 'Account',
    //   custom: true, component: i => <div>{i.head}</div>
    // },
    {
      title: "Description",
      value: "description",
    },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.amount)}</div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (i) => (
        <div>
          <CustomButton
            size="sm"
            onClick={() => {
              removeItem(i);
            }}
          >
            Remove
          </CustomButton>
        </div>
      ),
    },
  ];

  const getMember = useCallback(() => {
    _fetchApi(
      `/membership/info-by-users?users=${member_id}&users=0&society_id=${society.id}`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          setMember(resp.data[0]);
        }
      },
      (e) => {
        console.log(e);
      }
    );
    // _fetchApi(
    //   `/societies/by-member?user_id=${member_id}`,
    //   (resp) => {
    //     if (resp && resp.data && resp.data.length) {
    //       setMember(resp.data[0])
    //     }
    //   },
    //   (err) => {
    //     console.log(err)
    //   },
    // )
  }, [society.id]);

  useEffect(() => {
    getMember();
    let arr = [];
    Object.keys(loanScheduleByLoan).map((i, id) => {
      loanScheduleByLoan[i].map((item, ix) => {
        if (
          item.schedule_status === "defaulted" ||
          item.schedule_status === "unpaid"
        ) {
          arr.push(item);
        }
      });
    });
    setAffectedMonths(arr);
  }, [getMember]);

  const totalAmount = selectedLoanPayment.reduce((a, b) => a + b.amount, 0);

  const handleChecked = (i) => {
    // console.log(i)
    if (
      affectedMonths.findIndex(
        (a) => a.loan_id === i.loan_id && a.schedule_date === i.schedule_date
      ) === -1
    ) {
      let list = [...affectedMonths, i];
      setAffectedMonths(list);
    } else {
      let filtered = affectedMonths.filter(
        (a) => a.loan_id === i.loan_id && a.schedule_date !== i.schedule_date
      );
      setAffectedMonths(filtered);
    }
  };

  const totalAmountOfAffectedMonths = affectedMonths.reduce(
    (a, b) => a + b.amount,
    0
  );

  return (
    <div>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4">
          Confirm Your Selection
        </CardTitle>
        <CustomTable
          fields={tableFields}
          data={selectedLoanPayment}
          total={[
            "",
            "",
            "Total",
            formatNumber(
              totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })
            ),
          ]}
        />
        {action === "recovery" ? null : (
          <div>
            <label htmlFor="receipt">Attach Evidence of Payment</label>
            <Input
              id="receipt"
              type="file"
              onChange={handleAttachment}
              multiple
            />
          </div>
        )}

        <h4>
          Select Affected Loans (Total Amount: {CURRENCY}{" "}
          {totalAmountOfAffectedMonths})
        </h4>
        {/* <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setAffectedMonths(Object.values(loanScheduleByLoan)[0])
          }}
        >
          Check All
        </a> */}
        <Checkbox
          id={"checkall"}
          checked={checkallState}
          label={checkallState ? "Uncheck All" : "Check All"}
          onChange={() => {
            if (checkallState) {
              setAffectedMonths([]);
              setCheckAllState(false);
            } else {
              setAffectedMonths(Object.values(loanScheduleByLoan)[0]);
              setCheckAllState(true);
            }
          }}
        />
        {/* {JSON.stringify(loanScheduleByLoan)} */}
        <Row>
          {Object.keys(loanScheduleByLoan).map((i, id) => {
            return loanScheduleByLoan[i].map((item, ix) => {
              let checked =
                affectedMonths.findIndex(
                  (a) =>
                    a.loan_id === item.loan_id &&
                    a.schedule_date === item.schedule_date
                ) !== -1;

              return (
                <Col key={ix} md={2}>
                  <Checkbox
                    id={ix}
                    name={i + ix}
                    checked={checked}
                    onChange={() => handleChecked(item)}
                    label={`${moment(item.schedule_date).format("MMM, YYYY")}`}
                  />

                  {/* <div>{moment(item.schedule_date).format('MMM, YYYY')}</div>
                  <div>{formatNumber(item.amount)}</div> */}
                </Col>
              );
            });
          })}
        </Row>

        {/* {JSON.stringify(Object.values(loanScheduleByLoan)[0][0])} */}

        <center>
          <CustomButton
            onClick={() => setApprovalConfirmation(true)}
            loading={loading}
          >
            Confirm Selection & Submit
          </CustomButton>
        </center>
      </Card>
      <ModalNotification
        isOpen={approvalConfirmation}
        confirm={handleSubmit}
        toggle={() => setApprovalConfirmation((p) => !p)}
      >
        <h3 className="text-center">
          Are you sure you want to make loan recovery of a total sum of{" "}
          {CURRENCY}
          {_formatNumber(totalAmount)}
        </h3>
      </ModalNotification>
    </div>
  );
}

export default LoanRecoverPost;
