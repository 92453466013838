import { CustomTable } from 'components/UI';
import DaterangeSelector from 'components/UI/DaterangeSelector'
import { formatNumber } from 'components/UI/helpers';
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { _fetchApi } from "redux/actions/api";
import React from 'react'
import { Button, Card, CardTitle, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { Value } from 'sass';
import BackButton from "components/UI/BackButton";

export default function ViewAccountReport(
    from = '',
    to = '',
    handleChange = (f) => f,
    noLabel = false,) {
//   const [range, setRange] = useState({ from: startOfYear, to: today });

//   const handleChange = ({ target: { name, value } }) =>
//     setRange((p) => ({ ...p, [name]: value }))
  const { society, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const isMemberPage = location.pathname.includes("my-pending-payments");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const _from = moment(today).subtract(6, "day").format("YYYY-MM-DD"); 
  const [range, setRange] = useState({
    from: _from,
    to: today,
   });
    const handleDateRangeChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));

  const getPendingPaymentRequests = useCallback(() => {
    setLoading(true);
    if (isMemberPage) {
      _fetchApi(
        `/contribution/member-payment-requests/${user.id}/${society.id}?req_status=pending&from=${range.from}&to=${range.to}`,
        (d) => {
          if (d && d.data && d.data.length) {
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          setLoading(false);
          console.log(e);
        }
      );
    } else {
      _fetchApi(
        `/contribution/payment-requests/${society.id}?req_status=pending&from=${range.from}&to=${range.to}`,
        (d) => {
          if (d && d.data && d.data.length) {
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          console.log(e);
          setLoading(false);
        }
      );
    }
    setLoading(false);
  }, [society.id, user.id, isMemberPage, range]);

  useEffect(() => {
    getPendingPaymentRequests();
  }, [getPendingPaymentRequests]);

const fields = [
  { title: "S/N", custom: true, component: (item, i) => <div>{i + 1}</div> },
  {
    title: "Member",
    value: "member_name",
  },
  {
    title: "Amount",
    custom: true,
    component: (item) => (
      <div className="text-right">
        {formatNumber(
          item.total_amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })
        )}
      </div>
    ),
  },
  {
    title: "DATE",
    custom: true,
    component: (item) => (
      <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
    ),
  },
  {
    title: "RECEIPT NO",
    value: "reference",
  },
  // {
  //   title: "ACTION",
  //   custom: true,
  //   component: (item) => (
  //     <Button
  //       style={{
  //         height: 40,
  //         cursor: "pointer",
  //       }}
  //       className="col-md-6"
  //     >
  //       View
  //     </Button>
  //   ),
  // },
];

  return (
      <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4">Account title</CardTitle>
        <Row className='container-fluid p-0'>
          <FormGroup className="col-md-3">
            <Label style={{ fontWeight: "bold" }}>From:</Label>
            <Input
              className="col-md-12 pl-0"
              type="date"
              value={range.from}
              name="from"
              onChange={handleDateRangeChange}
              style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-3">
            <Label style={{ fontWeight: "bold" }}>To:</Label>
            <Input
              type="date"
              value={range.to}
              name="from"
              onChange={handleDateRangeChange}
              style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label style={{ fontWeight: "bold" }}>Search:</Label>
            <Input
              type="Text"
              // value={from}
              name="from"
              placeholder="Search here"
              // onChange={handleChange}
              style={{
                borderWidth: 1,
                // borderColor: activeBusiness.primary_color,
              }}
            />
          </FormGroup>
          <Button
            style={{
              marginTop: 30,
              height: 40,
              cursor: "pointer",
            }}
            className="col-md px-1"
          >
            Export CSV
          </Button>
        </Row>
        <CustomTable fields={fields} data={list} bordered />
      </Card>
    </>
  );
}
