import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle, FormGroup } from "reactstrap";
import { getBankListFW } from "redux/actions/account";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import toast, { Toaster } from "react-hot-toast";
import { CURRENCY } from "variables";
import moment from "moment";
import { getRoleLink } from "views/helper";
import { _fetchApi } from "redux/actions/api";
export default function MessageNew() {
     const user = useSelector((state) => state.auth.user);
     const society = useSelector((state) => state.auth.society);
  const defaultForm = {
    type: "member",
    message: "",
    message_id: moment(new Date()).format("YYYYMMDDHHSS"),
  };
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

console.log(form)
  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));


  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      `/messages/new?query_type=create`,
      {
       ...form,
       member_id: user.id,
       member_name : `${user.firstname}${user.lastname}`,
       society_id: society.id,
      },
      (d) => {
        setLoading(false);
        if (d.success !== false) {
          toast.success(d.message);
          history.push(getRoleLink("/messages"));
        } else {
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">
          Start New Conversation
        </CardTitle>
        <AccountFormComponent
          setForm={setForm}
          handleChange={handleChange}
        />



            <CustomButton loading={loading} onClick={handleSubmit}>
              Submit
            </CustomButton>
        
        <Toaster position="top-center" />
      </Card>
    </>
  );
}

export const AccountFormComponent = ({
  form = {},
  setForm = (f) => f,
  handleChange = (f) => f,
}) => {
         const user = useSelector((state) => state.auth.user);
         const society = useSelector((state) => state.auth.society);
     const [list, setList] = useState([]);
      const [loading, setLoading] = useState(true);
    const getAdmins = useCallback(() => {
      _fetchApi(
        `/messages/admins?query_type=admins&member_id=${user.id}&society_id=${society.id}&admin_id=${user.id}`,
           (d) => {
          console.log("fetched")
          if (d && d.data && d.data.length) {
            console.log(d.data);
            setList(d.data);
            setLoading(false);
          }
        },
        (e) => {
          console.log(e);
          setLoading(false);
        }
      )
     
    }, [society.id, user.id]);

  useEffect(() => {
    getAdmins();
  }, []);


  const formFields = [
    {
      type: "custom",
      // col: "3",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Admin</label>
          <select
            className={`form-control`}
         
            onChange={({ target: { value } }) =>{
                let id = value.split(',')[0]
                let firstname = value.split(',')[1]
                let lastname = value.split(',')[2]
              setForm((p) => ({ ...p, admin_id: id, admin_name: `${firstname } ${lastname}` }))
            }
            }
            value={form.admin_id}
          >
            <option>--select---</option>
            {list?.map((item, index) => (
              <option key={index} value={[item.id, item.firstname, item.lastname]}>
                {`${item.firstname} ${item.lastname}`}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
    {
      label: "Messages",
      col:"8",
      value: form.description,
      name: "message",
      required: true,
    },
  ];

  return <CustomForm fields={formFields} handleChange={handleChange} />;
};
