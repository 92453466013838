import types from '../actions/types'

const initialState = {
  loanScheduleByLoan: {},
  selectedLoanPayment: [],

  paymentList: [],
  selectedPayment: [],
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.loan.SET_LOAN_SCHEDULE_BY_LOAN:
      return {
        ...state,
        loanScheduleByLoan: action.payload,
      }
    case types.loan.SET_SELECTED_LOAN_PAYMENT:
      return {
        ...state,
        selectedLoanPayment: action.payload,
      }
    case types.loan.CLEAR_LOAN_PAYMENT_SELECTION:
      return {
        ...state,
        selectedLoanPayment: [],
      }

    case types.loan.SET_PAYMENT_LIST:
      return {
        ...state,
        paymentList: action.payload,
      }
    case types.loan.SET_SELECTED_PAYMENT_LIST:
      return {
        ...state,
        selectedPayment: action.payload,
      }
    case types.loan.CLEAR_SELECTED_PAYMENT_LIST:
      return {
        ...state,
        selectedPayment: [],
      }

    default:
      return state
  }
}

export default accountReducer
