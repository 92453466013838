import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "components/UI/CustomTable";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { themeClass } from "variables";
import { useHistory } from "react-router";
import { _fetchApi } from "redux/actions/api";
import CustomCard from "components/UI/CustomCard";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { EmptyList } from "../Admin/ReportDetails";
import { formatNumber } from "components/UI/helpers";
import moment from "moment";
import { UncontrolledDropdown } from "reactstrap/lib";
import { _formatNumber } from "components/UI/helpers";
import { getRoleLink } from "views/helper";
import { today } from "utils/helpers";
import DaterangeSelector from "components/UI/DaterangeSelector";

export default function LoanRecovery() {
  const history = useHistory();
  const society = useSelector((state) => state.auth.society);

  const [pendingLoanList, setPendingLoanList] = useState([]);
  const [dateInfo, setDateInfo] = useState({ from: today, to: today });

  const handleChange = ({ target: { name, value } }) => {
    setDateInfo((p) => ({ ...p, [name]: value }));
  };
  const getPendingLoanList = useCallback(() => {
    _fetchApi(
      `/loan-application/by-status/recovery/${society.id}?from=${dateInfo.from}&to=${dateInfo.to}`,
      (d) => {
        if (d && d.data) {
          setPendingLoanList(d.data);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [society, dateInfo]);

  useEffect(() => {
    getPendingLoanList();
  }, [getPendingLoanList]);

  const fields = [
    {
      title: "Application Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    { title: "Loan No", value: "loan_id" },
    { title: "Applicant Name", value: "member_name" },
    {
      title: "Balance Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {" "}
          {_formatNumber(parseFloat(i.amount) - parseFloat(i.amount_paid))}
        </div>
      ),
    },
    {
      title: "Loan amount",
      custom: true,
      component: (i) => (
        <div className="text-right">{_formatNumber(i.amount)}</div>
      ),
    },
    {
      title: "Total Admin Charges",
      custom: true,
      component: (i) => (
        <div className="text-right">{_formatNumber(i.total_admin_charges)}</div>
      ),
    },
    // {
    //   title: 'Total Repayment',
    //   custom: true,
    //   component: (i) => (
    //     <div className="text-right">
    //       {formatNumber(i.loan_amount - i.loan_balance)}
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      custom: true,
      component: (i) => (
        <div>
          <Button
            size="sm"
            color="info"
            className="mr-1"
            onClick={() =>
              history.push(
                getRoleLink(
                  `/retrieve-loan?loan_id=${i.loan_id}&member_id=${
                    i.member_id
                  }&application_date=${moment(i.created_at).format(
                    "YYYY-MM-DD"
                  )}&grantor1_id=${i.grantor1_id}&grantor2_id=${i.grantor2_id}`
                )
              )
            }
          >
            Recover now
          </Button>

          {/* <UncontrolledDropdown>
            <DropdownToggle  caret>
              Action
            </DropdownToggle>
            <DropdownMenu container="body">
              <DropdownItem onClick={()=>{}}>
                Action 1
              </DropdownItem>
              <DropdownItem onClick={()=>{}}>
                Action 2
              </DropdownItem>
              <DropdownItem onClick={()=>{}}>
                Action 3
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          {/* <Button size='sm' color='success' className='mr-1'
            onClick={() => approveLoan(i)}
          >
            APPROVE
          </Button>
          <Button size='sm' color='danger'
            onClick={() => rejectLoan(i)}
          >
            REJECT
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <>
      <Card body>
        <CardTitle className="text-center h4 mb-2">Loan Recovery</CardTitle>
        <SearchBar placeholder="Search for a member" className="mt-1" />
        {/* {JSON.stringify(pendingLoanList)} */}

        <DaterangeSelector
          from={dateInfo.from}
          to={dateInfo.to}
          handleChange={handleChange}
        />

        <CustomTable fields={fields} data={pendingLoanList} size="sm" />
        {pendingLoanList.length ? null : (
          <EmptyList message="There are no pending disbursement, check back later." />
        )}
      </Card>
      <Toaster />
    </>
  );
}
