import React, { useState } from "react";
// import ChartistGraph from "react-chartist";
import moment from "moment";
// react-bootstrap components
import { Container, Row } from "react-bootstrap";
import MemberCustomCard from "components/UI/MemberCustomCard";
import CustomBarChart from "components/UI/CustomBarChart";
// import DaterangeSelector from "components/UI/DaterangeSelector";
import { CustomButton } from "components/UI";
import ContributionSchedule from "./app/monthlyContribution/ContributionSchedule";
import CustomLoanStatCard from "components/UI/CustomLoanStatCard";

const scrollView = (id) => {
  document.getElementById(id).scrollIntoView();
};

function Dashboard() {
  const today = moment().format("YYYY-MM-DD");
  // const startOfYear = moment().startOf('y').format('YYYY-MM-DD')
  const startOfYear = "2000-01-01";
  const [range, setRange] = useState({ from: startOfYear, to: today });

  const handleChange = ({ target: { name, value } }) =>
    setRange((p) => ({ ...p, [name]: value }));

  return (
    <>
      <Container fluid>
        {/* <DaterangeSelector
          noLabel
          from={range.from}
          to={range.to}
          handleChange={handleChange}
        /> */}
        <Row id="top-cards">
          <MemberCustomCard
            _title="Ordinary Savings"
            title="Total Contribution"
            onDefaultersClick={() => scrollView("scrol-1")}
            range={range}
            index={0}
          />

          <MemberCustomCard
            title="Special loans"
            onDefaultersClick={() => scrollView("scrol-3")}
            range={range}
            index={2}
          />
          <MemberCustomCard
            _title="Special Savings"
            title="Special contribution"
            onDefaultersClick={() => scrollView("scrol-4")}
            range={range}
            index={3}
          />
          <MemberCustomCard
            _title="Shares Contributions"
            title="Shares Contributions"
            onDefaultersClick={() => scrollView("scrol-4")}
            range={range}
            index={4}
          />
          <MemberCustomCard
            _title="Others Contributions"
            title="Others Contributions"
            onDefaultersClick={() => scrollView("scrol-4")}
            range={range}
            index={0}
          />
          <CustomLoanStatCard
            title="Loans"
            onDefaultersClick={() => scrollView("scrol-2")}
            range={range}
            index={1}
          />
        </Row>

        <ContributionSchedule />

        {/* Chart starts here */}
        <CustomBarChart
          card_id="scrol-1"
          title="Total Contribution"
          sub_title="Contribution Chart"
          items={[{ name: "Total Loan" }, { name: "Loan repayment" }]}
          onDefaultersClick={() => scrollView("top-cards")}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView("scrol-2")}
            >
              Next
            </CustomButton>
          }
          range={range}
        />
        <CustomBarChart
          card_id="scrol-2"
          title="Loan & loan repayment"
          sub_title="Loan transactions"
          items={[{ name: "Total Loan" }, { name: "Loan repayment" }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView("scrol-3")}
            >
              Next
            </CustomButton>
          }
          onDefaultersClick={() => scrollView("top-cards")}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-3"
          title="Special loan"
          sub_title="Special loan chart"
          items={[{ name: "Total Loan" }, { name: "Loan repayment" }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView("scrol-4")}
            >
              Next
            </CustomButton>
          }
          onDefaultersClick={() => scrollView("top-cards")}
          range={range}
        />
        <CustomBarChart
          card_id="scrol-4"
          title="Special contribution"
          sub_title="Special contribution chart"
          items={[{ name: "Total Loan" }, { name: "Loan repayment" }]}
          footer={
            <CustomButton
              size="sm"
              color="default"
              onClick={() => scrollView("scrol-3")}
            >
              Prev
            </CustomButton>
          }
          onDefaultersClick={() => scrollView("top-cards")}
          range={range}
        />
      </Container>
    </>
  );
}

export default Dashboard;
