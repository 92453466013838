import { Switch, Route } from "react-router";
import MessagesList from "./Messages";
import MessageNew from "./MessageNew";
import ViewMessage from "./ViewMessage";

function MessagesIndex() {
  return (
    <Switch>
      <Route exact path={"/admin/messages"} component={MessagesList} />
      <Route exact path={"/admin/messages/new"} component={MessageNew} />
      <Route exact path={"/member/messages/new"} component={MessageNew} />
      <Route exact path={"/admin/messages/view"} component={ViewMessage} />
      <Route exact path={"/member/messages/view"} component={ViewMessage} />
      <Route exact path={"/member/messages"} component={MessagesList} />
    </Switch>
  );
}

export default MessagesIndex;
