import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import CustomCard from "components/UI/CustomCard";
import { _formatNumber } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import { CURRENCY } from "variables";
import { EmptyList } from "../Admin/ReportDetails";
import { getLoanTypesList } from "./api";
import toast, { Toaster } from "react-hot-toast";
import { getRoleLink } from "views/helper";
import { _postApi } from "redux/actions/api";

function LoanTypeList() {
  const history = useHistory();
  const { society } = useSelector((o) => o.auth);
  const [loanList, setLoanList] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState({});

  const toggle = () => {
    setModal(!modal);
  };

  const getLoanList = () => {
    getLoanTypesList((d) => {
      // alert(JSON.stringify(d))
      setLoanList(d);
    });
  };

  useEffect(() => {
    getLoanList();
  }, []);

  // const handleDelete = (item) => {
  //   const confirmDelete = window.confirm(
  //     "Are you sure you want to delete this Loan?"
  //   );
  //   if (confirmDelete) {
  //     _postApi(
  //       `/new/loan-new?query_type=delete`,
  //       { id: item.id, society_id: society.id },
  //       (response) => {
  //         if (response.success) {
  //           toast.success("Loan deleted successfully!");
  //           getLoanList(); // Refresh the Loan list
  //         } else {
  //           toast.error(response.message || "Failed to delete Loan.");
  //         }
  //       },
  //       (error) => {
  //         toast.error("Error deleting Loan!");
  //       }
  //     );
  //   }
  // };

  const updateStatus = (selects, value) => {
    const data = { ...selects, status: value };
    _postApi(
      `/new/loan-new?query_type=update`,
      data,
      (response) => {
        // alert(JSON.stringify(response));
        if (response.success) {
          toast.success("Status update Successfully");
          getLoanList();
        }
      },
      (error) => {
        toast.error("Error getting Loan!");
      }
    );
  };
  const tableFields = [
    { title: "Loan Type", value: "type" },
    { title: "Title", value: "loan_title" },
    {
      title: `Maximum Amount`,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {/* {CURRENCY} */}
          {item.max_amount_type?.toLowerCase() === "percentage"
            ? item.max_amount + "% of savings"
            : CURRENCY + " " + _formatNumber(item.max_amount)}
          {/* {formatNumber(item.min_amount)} - {CURRENCY} */}
        </div>
      ),
    },
    {
      title: "Interest",
      custom: true,
      component: (item) => (
        <div className="text-center">
          {item.interest_rate}
          {item.interest_rate_type === "Percentage" ? "%" : ""}
        </div>
      ),
    },

    {
      title: "Period",
      custom: true,
      component: (item) => (
        <div>
          {item.period_value} {item.period_duration}
        </div>
      ),
    },
    { title: "Status", value: "status" },
    {
      title: `Actions`,
      custom: true,
      component: (item) => (
        <div>
          <CustomButton
            size="sm"
            onClick={() =>
              history.push(`/admin/setup-loan-types-form?id=${item.id}`)
            }
          >
            Edit
          </CustomButton>
          <CustomButton
            size="sm"
            color={item.status === "active" ? "danger" : "success"}
            onClick={() => {
              updateStatus(
                item,
                item.status === "active" ? "inactive" : "active"
              );
            }}
          >
            {item.status === "active" ? "inactive" : "active"}
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <Card body>
      <CardTitle className="h4 text-center mb-4">List of Loans</CardTitle>

      <div className="mb-2">
        <CustomButton
          onClick={() => history.push(getRoleLink("/setup-loan-types-form"))}
        >
          Setup New Loan
        </CustomButton>
      </div>

      <CustomTable data={loanList} fields={tableFields} />
      {loanList.length ? null : (
        <EmptyList message="You are yet to setup your loans, click the button above to get started." />
      )}
    </Card>
  );
}

export default LoanTypeList;
