import React, { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Button, Badge } from "reactstrap";
import { CustomCotrolCard } from "components/UI/CustomCard";
import { useSelector } from "react-redux";
import { _updateApi } from "redux/actions/api";
import { CustomButton } from "components/UI";
import { MdUpdate } from "react-icons/md";

export default function CooperateAccountDetails() {
  const location = "";
  const isMember = false;
  const isAdmin = false;
  const isAdminUser = false;

  const { societies } = useSelector((state) => state.auth);
  const [loading, toggle] = useState(false);
  const [bankInfo, setBankInfo] = useState({
    acct_name: "",
    acct_no: 0,
    bank_name: "",
  });

  useEffect(() => {
    setBankInfo((p) => ({
      ...p,
      acct_no: societies[0]?.acc_no,
      acct_name: societies[0]?.acc_name,
      bank_name: societies[0]?.bank_name,
    }));
  }, [societies]);

  const handleChange = (e) => {
    setContribution(e.target.value);
  };
  const handleSubmit = () => {
    const userId = user.id;
    toggle(true);
    _updateApi(
      `/update-bank-details/${userId}`,
      bankInfo,
      () => {
        toggle(false);
        toast.success("Bank details updated");
      },
      (err) => {
        toggle(false);
        toast.error("An error occured");
        console.log("caught error", err);
      }
    );
    // dispatch(submitBasicInfo(user, () => toggle(false)));
  };

  return (
    <>
      <CustomCotrolCard
        className="p-3 mb-5 mt-2"
        body
        title="Society Account Details"
      >
        <Card className="p-2 mb-0">
          <CardBody>
            <FormGroup row>
              <div className="col-md-12">
                <span className="">Account Name </span>
                <input
                  onChange={(e) => handleChange("acct_name", e.target.value)}
                  className="form-control"
                  type="text"
                  value={bankInfo?.acct_name}
                  disabled={isMember}
                />
              </div>
              <div className="col-md-12">
                <span className="">Account Number </span>
                <input
                  onChange={(e) => handleChange("acct_no", e.target.value)}
                  className="form-control"
                  type="number"
                  value={bankInfo?.acct_no}
                  disabled={isMember}
                />
              </div>
              <div className="col-md-12">
                <span className="">Bank Name</span>
                <input
                  onChange={(e) => handleChange("bank_name", e.target.value)}
                  className="form-control"
                  type="text"
                  value={bankInfo?.bank_name}
                  disabled={isMember}
                />
              </div>
            </FormGroup>
            {false ? (
              <center>
                <CustomButton
                  color="primary"
                  className="m-2 "
                  loading={loading}
                  onClick={handleSubmit}
                >
                  <MdUpdate size={20} className="mr-1" />
                  Update Bank Details
                </CustomButton>
              </center>
            ) : (
              ""
            )}
          </CardBody>
        </Card>
      </CustomCotrolCard>
    </>
  );
}
