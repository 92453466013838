import Membershipaplication from "views/app/membership/Membership_Application";
import PostingTable from "views/app/postingSaving/PostingTable";
import LoanDisbursement from "views/app/loanApplication/LoanDisbursement";
import ItemListTable from "views/app/item/ItemListTable";
import LoanApplication from "views/app/loanApplication/PendingLoans";
import MemberDetails from "views/app/ViewMemberDetails/MemberDetails";
import Withdrawal from "views/app/withdraw/Withdrawal";
import ItemIssueForm from "views/app/itemIssue/ItemIssueForm";
import ContributionPosting from "views/app/monthlyContribution/ContributionPosting";
// Extras
import Membership from "views/app/membership/Membership";
import Share from "views/app/membership/Total_Share";
import Loan from "views/app/membership/Pending_loan";
import Component from "views/app/membership/Component";
import PostingForm from "views/app/postingSaving/PostingForm";
// import PostingTable from "views/app/postingSaving/PostingTable";
import PostSpecialTable from "views/app/postSpecial/PostSpecialTable";
import PostLoanForm from "views/app/postLoanRepayment/PostLoanForm";
import PostLoanTable from "views/app/postLoanRepayment/PostLoanTable";
// import LoanApplication from "views/app/loanApplication/PendingLoans";
import ViewPost from "views/app/postingSaving/ViewPost";
// import ItemListTable from "views/app/item/ItemListTable";
import ItemList from "views/app/item/ItemList";
// import ItemIssueForm from "views/app/itemIssue/ItemIssueForm";
import ItemIssueTable from "views/app/itemIssue/ItemIssueTable";
// import MemberDetails from "views/app/ViewMemberDetails/MemberDetails";
// import Withdrawal from "views/app/withdraw/Withdrawal";
import WithdrawalTable from "views/app/withdraw/WithdrawalTable";
// import TargetSvingform from "views/app/targetSving/TargetSvingform";
// import TargetSvingTable from "views/app/targetSving/TargetSavingTable";
import PostSpecialForm from "views/app/postSpecial/postSpecialForm";
import AdminRoute from "views/app/AdminRoute";
// import ContributionPosting from "views/app/monthlyContribution/ContributionPosting";
import ContributionTable from "views/app/monthlyContribution/ContributionTable";
// import LoanDisbursement from "views/app/loanApplication/LoanDisbursement";
import LoanDisbursementForm from "views/app/loanApplication/LoanDisbursementForm";
import LoanApproval from "views/app/loanApplication/LoanApproval";
import LoanRepayment from "views/app/loanApplication/LoanRepayment";
import LoanRepaymentPosting from "views/app/loanApplication/LoanRepaymentPosting";
import Special from "views/app/membership/Special_saving";

import View from "views/app/membership/View_members";
import Total from "views/app/membership/Total_saving";
import Dashboard from "views/Dashboard";
import ReportDetails from "views/app/Admin/ReportDetails";
import AccountSetup from "views/app/account-setup";
// import NewAccountForm from "views/app/account-setup/NewAccountForm";
import NewPayments from "views/app/monthlyContribution/NewPayments";
import PendingPayments from "views/app/monthlyContribution/PendingPayments";
import Settings from "views/app/settings";
import ViewPaymentDetails from "views/app/monthlyContribution/ViewPaymentDetails";
import LoanSetup from "views/app/loan-setup/LoanSetup";
import LoanTypeList from "views/app/loan-setup/LoanTypeList";
import LoanSetupForm from "views/app/loan-setup/LoanSetupForm";
import MyLoans from "views/app/my-loans";
import LoanDetails from "views/app/my-loans/LoanDetails";
import NewLoanApplication from "views/app/my-loans/NewLoanApplication";
import RepaymentSchedule from "views/app/loanApplication/RepaymentSchedule";
import TransactionHistory from "views/app/transaction-history";
import LoanRepaymentConfirmation from "views/app/loanApplication/LoanRepaymentConfirmation";
import PaymentConfirmationPage from "views/app/monthlyContribution/PaymentConfirmationPage";
import PendingMembership from "views/app/membership/PendingMembership";
import MyWithdrawals from "views/app/withdraw/MyWithdrawals";
import Profile from "views/examples/Profile";
import LoanRecovery from "views/app/loanApplication/LoanRecovery";
import RecoverLoan from "views/app/loanApplication/RecoverLoan";
import RetrieveLoan from "views/app/loanApplication/RetrieveLoan";
import LoanRecoverPost from "views/app/loanApplication/LoanRecoverPost";

import GrantorList from "views/app/grantor";
import { MdDashboard, MdSavings } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiHandCoinFill, RiAdminFill } from "react-icons/ri";
import { GiCash } from "react-icons/gi";
import DrugCount from "views/app/inventory/DrugCount";
import AddnewDrug from "views/app/inventory/drug/AddnewDrug";
import ItemRequest from "views/app/inventory/item-request/ItemRequest";
import Request from "views/app/inventory/item-request/Request"; 
import ViewRequest from "views/app/inventory/item-request/ViewRequest";
import ExpensesIndex from "views/app/Admin/ExpensesIndex";
import CreateExpenses from "views/app/Admin/CreateExpenses";
import AccountChart from "views/app/account-chat";
import Vote from "views/app/voting";
import CandidateForm from "views/app/voting/CandidateForm";
import SummaryTransactionHistory from "views/app/transaction-history/SummaryTransac";
import QuickViewTrans from "views/app/transaction-history/QuickViewTrans";
import LoanSummary from "views/app/loanApplication/LoanSummary";
import BankDetails from "views/app/bank-details";
import MessagesIndex from "views/app/messages";
import Announcement from "views/app/announcement/Annoncenmt"
// import { RiAdminFill } from 'react-icons/gr'

const SideBarData = [
  {
    subItem: [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: (
          <MdDashboard
            style={{ fontSize: 19, marginTop: -3, marginRight: -20 }}
          />
        ),
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/member-dashboard",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/report-details",
        // icon: "nc-icon nc-alien-33",
        component: ReportDetails,
        layout: "/admin",
      },
      {
        path: "/user-profile",
        component: Profile,
        layout: "/admin",
      },
    ],
  },

  {
    title: "MEMBERSHIP",
    icon: (
      <HiUsers
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/membership",
        name: "Members",
        component: Membershipaplication,
        layout: "/admin",
      },
      {
        path: "/pending-membership",
        name: "Pending Membership",
        component: PendingMembership,
        layout: "/admin",
      },

      // {
      //   path: "/setup-account/new",
      //   // icon: "nc-icon nc-alien-33",
      //   component: NewAccountForm,
      //   layout: "/admin",
      // },
      // {
      //   path: '/members-saving',
      //   name: 'Contribution Saving',
      //   component: PostingTable,
      //   layout: '/admin',
      // }, // {
      //   path: '/members-saving',
      //   name: 'Contribution Saving',
      //   component: PostingTable,
      //   layout: '/admin',
      // },
      // {
      //   path: '/members-shares',
      //   name: 'Post Special Saving',
      //   component: PostingTable,
      //   layout: '/admin',
      // },

      // {
      //   path: '/item/list',
      //   name: 'Item List',
      //   component: ItemListTable,
      //   layout: '/admin',
      // },

      // {
      //   path: '/view-member-details',
      //   name: 'Membership groups',
      //   component: MemberDetails,
      //   layout: '/admin',
      // },
      // {
      //   path: '/withdraw',
      //   name: 'Withdraw',
      //   component: Withdrawal,
      //   layout: '/admin',
      // },
      // {
      //   path: '/contribution',
      //   name: 'Contribution',
      //   component: ContributionPosting,
      //   layout: '/admin',
      // },

      // {
      //   path: '/target-saving',
      //   name: 'Target Savings Form',
      //   layout: '/admin',
      // },
      // {
      //   path: '/transaction-history',
      //   name: 'My Transactions',
      //   component: TransactionHistory,
      //   layout: '/admin',
      // },
      // {
      //   path: '/item/issue',
      //   name: 'Item Issue',
      //   component: ItemIssueForm,
      //   layout: '/admin',
      // },
    ],
  },
  {
    title: "SAVINGS",
    icon: (
      <MdSavings
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/setup-account",
        name: "Setup Savings Accounts",
        // icon: "nc-icon nc-alien-33",
        component: AccountSetup,
        layout: "/admin",
      },
      // {
      //   path: '/member-payments',
      //   name: 'Savings',
      //   component: NewPayments,
      //   layout: '/admin',
      // },
      {
        path: "/member-payments",
        name: "Savings",
        component: PaymentConfirmationPage,
        layout: "/admin",
      },
      {
        path: "/member-payment-confirmation",
        component: PaymentConfirmationPage,
        layout: "/admin",
      },
    ],
  },

  {
    title: "INVENTORY",
    icon: (
      <MdSavings
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/item-list",
        name: "Item List",
        // icon: "nc-icon nc-alien-33",
        component: DrugCount,
        layout: "/admin",
      },
      // {
      //   path: '/member-payments',
      //   name: 'Savings',
      //   component: NewPayments,
      //   layout: '/admin',
      // },
      {
        path: "/add-new-purchase",
        // name: "Add New Item",
        component: AddnewDrug,
        layout: "/admin",
      },
      {
        path: "/new-request",
        name: "New Request",
        component: Request,
        layout: "/admin",
      },
      {
        path: "/view-request",
        component: ViewRequest,
        layout: "/admin",
      },
      {
        path: "/new-requests/new",
        component: ItemRequest,
        layout: "/admin",
      },
    ],
  },

  {
    title: "LOANS",
    icon: (
      <RiHandCoinFill
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/setup-loan-types",
        name: "Setup Loans",
        // icon: "nc-icon nc-alien-33",
        component: LoanTypeList,
        layout: "/admin",
      },
      {
        path: "/loan/grantor-request",
        name: "Guarantor Request",
        component: GrantorList,
        layout: "/admin",
      },
      {
        path: "/setup-loan-types-form",
        // icon: "nc-icon nc-alien-33",
        component: LoanSetupForm,
        layout: "/admin",
      },
      {
        path: "/loan-application",
        // icon: "nc-icon nc-alien-33",
        component: LoanSetupForm,
        layout: "/admin",
      },
      {
        path: "/loan/application",
        name: "Loan Applications",
        component: LoanApplication,
        layout: "/admin",
      },

      {
        path: "/loan/summary",
        name: "Loan Summary",
        component: LoanSummary,
        layout: "/admin",
      },

      {
        path: "/loan_approval",
        component: LoanApproval,
        layout: "/admin",
      },
      {
        path: "/loan-disbursement",
        name: "Loan Disbursement",
        // name: 'Loan Disbursement (Treasury)',
        layout: "/admin",
        component: LoanDisbursement,
      },
      {
        path: "/loan-recovery",
        name: "Loan Recovery",
        // name: 'Loan Disbursement (Treasury)',
        layout: "/admin",
        component: LoanRecovery,
      },
      {
        path: "/loan_disbursement_form",
        component: LoanDisbursementForm,
        layout: "/admin",
      },
      {
        path: "/loan-repayment",
        name: "Loan Repayment",
        component: LoanRepayment,
        layout: "/admin",
      },
      {
        path: "/loan-repayment-confirm",
        component: LoanRepaymentConfirmation,
        layout: "/admin",
      },
      {
        path: "/loan-retrieval",
        component: LoanRecoverPost,
        layout: "/admin",
      },
      {
        path: "/loan/my-loans",
        name: "My Loans",
        component: MyLoans,
        layout: "/admin",
      },
      {
        path: "/loan/my-loan-details",
        component: LoanDetails,
        layout: "/admin",
      },
      {
        path: "/loan/my-loan-schedule",
        component: RepaymentSchedule,
        layout: "/admin",
      },
      {
        path: "/loan/new-loan-application",
        component: NewLoanApplication,
        layout: "/admin",
      },
      {
        path: "/recover-loan",
        // name: 'Loan Disbursement (Treasury)',
        layout: "/admin",
        component: RecoverLoan,
      },
      {
        path: "/retrieve-loan",
        // name: 'Loan Disbursement (Treasury)',
        layout: "/admin",
        component: RetrieveLoan,
      },
    ],
  },
  {
    title: "WITHDRAWALS",
    icon: (
      <GiCash
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/withdraw",
        name: "My Withdrawals",
        component: MyWithdrawals,
        layout: "/admin",
      },
      {
        path: "/new-withdraw",
        component: Withdrawal,
        layout: "/admin",
      },
    ],
  },

  {
    title: "ADMIN",
    icon: (
      <RiAdminFill
        style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
      />
    ),
    subItem: [
      {
        path: "/request",
        name: "Default contributor request",
        component: Request,
        layout: "/admin",
      },
      {
        path: "/pending-member-payments",
        name: "Pending Payments",
        component: PendingPayments,
        layout: "/admin",
      },
      {
        path: "/messages",
        name: "Messages",
        component: MessagesIndex,
        layout: "/admin",
      },
      {
        path: "/pending-member-payments-details",
        component: ViewPaymentDetails,
        layout: "/admin",
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        layout: "/admin",
      },
      {
        path: "/bank-accounts",
        name: "Bank Account Settings",
        component: BankDetails,
        layout: "/admin",
      },
      {
        path: "/transaction-history",
        name: "Transactions List",
        component: TransactionHistory,
        layout: "/admin",
      },
      {
        path: "/total-summary",
        name: "Total Summaries",
        component: SummaryTransactionHistory,
        layout: "/admin",
      },
      {
        path: "/quick-summary",
        name: "Quick Summaries",
        component: QuickViewTrans,
        layout: "/admin",
      },

      {
        path: "/expenses",
        name: "Expenses",
        component: ExpensesIndex,
        layout: "/admin",
      },
      {
        path: "/new-expenses",
        component: CreateExpenses,
        layout: "/admin",
      },
      // {
      //   path: "/account-chat",
      //   name: "Account Chat",
      //   component: AccountChart,
      //   layout: "/admin",
      // },
      {
        path: "/voting",
        name: "Voting",
        component: Vote,
        layout: "/admin",
      },
      {
        path: "/votings/form",
        component: CandidateForm,
        layout: "/admin",
      },

      {
        path: "/announcement",
        name: "Announcement",
        component: Announcement,
        layout: "/admin",
      },
    ],
  },

  // {
  //   title: "Default Contribution",
  //   icon: (
  //     <HiUsers
  //       style={{ fontSize: 19, marginTop: -3, marginRight: 8, marginLeft: 10 }}
  //     />
  //   ),
  //   subItem: [
  //     {
  //       path: "/membership",
  //       name: "Members",
  //       component: Membershipaplication,
  //       layout: "/admin",
  //     },
  //   ],
  //   },

  // {
  //   title: 'Admin',
  //   subItem: [
  //     {
  //       path: '/admin-admin',
  //       name: 'dashboard Dashboard',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/members-savin',
  //       name: 'Saving Report',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership-new',
  //       component: Membership,
  //       name: 'Create Member',
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/specialsaving',
  //       component: Special,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/total_shares',
  //       component: Share,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/pending_loan',
  //       component: Loan,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/view/:id',
  //       component: View,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/totalsaving',
  //       component: Total,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/membership/componenet',
  //       component: Component,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/members-saving',
  //       component: PostingTable,
  //       layout: '/admin',
  //     },

  //     {
  //       path: '/members-shares',
  //       component: PostingTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/loan-disbursement',
  //       component: LoanDisbursement,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/posting-new:type?',
  //       component: PostingForm,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/members/:type?',
  //       component: PostSpecialTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/post/special-new',
  //       component: PostSpecialForm,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/post/repayment',
  //       component: PostLoanTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/post/repayment-new',
  //       component: PostLoanForm,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/loan/application',
  //       component: LoanApplication,
  //       layout: '/admin',
  //     },

  //     {
  //       path: '/loan-repayment',
  //       component: LoanRepayment,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/loan_repayment_posting',
  //       component: LoanRepaymentPosting,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/view-post/:member_code?',
  //       component: ViewPost,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/item/list',
  //       component: ItemListTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/item/list/new/:id?',
  //       component: ItemList,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/item/issue-new',
  //       component: ItemIssueForm,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/item/issue',
  //       component: ItemIssueTable,
  //       layout: '/admin',
  //     },

  //     {
  //       path: '/withdraw/new',
  //       component: Withdrawal,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/withdraw',
  //       component: WithdrawalTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/contribution-new',
  //       component: ContributionPosting,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/contribution',
  //       component: ContributionTable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/targ',
  //       component: ContributionTable,
  //       layout: '/admin',
  //     },
  //   ],
  // },
];
export default SideBarData;
