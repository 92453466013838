import React, { useState } from 'react';
import { Card, CardBody, FormGroup, Input, Button, Badge } from 'reactstrap';
import { CustomCotrolCard } from 'components/UI/CustomCard';

const DefaultContribution = () => {
  const [contribution, setContribution] = useState(1000);
  const [status, setStatus] = useState('Pending');

  const handleChange = (e) => {
    setContribution(e.target.value);
  };

  const handleCancel = () => {
    setContribution(1000);
    setStatus('Pending');
  };

  const handleRequest = () => {
    setStatus('Approved');
  };

  return (
    <CustomCotrolCard className="p-3 mb-5 mt-2" body title="Default Contribution Request">
      <Card className="p-2 mb-2">
        <CardBody>
          <FormGroup>
            <div className="w-100">
              <span>Default Contribution</span>
              <div className="d-flex align-items-center mt-2">
                <Input
                  type="number"
                  value={contribution}
                  onChange={handleChange}
                  className="form-control flex-grow-1"
                />
                <Badge color="warning" className="ml-2">{status}</Badge>
              </div>
            </div>
          </FormGroup>
          <center>
            <Button color="secondary" className="m-2" onClick={handleCancel}>Cancel</Button>
            <Button color="primary" className="m-2" onClick={handleRequest}>Request</Button>
          </center>
        </CardBody>
      </Card>
    </CustomCotrolCard>
  );
};

export default DefaultContribution;
