import { themeClass } from "variables";
import { Card, CardBody } from "reactstrap";
import { CustomTable } from "components/UI";
import { SearchBar } from "components/UI";
import { CustomButton } from "components/UI";
import { useHistory } from "react-router";
import { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { _fetchApi } from "redux/actions/api";
import CustomModal from "components/UI/CustomModal";
import { useSelector } from "react-redux";
import NewAccountForm, { AccountFormComponent } from "./NewAccountForm";
import { _updateApi } from "redux/actions/api";
import { EmptyList } from "../Admin/ReportDetails";
import toast, { Toaster } from "react-hot-toast";
import Loading from "components/UI/Loading";
import { enableStatus } from "redux/actions/account";
// import { disabledStatus } from "redux/actions/account";
// import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { getRoleLink } from "views/helper";
import { useLocation } from "react-router-dom";
import { _postApi } from "redux/actions/api";

function BankDetailsList() {
  const history = useHistory();
  const location = useLocation();
  const [accountList, setAccountList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const society = useSelector((state) => state.auth.society);
  const isAdmin = location?.pathname?.includes("/admin/");
  const [fetching, setFetching] = useState(false);

  const toggleEditModal = () => setModalIsOpen((p) => !p);

  const handleChange = ({ target: { name, value } }) =>
    setAccountInfo((p) => ({ ...p, [name]: value }));

  const getAccountList = useCallback(() => {
    setFetching(true);
    _fetchApi(
      `/bank_details?query_type=select&society_id=${society.id}`,
      (resp) => {
        setFetching(false);
        if (resp && resp.success) {
          setAccountList(resp.data);
        } else {
          toast.error(resp.message);
        }
      },
      (e) => {
        setFetching(false);
        console.log(e);
        toast.error(e.message);
        // alert(resp.message)
      }
    );
  }, [society.id]);

  useEffect(() => getAccountList(), []);
  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, id) => <div>{id + 1}</div>,
    },
    { title: "Description", value: "description" },
    { title: "Account Name", value: "account_name" },

    {
      title: "Account Number",
      custom: true,
      component: (item) => <div>{item.account_no}</div>,
      value: "account_no",
    },
    { title: "Bank Name", value: "bank_name" },

    {
      title: "Action",
      custom: true,
      component: (item) =>
        isAdmin ? (
          <div className="text-center">
            <CustomButton
              size="sm"
              onClick={() => {
                setModalIsOpen(true);
                setAccountInfo(item);
              }}
            >
              Edit
            </CustomButton>
          </div>
        ) : null,
    },
  ];
  return (
    <div className={themeClass}>
      <Card color="white">
        <CardBody>
          {isAdmin ? (
            <CustomButton
              className="px-6 mb-3"
              onClick={() => history.push(getRoleLink("/bank-accounts/new"))}
            >
              Create New Account
            </CustomButton>
          ) : null}

          <SearchBar placeholder="Search for an account" />
          {fetching && <Loading size="sm" />}
          <CustomTable fields={fields} data={accountList} size="sm" />
          {accountList.length ? null : (
            <EmptyList message="No account has been created yet, click on the button above to get started." />
          )}
        </CardBody>
      </Card>

      <CustomModal
        header="Edit Account Information"
        isOpen={modalIsOpen}
        toggle={toggleEditModal}
      >
        {/* {JSON.stringify({accountInfo})} */}

        <EditModalContent
          form={accountInfo}
          setForm={setAccountInfo}
          handleChange={handleChange}
          toggleEditModal={toggleEditModal}
          society={society}
          getAccountList={getAccountList}
        />
      </CustomModal>
      <Toaster position="top-center" />
    </div>
  );
}

function EditModalContent({
  form = {},
  handleChange = (f) => f,
  toggleEditModal = (f) => f,
  society = {},
  getAccountList = (f) => f,
  setForm = (f) => f,
}) {
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setLoading(true);
    _postApi(
      `/bank_details/new?query_type=update`,
      form,
      (d) => {
        setLoading(false);
        if (d && d.success) {
          toggleEditModal();
          getAccountList();
          toast.success(d.message);
        } else {
          // alert(d.message)
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <>
      {/* {JSON.stringify(form)} */}
      <AccountFormComponent
        form={form}
        handleChange={handleChange}
        setForm={setForm}
      />
      <center>
        <CustomButton loading={loading} onClick={handleUpdate}>
          Update
        </CustomButton>
      </center>
    </>
  );
}

export default BankDetailsList;
