import { CustomButton } from "components/UI";
import { CustomCotrolCard } from "components/UI/CustomCard";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { MdUpdate } from "react-icons/md";
import { useSelector } from "react-redux";
import { Card, CardTitle, FormGroup } from "reactstrap";
import { _updateApi } from "redux/actions/api";

export default function BankDetails() {
  const user = useSelector((state) => state.auth.user);
  const [bankInfo, setBankInfo] = useState({
    acct_name: "",
    acct_no: "",
    bank_name: "",
  });
  const [loading, toggle] = useState(false);

  const handleChange = (key, value) => {
    setBankInfo((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setBankInfo(user);
  }, [user]);

  const handleSubmit = () => {
    const userId = user.id;
    toggle(true);
    _updateApi(
      `/update-bank-details/${userId}`,
      bankInfo,
      () => {
        toggle(false);
        toast.success("Bank details updated");
      },
      (err) => {
        toggle(false);
        toast.error("An error occured");
        console.log("caught error", err);
      }
    );
    // dispatch(submitBasicInfo(user, () => toggle(false)));
  };

  return (
    <>
      {/* {JSON.stringify(bankInfo)} */}
      <CustomCotrolCard className="p-3 mb-5 mt-2" body title="Bank Details">
        <div className="p-3">
          <FormGroup row>
            <div className="col-md-12">
              <span className="">Account Name </span>
              <input
                onChange={(e) => handleChange("acct_name", e.target.value)}
                className="form-control"
                type="text"
                value={bankInfo.acct_name}
              />
            </div>
            <div className="col-md-12">
              <span className="">Account Number </span>
              <input
                onChange={(e) => handleChange("acct_no", e.target.value)}
                className="form-control"
                type="number"
                value={bankInfo.acct_no}
              />
            </div>
            <div className="col-md-12">
              <span className="">Bank Name</span>
              <input
                onChange={(e) => handleChange("bank_name", e.target.value)}
                className="form-control"
                type="text"
                value={bankInfo.bank_name}
              />
            </div>
          </FormGroup>
          <center>
            <CustomButton
              color="primary"
              className="m-2 mb-5"
              loading={loading}
              onClick={handleSubmit}
            >
              <MdUpdate size={20} className="mr-1" />
              Update Bank Details
            </CustomButton>
          </center>
        </div>
      </CustomCotrolCard>
    </>
  );
}
