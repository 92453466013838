import { CustomTable } from 'components/UI'
import BackButton from 'components/UI/BackButton'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardTitle, Input } from 'reactstrap'
import moment from 'moment'
import { formatNumber } from 'components/UI/helpers'
import { CustomButton } from 'components/UI'
import { CURRENCY } from 'variables'
import ModalNotification from 'components/UI/ModalNotification'
import { newLoanRepayment } from './api'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import { getRoleLink } from 'views/helper'
import types from 'redux/actions/types'
import { _formatNumber } from 'components/UI/helpers'
import { api_url } from 'redux/actions'

function LoanRepaymentConfirmation() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, selectedLoanPayment },
  } = useSelector((s) => s)
  const [approvalConfirmation, setApprovalConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [attachment, setAttachment] = useState(null)

  const handleAttachment = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files
      setAttachment(Object.values(fileObj))
      //   console.log(Object.values(fileObj))
    }
  }

  const handleSubmit = () => {
    if (selectedLoanPayment.length) {
      setLoading(false)
      if (attachment) {
        let total_amount = selectedLoanPayment.reduce(
          (a, b) => a + parseFloat(b.amount),
          0,
        )
        let formData = new FormData()
        formData.append('payment_items', JSON.stringify(selectedLoanPayment))
        formData.append('member_name', user.firstname + ' ' + user.lastname)
        formData.append('society_id', society.id)
        formData.append('member_id', user.id)
        formData.append('total_amount', total_amount)
        if (attachment && attachment.length) {
          attachment.forEach((file) => formData.append('attachments', file))
        }

        fetch(`${api_url}/loan-repayment/new-payment-request`, {
          method: 'POST',
          body: formData,
        })
          .then((raw) => raw.json())
          .then((resp) => {
            if (resp.success) {
              // navigate back to dashboard
              toast.success(resp.message)
              history.push(getRoleLink('/dashboard'))
              dispatch({ type: types.loan.CLEAR_LOAN_PAYMENT_SELECTION })
            } else {
              toast.error(resp.message)
            }
          })
          .catch((e) => {
            toast.error(e.message)
          })
      } else {
        let obj = {
          payment_items: selectedLoanPayment,
          member_id: user.id,
          member_name: `${user.firstname} ${user.lastname}`,
          society_id: society.id,
          reference: Date.now(),
        }

        newLoanRepayment(
          obj,
          (resp) => {
            setLoading(false)
            toast.success(resp.message)
            history.push(getRoleLink('/dashboard'))
            dispatch({ type: types.loan.CLEAR_LOAN_PAYMENT_SELECTION })
          },
          (e) => {
            setLoading(false)
            toast.error(resp.message)
          },
        )
      }
    } else {
      toast.error('You have not made any selection yet!')
    }
  }

  const tableFields = [
    {
      title: 'Loan ID',
      custom: true,
      component: (i) => <div>{i.loan_id}</div>,
    },
    {
      title: 'Date',
      custom: true,
      component: (i) => (
        <div>{moment(i.schedule_date).format('MMM, YYYY')}</div>
      ),
    },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.amount)}</div>
      ),
    },
  ]

  const totalAmount = selectedLoanPayment.reduce((a, b) => a + b.amount, 0)

  return (
    <div>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4">
          Confirm Your Selection 
        </CardTitle>

        <CustomTable
          fields={tableFields}
          data={selectedLoanPayment}
          total={['', 'Total', formatNumber(totalAmount)]}
        />
        {/* <div>{JSON.stringify(selectedLoanPayment)}</div> */}

        <div>
          <label htmlFor="receipt">Attach Evidence of Payment</label>
          <Input
            id="receipt"
            type="file"
            onChange={handleAttachment}
            multiple
          />
        </div>

        <center>
          <CustomButton
            onClick={() => setApprovalConfirmation(true)}
            loading={loading}
          >
            Confirm Selection & Pay
          </CustomButton>
        </center>
      </Card>

      <ModalNotification
        isOpen={approvalConfirmation}
        confirm={handleSubmit}
        toggle={() => setApprovalConfirmation((p) => !p)}
      >
        <h3 className="text-center">
          Are you sure you want to make payment of a total sum of {CURRENCY}
          {_formatNumber(totalAmount)}
        </h3>
      </ModalNotification>
    </div>
  )
}

export default LoanRepaymentConfirmation
