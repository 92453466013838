import React from "react";
import { Button } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FaFileExport } from "react-icons/fa";
import { CustomButton } from "components/UI";

export const ExportCSV = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (dataToExport, fileName) => {
    // Create a copy of the data and remove the unwanted columns
    const dataCopy = dataToExport.map((item) => {
      // Create a copy of each item to avoid mutating the original data
      const newItem = { ...item };
      delete newItem?.society_id;
      delete newItem?.member_id;
      delete newItem?.status;
      delete newItem?.id;
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(dataCopy);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <CustomButton
      className="bg-warning"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      <FaFileExport /> Export in excel
    </CustomButton>
  );
};
