import { CustomCotrolCard } from "components/UI/CustomCard";
import { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import { CustomButton } from "components/UI";
import { MdUpdate } from "react-icons/md";
import { useSelector } from "react-redux";
import { _updateApi } from "redux/actions/api";
import toast from "react-hot-toast";
const EmployerInfo = () => {
  const profile = useSelector((state) => state.auth.user);
  const [form, setForm] = useState({ });
  const [loading, setLoading] = useState(false);
  const handleChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setForm(profile);
  }, [profile]);
  const handleSubmit = () => {
    const userId = form.id;
    setLoading(true);
    _updateApi(
      `/users/${userId}`,
      form,
      () => {
        setLoading(false);
        toast.success("Profile Updated");
      },
      (err) => {
        setLoading(false);
        toast.error("An error occured");
        console.log("caught error", err);
      }
    );
  };
  return (
    <CustomCotrolCard title="Employer Details">
      <div className="pl-3 pr-3 pb-1 pt-1">
        <FormGroup row>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Registration number </span>
            <input
              onChange={(e) => handleChange("registration_no", e.target.value)}
              className="form-control"
              type="text"
              value={form.registration_no}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Employer name</span>
            <input
              onChange={(e) => handleChange("emp_name", e.target.value)}
              className="form-control"
              type="text"
              value={form.emp_name}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Employer address</span>
            <input
              onChange={(e) => handleChange("emp_address", e.target.value)}
              className="form-control"
              type="text"
              value={form.emp_address}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">State of practice</span>
            <input
              onChange={(e) =>
                handleChange("state_of_practice", e.target.value)
              }
              className="form-control"
              type="text"
              value={form.state_of_practice}
            />
          </div>
        </FormGroup>
        <FormGroup row>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Year of practice</span>
            <input
              onChange={(e) => handleChange("year_practice", e.target.value)}
              className="form-control"
              type="number"
              value={form.year_practice}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Technical group</span>
            <input
              onChange={(e) => handleChange("technical_group", e.target.value)}
              className="form-control"
              type="text"
              value={form.technical_group}
            />
          </div>
          <div className="col-md-12 col-sm-12 col-xl-12">
            <span className="">Interest group</span>
            <input
              onChange={(e) => handleChange("interest_group", e.target.value)}
              className="form-control"
              type="text"
              value={form.interest_group}
            />
          </div>
        </FormGroup>
        <center>
          <CustomButton
            color="primary"
            className="m-2 mb-5"
            loading={loading}
              onClick={handleSubmit}
          >
            <MdUpdate size={20} className="mr-1" />
            Update Details
          </CustomButton>
        </center>
      </div>
    </CustomCotrolCard>
  );
};

export default EmployerInfo;
