import useQuery from 'helpers/useQuery'
import React, {  createRef, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Card, CardBody, Col, Form, Row, Spinner } from 'reactstrap'
import cover from '../../../images/hands2.jpg'
import '../../../assets/css/auths/style.css'

export default function EnterCode() {
    const history = useHistory()
    let refs = useRef([]);
    refs.current = [0,0,0,0,0,0].map(
        (ref, index) =>   refs.current[index] = createRef()
        )
        
    const inputLists = [
        {
            name: 'inputValue1',
            value:"",
            // ref:null,

        },
        {
            name: 'inputValue2',
            value:"",
            //  ref:null
        },
        {
            name: 'inputValue3',
            value:"",
            // ref:null
        },
        {
            name: 'inputValue4',
            value:"",
            // ref:null
        },
        {
            name: 'inputValue5',
            value:"",
            // ref:null
        },
        {
            name: 'inputValue6',
            value:"",
            // ref:null
        },
    ]
    const [inputValue, setInputValue] = useState(inputLists)
    const [loading, setLoading] = useState(false)
    const code = inputValue.map(item=>item.value)?.join('')


    const onChange =(index,value)=>{
        // const inputRef =useRef(null);
        console.log({index,value})
        let arr =[];
        inputValue.forEach((item,ind)=>{
            if(index===ind){
                arr.push({...item,value});
                if(index===5){
                    if(value===''){
                        if(index==0){
                            refs.current[index].current.focus()
                        }else{
                            refs.current[index-1].current.focus()
                        }
                        
                    }else{
                        refs.current[index].current.focus()
                    }
                }else{
                    if(value===''){
                        if(index==0){
                            refs.current[index].current.focus()
                        }else{
                            refs.current[index-1].current.focus()
                        }
                    }else{
                        refs.current[index+1].current.focus()
                    }
                }
                
            }else{
                arr.push(item);
            }
        })
        setInputValue(arr)
    }
    useEffect(()=>{
        refs.current[0].current.focus()
    },[refs])
    return (
        <div className="login-div">
            {JSON.stringify(code)}
            <Row className="m-0 login-row">
                <Col
                    md={6}
                    style={{
                        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
                    }}
                    className="img-col">
                    <div className="title-wrap">
                        <h3 className="bits-head">BitsCoops</h3>
                        <p>Automated cooperative society system</p>
                        <input
                            type="submit"
                            onClick={() => history.push('/auth/login')}
                            className="goto"
                            name=""
                            value={`Goto Log in`}
                        />
                    </div>
                </Col>
                <Col md={6} className="login-col">
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Form>
                                <Card className="login-card shadow-lg">
                                    <CardBody className="">
                                        <p className="login-text">Enter Code</p>
                                        <hr style={{ padding: 0 }}></hr>
                                        <div className="form-group text-center" style={{ paddingTop: 7 }}>
                                            {inputValue.map((item,index) =>
                                                <input
                                                    type="text"
                                                    className="code_input text-center"
                                                    maxLength='1'
                                                    value ={item.value}
                                                    onChange={({target:{value}})=>{
                                                        onChange(index,value)
                                                    }}
                                                    ref={refs.current[index]}
                                                />
                                            )}

                                        </div>
                                        <div className='text-center'>
                                            <button className="auth-btn">
                                                {loading && <Spinner size="sm" />}
                                                Save and login
                                            </button>
                                            <hr style={{ padding: 0 }}></hr>
                                            <p
                                                style={{
                                                    fontSize: '13px',
                                                    marginTop: 5,
                                                    color: 'grey',
                                                }}
                                            >
                                                Already a member?{' '}
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "rgb(96, 14, 230)",
                                                    }}
                                                    onClick={() => history.push("/auth/login")}
                                                >
                                                    Login here!
                                                </span>{' '}
                                            </p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
