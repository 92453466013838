import { _updateApi, _fetchApi, _postApi } from "redux/actions/api";

export function getLoanDetails(
  { society_id = "", loan_id = "" },
  callback = (f) => f,
  error = (f) => f
) {
  _fetchApi(
    `/loan-application/details/${loan_id}/${society_id}`,
    callback,
    error
  );
}

export function approveLoan(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/loan-application/approval`, data, callback, error); 
  
}

export function disburseLoan(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/loan-application/disburse`, data, callback, error);
}

export function getLoanSchedule(
  { loan_id = "", society_id = "" },
  callback = (f) => f,
  error = (f) => f
) {
  _fetchApi(
    `/loan-application/repayment-schedule/${loan_id}/${society_id}`,
    callback,
    error
  );
}

export function getLoanScheduleByStatus(
  { user_id = "", society_id = "", check = "" },
  callback = (f) => f,
  error = (f) => f
) {
  _fetchApi(
    `/loan-application/repayment-schedule-by-status/${user_id}/${society_id}?check=${check}`,
    callback,
    error
  );
}

export function newLoanRepayment(
  data = {},
  callback = (f) => f,
  error = (f) => f
) {
  _postApi("/loan-repayment/new-payment", data, callback, error);
}

export function newLoanRecovery(
  data = {},
  callback = (f) => f,
  error = (f) => f
) {
  _postApi("/loan-recovery/new", data, callback, error);
}
