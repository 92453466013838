import React, { useState, useCallback, useEffect } from "react";
import VoteForm from "./VoteForm";
import Result from "./Result";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { useHistory } from "react-router";
import { CustomButton } from "components/UI";
import { ButtonGroup } from "reactstrap";
import { useSelector } from "react-redux";
import { _postApi } from "redux/actions/api";
import { _customNotify } from "utils/helpers";
import { _warningNotify } from "utils/helpers";
import DaterangeSelector from "components/UI/DaterangeSelector";
import moment from "moment";
import { today } from "utils/helpers";

const Vote = () => {
  useEffect;
  const [tab, setTab] = useState(1);
  const goto = useHistory();
  const { society } = useSelector((a) => a.auth);
  const [data, setData] = useState([]);
  const _from = moment(today).subtract(6, "months").format("YYYY-MM-DD");

  const [form, setForm] = useState({
    from: _from,
    to: today,
  });

  let newStatus = tab === 1 ? "in progress" : tab === 2 ? "closed" : 'pending';
  const query_type = tab === 3 ? "select_all" : "select";
  const { from, to } = form;
  const getData = useCallback(() => {
    _postApi(
      `/vote-candidate?query_type=${query_type}&from=${from}&to=${to}`,
      { society_id: society.id, status: newStatus },
      (data) => {
        setData(data.results);
      },
      (err) => {
        _warningNotify("Error Occur");
      }
    );
  }, [society.id, tab, newStatus, query_type, form]);

  const handleRange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const update = (status, val) => {
    const updatePromises = val.map((item) => {
      const obj = { ...item, status };
      return _postApi(
        `/vote-candidate?query_type=update`,
        obj,
        (res) => {
          console.log(res);
        },
        (err) => {
          _warningNotify(err.message);
        }
      );
    });

    // Wait for all updates to complete
    Promise.all(updatePromises)
      .then(() => {
        _customNotify(`Vote is now ${status}`);
        getData(); // Assuming you want to refresh the data after all updates
      })
      .catch((err) => {
        console.error("Error during update:", err);
      });
  };


  return (
    <div>
      <div className="d-flex justify-content-between">
        <CustomButtonAdmin onClick={() => goto.push("/admin/votings/form")}>
          Create New Candidate
        </CustomButtonAdmin>
        {/* {JSON.stringify(society.role)} */}
        {society.role === "Admin" && (
          <div>
            <DaterangeSelector
              from={form.from}
              to={form.to}
              handleChange={handleRange}
              space={false}
              noLabel={true}
            />
          </div>
        )}

        <div className="mr-2">
          <ButtonGroup>
            <CustomButtonAdmin
              className="mr-0"
              outline={tab !== 3 ? true : false}
              onClick={() => {
                setTab(3);
              }}
            >
              Candidate List
            </CustomButtonAdmin>

            <CustomButton
              className="mr-0"
              outline={tab !== 1 ? true : false}
              onClick={() => {
                setTab(1);
              }}
            >
              Cast Your Vote
            </CustomButton>

            <CustomButton
              className="ml-0 mr-0"
              outline={tab !== 2 ? true : false}
              onClick={() => {
                setTab(2);
              }}
            >
              Check Result
            </CustomButton>
          </ButtonGroup>
        </div>
      </div>

      <VoteForm tab={tab} data={data} update={update} getData={getData} />
      {/* {voted && <Result candidates={[]} />} */}
    </div>
  );
};

export default Vote;
