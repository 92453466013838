import React, { useEffect, useState } from 'react'
import { EmptyList } from '../Admin/ReportDetails'
import { Card, CardTitle } from 'reactstrap'
import { getContributionSchedule } from 'redux/actions/account'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import ScheduleCalendar from 'components/UI/ScheduleCalendar'
import useQuery from 'helpers/useQuery'

function ContributionSchedule() {
  const dispatch = useDispatch()
  const query = useQuery()
  const user_id =query.get("user_id")
  //   const query = useQuery()
  //   const loan_id = query.get('loan_id')
  const society = useSelector((state) => state.auth.society)
  const user = useSelector((state) => state.auth.user)
  const [scheduleData, setScheduleData] = useState({})

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  useEffect(() => {
    getContributionSchedule(
      { user_id:user_id?user_id: user.id, society_id: society.id },
      (d) => {
        if (d && d.data && d.data.length) {
          let listByYear = {}
          d.data.forEach((sch) => {
            // console.log(monthNames[parseInt(sch.payment_month) - 1])
            let scheduleYear = sch.payment_year
            if (Object.keys(listByYear).includes(scheduleYear.toString())) {
              listByYear[scheduleYear] = [
                ...listByYear[scheduleYear],
                {
                  ...sch,
                  schedule_status: sch.paid > 0 ? 'paid' : 'skipped',
                  schedule_month: monthNames[parseInt(sch.payment_month) - 1],
                },
              ]
            } else {
              listByYear[scheduleYear] = [
                {
                  ...sch,
                  schedule_status: sch.paid > 0 ? 'paid' : 'skipped',
                  schedule_month: monthNames[parseInt(sch.payment_month) - 1],
                },
              ]
            }
          })
          console.log(listByYear)
          setScheduleData(listByYear)
        }
      },
    )
  }, [society.id])

  return (
    <Card body>
      <CardTitle className="h4 mt-0 mb-3 text-center">
        Contributions Table 
      </CardTitle>
      {scheduleData && Object.keys(scheduleData).length ? (
        <ScheduleCalendar data={scheduleData} hideUnpaid />
      ) : (
        <EmptyList message="No contribution data found." />
      )}
    </Card>
  )
}

export default ContributionSchedule
