import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getPurchaseItem,
  getTotalDrug,
  updateStock,
} from "redux/actions/inventory";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";
import { CustomButton, CustomTable, TextInput } from "components/UI";
// import { ReOderLevel } from "./drug/DrugAlerts";
import { formatNumber } from "utils/helpers";
import { GET_PURCHASE_ITEM } from "redux/actions/types";
import { _customNotify } from "utils/helpers";
import { _warningNotify } from "utils/helpers";
import Loading from "components/UI/Loading";
import { useHistory } from "react-router";
import CustomCard from "components/UI/CustomCard";
import { SearchBar } from "components/UI";
import { EmptyList } from "../Admin/ReportDetails";
import { getRoleLink } from "views/helper";

function DrugCount() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");
  const [reportType, setReportType] = useState("stock");
  const inventoryStore = useSelector(
    (state) => state?.inventory?.inventoryStore
  );
  const { user, society } = useSelector((state) => state.auth);
  const { purchaseItems } = useSelector((state) => state.inventory);
  let outOfStock = [];
  let lastDayOfMonth = moment().endOf("month").format("DD");
  const _today = moment().format("DD");
  let monthDiff = lastDayOfMonth - _today;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    from: 0,
    to: 300,
    store_name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  // const itemSearchRef = useRef();

  const rows = [];
  purchaseItems.forEach((p) => {
    if (
      p.drug_name &&
      p.drug_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else rows.push(p);
  });

  const onPageChanged = useCallback((event, page) => {
    event.preventDefault();
    setForm((p) => ({ from: (page - 1) * 100, to: page * 100 }));
    setCurrentPage(page);
  }, []);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value, query_type: "by_store" }));
  };

  const onEnableChange = (ind) => {
    const arr = [];
    rows?.forEach((state, index) => {
      if (index === ind) {
        arr.push({ ...state, enable: true });
        // console.log("LLLLLLLLLLLL", index, ind);
      } else {
        arr.push({ ...state, enable: false });
        // console.log("KKKKKKKKK", index, ind);
      }
    });
    dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
  };

  const onCancelChange = (ind) => {
    const arr = [];
    rows?.forEach((state, index) => {
      if (index === ind) {
        arr.push({ ...state, enable: false });
      } else {
        arr.push(state);
      }
    });
    dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
  };

  const _getPurchaseItem = useCallback(() => {
    setLoading(true);

    dispatch(
      getPurchaseItem(society.id, form.from, form.to, () => setLoading(false)),
      form.query_type
    );
    dispatch(getTotalDrug());
  }, [dispatch, form.from, form.to, form.store_name]);

  // useEffect(() => {
  //   dispatch(
  //     searchDrugSale(form.store_name, form.from, form.to, filterText, () =>
  //       setLoading(false)
  //     )
  //   );
  //   dispatch(getTotalDrug(filterText));
  //   // }
  // }, [filterText, form.from, form.to]);

  useEffect(() => {
    setLoading(true);
    // dispatch(getDrugList(society.id));
    _getPurchaseItem();
    setLoading(false);
  }, [_getPurchaseItem, dispatch, reportType]);

  const fields = [
    {
      title: "Action",
      custom: true,
      component: (item, index) => {
        if (item.enable) {
          return (
            <>
              <CustomButton
                size="sm"
                onClick={() => {
                  onCancelChange(index);
                }}
                className="m-1"
                color="danger"
              >
                x
              </CustomButton>
              <CustomButton
                size="sm"
                onClick={() => {
                  updateStock(
                    {
                      item_code: item.item_code,
                      drug_name: item.drug_name,
                      expiry_date: item.expiry_date,
                      store: item.store,
                      selling_price: item.selling_price,
                      balance: item.balance,
                      facilityId: society.id,
                    },
                    () => {
                      onCancelChange(index);
                      _customNotify("Updated Successfully");
                    },
                    () => {
                      _warningNotify("Error Occured");
                    }
                  );
                }}
                outline
              >
                Update
              </CustomButton>
            </>
          );
        } else {
          return (
            <>
              <CustomButton
                size="sm"
                onClick={() => {
                  onEnableChange(index);
                }}
              >
                Edit
              </CustomButton>
              <CustomButton
                disabled
                className="m-1"
                size="sm"
                onClick={() => {
                  history.push( 
                    getRoleLink(`/drug-purchase/drug-view?item_code=${item.item_code}&store=${item.store}&drug_name=${item.drug_name}&expiry_date=${item.expiry_date}`
                  ));
                }}
              >
                View
              </CustomButton>
            </>
          );
        }
      },
      className: "text-center no-print",
      headerClassName: "no-print",
    },
    {
      title: "S/N",
      custom: true,
      component: (item, index) => <div>{index + 1}</div>,
      className: "text-center print-only",
      headerClassName: "print-only",
    },
    {
      title: "GRN",
      value: "grn_no",
      custom: true,
      component: (item) => (
        <div
          style={{ cursor: "pointer", color: "royalblue" }}
          onClick={() =>
            history.push(
              getRoleLink(`/item-list/grn-view?grn_no=${item.grn_no}`)
            )
          }
        >
          {item.grn_no}
        </div>
      ),
      // className: 'text-center print-only',
      // headerClassName: 'print-only',
    },
    {
      title: "Item Name",
      value: "drug_name",
      custom: true,
      component: (item, index) => {
        if (item.enable) {
          return (
            <TextInput
              value={item.drug_name}
              className="text-right"
              name="drug_name"
              onChange={({ target: { name, value } }) => {
                const arr = [];
                rows?.forEach((state, ind) => {
                  if (index === ind) {
                    arr.push({ ...state, [name]: value });
                  } else {
                    arr.push(state);
                  }
                });
                dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
              }}
            />
          );
        } else {
          return item.drug_name;
        }
      },
    },
    {
      title: "Quantity",
      custom: true,
      component: (item, index) => {
        if (item.enable) {
          // && newDiff
          return (
            <TextInput
              value={item.balance}
              className="text-right"
              name="balance"
              onChange={({ target: { name, value } }) => {
                const arr = [];
                rows?.forEach((state, ind) => {
                  if (index === ind) {
                    arr.push({ ...state, [name]: value });
                  } else {
                    arr.push(state);
                  }
                });
                dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
              }}
            />
          );
        } else {
          return formatNumber(item.balance);
        }
      },
      className: "text-right",
    },
    {
      title: "Cost Price",
      custom: true,
      component: (item) => parseInt(item.selling_price) * parseInt(item.price),
    },
    {
      title: "Selling Price(₦)",
      custom: true,
      component: (item, index) => {
        if (item.enable) {
          return (
            <TextInput
              value={item.selling_price}
              className="text-right"
              name="selling_price"
              onChange={({ target: { name, value } }) => {
                const arr = [];
                rows?.forEach((state, ind) => {
                  if (index === ind) {
                    arr.push({ ...state, [name]: value });
                  } else {
                    arr.push(state);
                  }
                });
                dispatch({ type: GET_PURCHASE_ITEM, payload: arr });
              }}
            />
          );
        } else {
          return formatNumber(item.selling_price);
        }
      },

      className: "text-right",
    },

    // {
    //   title: "Supplier Name",
    //   custom: true,
    //   value: "supplier_name",
    //   // className: "text-center",
    // },
    {
      title: "Expiry Date",
      custom: true,
      component: (item) =>
        item.expiry_date === "1111-11-11" ? "-" : item.expiry_date,
      className: "text-center",
    },
  ];

  const printFunction = (value) => {
    setReportType(value);
    setTimeout(() => {
      if (reportType === "out_of_stock") {
        window.frames["print_frame"].document.body.innerHTML =
          document.getElementById("print-count").innerHTML;
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
      } else if (reportType === "priceList") {
        window.frames["print_frame"].document.body.innerHTML =
          document.getElementById("print-count").innerHTML;
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
      } else if (reportType === "stock") {
        window.frames["print_frame"].document.body.innerHTML =
          document.getElementById("print-count").innerHTML;
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
      }
    }, 500);
  };
  const data = reportType === "out_of_stock" ? outOfStock : purchaseItems;
  const totalSelling = purchaseItems?.reduce(
    (a, b) => a + parseInt(b.selling_price * b.balance),
    0
  );
  const totalCost = purchaseItems?.reduce(
    (a, b) => a + parseInt(b.price * b.balance),
    0
  );
  // const _priceList = reportType === "priceList";
  // const _outOfStock = reportType === "out_of_stock";
  const _stock = reportType === "stock";

  return (
    <Row className="m-0">
      <Col className="px-1">
        {/* {JSON.stringify(newDiff)} */}
        {/* {JSON.stringify(purchaseItems)} */}
        <div>
          <CustomCard>
            <div className="d-flex justify-content-between">
              <div>
                <CustomButton
                  onClick={() =>
                    history.push(getRoleLink("/add-new-purchase"))
                  }
                >
                  Add New Item Count
                </CustomButton>
              </div>

              <div className="">
                <b className="mr-2">Total Cost: {formatNumber(totalCost)}</b> |
                <b className="ml-2">
                  Total Selling Price: {formatNumber(totalSelling)}
                </b>
              </div>
            </div>
            <div className="mt-2 mb-2 d-flex">
              <SearchBar
                container="col-md-8"
                filterText={filterText}
                onFilterTextChange={(v) => {
                  setFilterText(v);
                }}
                // _ref={itemSearchRef}
                placeholder="Search by item name or expiry date"
              />
              {/* <SelectInput
                placeholder="Select by Store"
                options={["All"]}
                onChange={handleChange}
                value={form.store_name}
                name="store_name"
              /> */}
            </div>
            <div style={{ height: "65vh", overflow: "scroll" }}>
              {loading && <Loading size="sm" />}
              <CustomTable size="sm" bordered fields={fields} data={rows} />
              {rows?.length ? null : (
                <EmptyList message="Item list is empty, check back later." />
              )}
              {/* <div id="print-count">
                <PrintWrapper title="Stock List" footer={false}>
                  <Table className="print-only">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>{_stock ? "Selling Price" : "Cost Price"}</th>
                        {_stock && (
                          <>
                            <th>Balance</th>
                            <th>Expired</th>
                            <th>Missing</th>
                            <th>Excess</th>
                            <th>Damage</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.drug_name}</td>
                            <td>{_stock ? item.selling_price : item.price}</td>
                            {_stock && (
                              <>
                                <td className="text-right">{item.balance}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </PrintWrapper>
              </div> */}
            </div>
          </CustomCard>
          {/* <iframe
            title="print-count"
            name="print_frame"
            width="0"
            height="0"
            src="about:blank"
            // style={styles}
          /> */}
        </div>
      </Col>
    </Row>
  );
}

export default DrugCount;
