import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import CustomCard from "components/UI/CustomCard";
import { formatNumber } from "components/UI/helpers";
import useQuery from "helpers/useQuery";
import React, { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { getRoleLink } from "views/helper";
import { getLoanTypesList } from "./api";

function LoanSetupForm() {
  const history = useHistory();

  const [form, setForm] = useState({ finetype: "Fixed", guarantor: "Yes" });
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const id = query.get("id");
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);

  const handleChange = ({ target: { name, value } }) =>
    setForm((a) => ({ ...a, [name]: value }));

  const getLoanDetails = useCallback(() => {
    _postApi(
      `/new/loan-new?query_type=select`,
      { id, society_id: society.id },
      (response) => {
        if (response.success) {
          let d = response.data[0];
          setForm(d);
        }
      },
      (error) => {
        toast.error("Error getting Loan!");
      }
    );
  }, [society.id, id]);

  const updateLoan = () => {
    _postApi(
      `/new/loan-new?query_type=update`,
      form,
      (response) => {
        if (response.success) {
          toast.success("Update Successfully");
          history.push(getRoleLink("/setup-loan-types"));
          getLoanTypesList((d) => {});
        }
      },
      (error) => {
        toast.error("Error getting Loan!");
      }
    );
  };
  useEffect(() => {
    if (id) {
      getLoanDetails();
    }
  }, [getLoanDetails]);

  const formFields = [
    {
      name: "type",
      value: form.type,
      label: "Loan Type",
      type: "select",
      options: ["Normal", "Special"],
      required: true,
    },
    {
      name: "loan_title",
      value: form.loan_title,
      label: "Loan Title",
      required: true,
    },
    // {
    //   name: 'min_amount',
    //   value: form.min_amount,
    //   label: `Minimum Amount (${formatNumber(form.min_amount)})`,
    //   required: true,
    // },
    {
      name: "max_amount_type",
      type: "select",
      options: ["Fixed", "Percentage"],
      value: form.max_amount_type,
      label: "Maximum Amount Type",
      required: true,
    },
    {
      name: "max_amount",
      value: form.max_amount,
      label: `Maximum Amount (${
        form.max_amount_type === "Percentage"
          ? form.max_amount + "% of savings"
          : formatNumber(form.max_amount)
      })`,
      required: true,
    },
    {
      name: "interest_rate",
      value: form.interest_rate,
      label: "Interest Rate",
      required: true,
    },
    {
      name: "interest_rate_type",
      value: form.interest_rate_type,
      label: "Interest Rate Type",
      type: "select",
      options: ["Percentage", "Fixed"],
      required: true,
    },
    {
      name: "period_value",
      value: form.period_value,
      label: "Period",
      required: true,
    },
    {
      name: "period_duration",
      value: form.period_duration,
      label: "Period Duration",
      required: true,
      type: "select",
      options: ["Months"],
      // options: ['Months', 'Years'],
    },
    {
      name: "finetype",
      value: form.finetype,
      label: `Fine Type`,
      required: true,
      type: "select",
      options: ["Fixed", "Percentage"],
      col: 4,
    },
    {
      name: "admin_charges",
      value: form.admin_charges,
      label: `Fine ${formatNumber(form.admin_charges)}`,
      required: true,
      type: "number",
      col: 4,
    },
    {
      name: "guarantor",
      value: form.guarantor,
      label: `Guarantor Needed?`,
      type: "select",
      options: ["Yes", "No"],
      required: true,
      col: 4,
    },
  ];

  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      "/loan-setup/new",
      { ...form, society_id: society.id, created_by: user.id },
      (d) => {
        setLoading(false);
        if (d && d.success) {
          toast.success(d.message);
          history.push(getRoleLink("/setup-loan-types"));
        } else {
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };

  return (
    <>
      <BackButton />
      <Card body>
        {/* {JSON.stringify(id)} */}
        <CardTitle className="h4 text-center mb-4">Setup New Loan</CardTitle>
        <CustomForm fields={formFields} handleChange={handleChange} />
        <center>
          <CustomButton
            loading={loading}
            onClick={id ? updateLoan : handleSubmit}
          >
            {id ? "Update" : "Submit"}
          </CustomButton>
        </center>
      </Card>

      <Toaster />
    </>
  );
}

export default LoanSetupForm;
