import { CustomButton } from "components/UI";
import { CustomCotrolCard } from "components/UI/CustomCard";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdUpdate } from "react-icons/md";
import { useSelector } from "react-redux";
import { FormGroup } from "reactstrap";
import { _updateApi } from "redux/actions/api";

const NextOfKin = () => {
  const profile = useSelector((state) => state.auth.user);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setForm(profile);
  }, [profile]);
  const handleSubmit = () => {
    const userId = form.id;
    setLoading(true);
    _updateApi(
      `/users/${userId}`,
      form,
      () => {
        setLoading(false);
        toast.success("Profile Updated");
      },
      (err) => {
        setLoading(false);
        toast.error("An error occured");
        console.log("caught error", err);
      }
    );
  };
  return (
    <CustomCotrolCard title="Next Of Kin Details">
      <div className="pl-3 pr-3 pb-1 pt-1">
        <h5>
          <b>First Next of Kin</b>
        </h5>
        <FormGroup row>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Name </span>
            <input
              onChange={(e) => handleChange("nok1_name", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok1_name}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Relationship</span>
            <input
              onChange={(e) => handleChange("nok1_relationship", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok1_relationship}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Address</span>
            <input
              onChange={(e) => handleChange("nok1_address", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok1_address}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Phone Number</span>
            <input
              onChange={(e) => handleChange("nok1_phone_no", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok1_phone_no}
            />
          </div>
        </FormGroup>
        <h5>
          <b>Second Next of Kin</b>
        </h5>
        <FormGroup row>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Name </span>
            <input
              onChange={(e) => handleChange("nok2_name", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok2_name}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Relationship</span>
            <input
              onChange={(e) =>
                handleChange("nok2_relationship", e.target.value)
              }
              className="form-control"
              type="text"
              value={form.nok2_relationship}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Address</span>
            <input
              onChange={(e) => handleChange("nok2_address", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok2_address}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xl-6">
            <span className="">Phone Number</span>
            <input
              onChange={(e) => handleChange("nok2_phone_no", e.target.value)}
              className="form-control"
              type="text"
              value={form.nok2_phone_no}
            />
          </div>
        </FormGroup>
        <center>
          <CustomButton
            color="primary"
            className="m-2"
            loading={loading}
              onClick={handleSubmit}
          >
            <MdUpdate size={20} className="mr-1" />
            Update Details
          </CustomButton>
        </center>
      </div>
    </CustomCotrolCard>
  );
};

export default NextOfKin;
