import { CustomButton } from "components/UI";
import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";

function Swicht() {
  const history = useHistory();
  const location = useLocation();
  const admin = location?.pathname?.includes("/admin/");

  return (
    <div className="mr-2 mt-1">
      <ButtonGroup>
        <CustomButton
          className="mr-0"
          outline={!admin ? true : false}
          onClick={() => {
            history.push(`/admin/dashboard`);
          }}
        >
          Admin
        </CustomButton>
        <CustomButton
          className="ml-0"
          outline={admin ? true : false}
          onClick={() => {
            history.push(`/member/dashboard`);
          }}
        >
          Member
        </CustomButton>
      </ButtonGroup>
    </div>
  );
}

export default Swicht;
