import { Switch, Route } from "react-router";
import BankDetailsList from "./BankDetailsList";
import NewAccountForm from "./NewAccountForm";
// import ViewAccountReport from "./ViewAccountReport";

function BankDetails() {
  return (
    <Switch>
      <Route exact path={"/admin/bank-accounts"} component={BankDetailsList} />
      <Route
        exact
        path={"/admin/bank-accounts/new"}
        component={NewAccountForm}
      />
      <Route exact path={"/member/bank-accounts"} component={BankDetailsList} />
      {/* <Route
        exact
        path={"/member/bank-accounts/new"}
        component={NewAccountForm}
      /> */}
      {/* <Route
        exact
        path={"/admin/setup-account/view-report"}
        component={ViewAccountReport}
      /> */}
    </Switch>
  );
}

export default BankDetails;
