import { combineReducers } from "redux";
import accountReducer from "./account";
import authReducer from "./auth";
import inventoryReducer from "./inventory";
import reportsReducer from "./reports";
import societyReducer from "./society";

const rootReducer = combineReducers({
  auth: authReducer,
  society: societyReducer,
  reports: reportsReducer,
  account: accountReducer,
  inventory: inventoryReducer,
});

export default rootReducer;
