import BackButton from 'components/UI/BackButton'
import useQuery from 'helpers/useQuery'
import React, { useState } from 'react'
import { Tabs } from 'react-bootstrap'
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { themeClass } from 'variables'
import ViewMember from '../membership/View_members'
import MemberSaving from './MemberSaving'
// import { _fetchApi } from "redux/actions/api";
// import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
// import { _postApi } from "redux/actions/api";
// import MemberSaving from "./MemberSaving";
// import MemberShare from "./MemberShare";
// import MemberSpecialSaving from "./MemberSpecialSaving";
// import { useParams } from "react-router";
// import AllMembers from "./AllMembers";
// import AllMembers from "./AllMembers";

function MemberDetails() {
  const query = useQuery()
  const type = query.get('type')
  const user_id = query.get('id')
  const [activeTab, setActiveTab] = useState(1)
  // const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="mb-3 ml-10">
      <BackButton className="mb-3" />
      {user_id ? (
        <ViewMember />
      ) : (
        <Card className={themeClass} color="grey">
          <CardBody>
            <Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`active ${
                      activeTab === 1 && 'bg-primary-custom'
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    All Members
                  </NavLink>
                  <NavLink
                    className={`active ${
                      activeTab === 2 && 'bg-primary-custom'
                    }`}
                    onClick={() => setActiveTab(2)}
                  >
                    Saving members
                  </NavLink>
                  <NavLink
                    className={`active ${
                      activeTab === 3 && 'bg-primary-custom'
                    }`}
                    onClick={() => setActiveTab(3)}
                  >
                    Share members
                  </NavLink>
                  <NavLink
                    className={`active ${
                      activeTab === 4 && 'bg-primary-custom'
                    }`}
                    onClick={() => setActiveTab(4)}
                  >
                    Special Saving members
                  </NavLink>
                  <NavLink
                    className={`active ${
                      activeTab === 5 && 'bg-primary-custom'
                    }`}
                    onClick={() => setActiveTab(5)}
                  >
                    Loan members
                  </NavLink>
                </NavItem>

                <TabContent activeTab={activeTab.toString()}>
                  <TabPane tabId="1">
                    <MemberSaving type={'All share'} />
                  </TabPane>
                  <TabPane tabId="2">
                    <MemberSaving type={'Share'} />
                  </TabPane>
                  <TabPane tabId="3">
                    <MemberSaving type={'Special share'} />
                  </TabPane>
                  <TabPane tabId="4">
                    <MemberSaving type={'Loan members'} />
                  </TabPane>
                </TabContent>
              </Nav>
            </Row>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default MemberDetails
