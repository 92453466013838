import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle, FormGroup } from "reactstrap";
import { getBankListFW } from "redux/actions/account";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import toast, { Toaster } from "react-hot-toast";
import { CURRENCY } from "variables";
import { formatNumber } from "components/UI/helpers";
import { getRoleLink } from "views/helper";
export default function NewAccountForm() {
  const defaultForm = {
    description: "",
    account_no: "",
    account_status: "active",
    type: "",
    account_name: "",
    bank_name: null,
    bank_code: null,
  };
  const [form, setForm] = useState(defaultForm);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const society = useSelector((state) => state.auth.society);

  const history = useHistory();

  const tableFields = [
    // { title: "Head", value: "head" },
    // {
    //   title: "S/N",
    //   custom: true,
    //   component: (item) => <div>{item.length() + 1}</div>,
    //   value: "head",
    // },
    { title: "Description", value: "description" },
    { title: "Account Name", value: "account_name" },

    { title: "Account Number", value: "account_no" },
    { title: "Bank Name", value: "bank_name" },
  ];

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleAdd = () => {
    if (
      form.description &&
      form.description !== "" &&
      form.account_no &&
      form.account_no !== "" &&
      form.account_name &&
      form.account_name !== ""
    ) {
      setList((p) => [...p, form]);
      setForm(defaultForm);
    } else {
      // alert('Please complete the form')
      toast.error("Please complete the form");
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      `/bank_details/new?query_type=create`,
      {
        created_by: user.id,
        user_phone: user.phone,
        society_id: society.id,
        list: list,
      },
      (d) => {
        setLoading(false);
        if (d.success !== false) {
          toast.success(d.message);
          history.push(getRoleLink("/bank-accounts"));
        } else {
          toast.error(d.message);
        }
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">
          Setup new account
        </CardTitle>
        <AccountFormComponent
          form={form}
          setForm={setForm}
          handleChange={handleChange}
        />

        <center className="mb-4">
          <CustomButton onClick={handleAdd}>Add to list</CustomButton>
        </center>

        {list.length ? (
          <>
            <CustomTable fields={tableFields} data={list} />

            <CustomButton loading={loading} onClick={handleSubmit}>
              Submit
            </CustomButton>
          </>
        ) : null}
        <Toaster position="top-center" />
      </Card>
    </>
  );
}

export const AccountFormComponent = ({
  form = {},
  setForm = (f) => f,
  handleChange = (f) => f,
}) => {
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    getBankListFW((l) => setBanks(l.data));
  }, []);
  const formFields = [
    {
      label: "Type",
      type: "custom",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Type*</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>
              setForm((p) => ({ ...p, type: value }))
            }
            value={form.type}
            name="type"
          >
            <option>--select---</option>
            {[
              "Contribution",
              "Special Contribution",
              "Loan",
              "Shares",
              "Others",
            ].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
    // {
    //   label: "Head",
    //   value: form.head,
    //   name: "head",
    //   required: true,
    // },
    {
      label: "Description",
      value: form.description,
      name: "description",
      required: true,
    },

    {
      label: "Account Number",
      value: form.account_no,
      required: true,
      name: "account_no",
    },

    {
      label: "Account Name",
      value: form.account_name,
      name: "account_name",
      // required: 3,
    },
    {
      type: "custom",
      // col: 3,
      component: (item) => (
        <FormGroup>
          <label className="font-weight-bold">Bank Name</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) => {
              let code = value.split(",")[0];
              let name = value.split(",")[1];
              setForm((p) => ({ ...p, bank: code, bank_name: name }));
            }}
          >
            <option>--select---</option>
            {banks.map((item, index) => (
              <option key={index} value={[item.code, item.name]}>
                {item.name}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
  ];

  return <CustomForm fields={formFields} handleChange={handleChange} />;
};
