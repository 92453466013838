import React, { useCallback, useState } from "react";
import { Card, Row, Col, Spinner } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actions/auth";
import "assets/css/register.css";
import { apiURL } from "variables";
import cover from "../../../images/hands2.jpg";
import toast from "react-hot-toast";
import SuccessAlert from "components/UI/SuccessAlert";

const coopTypes = [
  "Housing Cooperative Society",
  "Credit  Cooperative Society",
  "Farmer's Cooperative Society",
  "Worker's Cooperative Society",
  "Consumer's Cooperative Society",
  "Marketing  Cooperative Society",
  "Producer's  Cooperative Society",
  "Multi Purpose",
  "Others",
];

const AdminForm = () => {
  const dispatch = useDispatch();
  const initForm = {
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    role: "Admin",
    society_type: "Organization",
    society_address: "",
    society_phone: "",
    society_email: "",
    form_type: "Signup",
  };
  const [form, setForm] = useState(initForm);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successLoading, setSuccessLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { society } = useSelector((s) => s.auth);
  const fields = [
    {
      name: "society_name",
      value: form.society_name,
      //   required: true,
      type: "text",

      label: "Name",
      col: 6,
    },
    {
      name: "society_phone",
      value: form.society_phone,
      //   required: true,
      type: "number",

      label: "Phone",
      col: 6,
    },
    {
      name: "society_type",
      value: form.society_type,
      //   required: true,
      type: "select",
      options: [
        "Structured Cooperative",
        "Semi-Structured Cooperative",
        "Famers' Cooperative",
      ],

      label: "Cooperative type",
      col: 6,
    },
    {
      name: "society_email",
      value: form.society_email,
      //   required: true,
      type: "email",

      label: "Cooperative mail",
      col: 6,
    },
    {
      name: "society_address",
      value: form.society_address,
      type: "textarea",

      label: "Office Address",
      col: 6,
    },
    {
      name: "society_logo",
      value: form.society_logo,
      type: "file",

      label: "Society Logo",
      col: 12,
    },
  ];

  const adminDetails = [
    {
      name: "firstname",
      value: form.firstname,
      //   required: true,
      type: "text",

      label: "First Name",
      col: 6,
    },
    {
      name: "lastname",
      value: form.lastname,
      //   required: true,
      type: "text",

      label: "Last Name",
      col: 6,
    },
    {
      name: "phone",
      form: form.phone,
      //   required: true,
      type: "number",

      label: "Phone number",
      col: 6,
    },
    {
      name: "email",
      form: form.email,
      //   required: true,
      type: "email",

      label: "Email",
      col: 6,
    },
    {
      name: "address",
      form: form.address,
      type: "textarea",

      label: "Home address",
      col: 6,
    },
    {
      name: "password",
      value: form.password,
      //   required: true,
      type: "password",

      label: "Password",
      col: 6,
    },
  ];

  const history = useHistory();

  const handleChange = ({ target: { value, name, files } }) => {
    if (name === "society_logo") {
      let fileLogo = files[0];
      setLogo(fileLogo);
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      form.society_type === "Organization" &&
      (form.society_address === "" ||
        // form.society_email === '' ||
        form.society_name === "")
    ) {
      alert("Incomplete form");
    }
    if (form.email === "" || form.phone_no === "" || form.password === "") {
      toast.error("Please complete the form");
    } else {
      form.query_type = "insert";
      let formData = new FormData();

      Object.keys(form).forEach((element, i) => {
        formData.append(element.toString(), form[element]);
      });
      formData.append("logo", logo);
      fetch(`${apiURL}/societies/create-society`, {
        method: "POST",
        body: formData,
      })
        .then((raw) => raw.json())
        .then((resp) => {
          console.log(resp);
          if (resp.success) {
            setLoading(false);
            
            // dispatch(
            //   login(
            //     { email: resp.user.email, password: form.password },
            //     () => {
            //       setLoading(false);
            //       setSuccessLoading(true);
            //       setMessage(resp.message);
            //     },
            //     () => {
            //       history.push("/auth/login");
            //     }
            //   )
            // );
            // history.push('/auth/login')
          } else {
            setLoading(false);
            toast.error(resp.message);
          }
          // alert(resp.message)
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    }
  };
;

  return (
    <div>
      <Row className="m-0">
        <Col
          md={6}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
          }}
          className="img-col"
        >
          <div className="title-wrap">
            {/* <h3 className="bits-reg">Register Here</h3> */}
            <h3 className="bits-head">BitCoops</h3>
            <p>Automated cooperative society system</p>
            <input
              type="submit"
              onClick={() => history.push("/auth/login")}
              className="goto"
              name=""
              value={`Goto Login`}
            />
          </div>
        </Col>
        <Col md={6} className="login-col">
          <div className="mobile-head">
            <h3 className="bits-head">BitCoops</h3>
            <p className="bits-slo">Automated cooperative society system</p>
          </div>
          <Row className="p-0 m-0">
            <Col md={1}></Col>
            <Col md={10}>
              <Card className="reg-card shadow-lg p-3">
                <p className="login-text">Register</p>
                <hr style={{ padding: 0 }}></hr>
                <div className="reg-col">
                  {/* {JSON.stringify(form)} */}
                  <>
                    {/* <h4 className="reg-sub-head text-center">
                        Association details
                      </h4> */}
                    <Row className="mt-4" style={{ marginBottom: 0 }}>
                      <Col md={6}>
                        {" "}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            id="Name"
                            name="society_name"
                            value={form.society_name}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Name"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Cooperative Name
                          </label> */}
                        </div>
                      </Col>

                      <Col md={6}>
                        {" "}
                        <div className="form-group">
                          <select
                            name="society_type"
                            className="sel"
                            value={form.society_type}
                            onChange={handleChange}
                          >
                            <option value="">
                              --select cooperative type--
                            </option>
                            {coopTypes.map((type, id) => (
                              <option value={type} key={id}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contribution"
                            id="contribution"
                            name="contribution"
                            value={form.contribution}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="contribution"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Monthly Contribution
                          </label> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            id="Phone"
                            name="society_phone"
                            value={form.society_phone}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Phone"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Cooperative Phone
                          </label> */}
                        </div>
                      </Col>
                      {/* <Col md={6}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Cooperative Mail"
                              id="Cooperative Mail"
                              name="society_email"
                              value={form.society_email}
                              onChange={handleChange}
                            />
                            <label
                              for="Cooperative Mail"
                              style={{ color: "rgb(160,160,160)" }}
                              className="form-label"
                            >
                              Cooperative Mail
                            </label>
                          </div>
                        </Col> */}

                      <Col md={6}>
                        {" "}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Office Address"
                            id="Office Address"
                            name="society_address"
                            value={form.society_address}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Office Address"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Office Address
                          </label> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Society Logo"
                            id="Society Logo"
                            value={form.society_logo}
                            onChange={handleChange}
                            name="society_logo"
                          />
                          {/* <label
                            for="Society Logo"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Society Logo
                          </label> */}
                        </div>
                        {/* <a href="#down">
                            <ArrowDown
                              size="2em"
                              style={{
                                margin: 0,
                                float: "right",
                                color: "white",
                                backgroundColor: "rgb(96, 14, 230)",
                                padding: 5,
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </a> */}
                      </Col>
                    </Row>

                    {/* <CustomForm fields={fields} handleChange={handleChange} /> */}

                    {/* <h5 className="text-center">
                        Admin details
                      </h5> */}

                    <Row>
                      <hr></hr>

                      <Col md={6}>
                        {" "}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="FirstName"
                            id="FirstName"
                            Name
                            name="firstname"
                            value={form.firstname}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="FirstName"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            First Name
                          </label> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="LastName"
                            id="LastName"
                            name="lastname"
                            value={form.lastname}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="LastName"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Last Name
                          </label> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {" "}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            id="Phone Number"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Phone Number"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Phone Number
                          </label> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            id="Email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Email"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Email
                          </label> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {" "}
                        <div
                          className="formName
-group"
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Home Address"
                            id="Home Address"
                            name="address"
                            value={form.address}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Home Address"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Home Address
                          </label> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            id="Password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                          />
                          {/* <label
                            for="Password"
                            style={{ color: "rgb(160,160,160)" }}
                            className="form-label"
                          >
                            Password
                          </label> */}
                        </div>
                      </Col>
                    </Row>

                    {/* <CustomForm
                        fields={adminDetails}
                        handleChange={handleChange}
                      /> */}
                    <center>
                      {/* <CustomButton
                          onClick={handleSubmit}
                          className="text-center btn-register"
                          id="down"
                        >
                          Register
                        </CustomButton> */}
                      <button className="auth-btn" onClick={handleSubmit}>
                        {loading && <Spinner size="sm" />}
                        Register
                      </button>
                    </center>
                  </>
                  <hr style={{ padding: 0 }}></hr>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: 5,
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Already a member?
                    <span
                      style={{
                        cursor: "pointer",
                        color: "rgb(96, 14, 230)",
                      }}
                      onClick={() => history.push("/auth/login")}
                    >
                      {" "}
                      Login!
                    </span>
                  </p>
                </div>
              </Card>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <div className="col-md-3 register-left">
		<img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
          <h3>Bits Cooperative</h3>
          <p>Automated cooperative society system</p>
          <input
            type="submit"
            onClick={() =>
              setForm((p) => ({
                ...p,
                form_type: p.form_type === "Login" ? "Signup" : "Login",
              }))
            }
            name=""
            value={`Goto ${form.form_type === "Login" ? "Signup" : "Login"}`}
          />
          <br />
        </div>
        <div className="col-md-9 register-right">
          {form.form_type === "Login" ? (
            <div className="login-card">
              <Form>
                <Row>
                  <Col md={3}></Col>
                  <Col md={6} className="text-left">
                    <Card>
                      <CardBody>
                        <div className="text-center">
                          <img
                            className="auth-logo"
                            style={{ color: "blue" }}
                            src={logo}
                            alt="Logo"
                          />
                        </div>
                        <CustomForm
                          fields={loginFields}
                          handleChange={handleChange}
                        />
                        <div className="text-center">
                          <br />
                          <Button
                            type="submit"
                            onClick={handleLogin}
                            className="text-center  btn-login"
                          >
                            Login
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </Form>
            </div>
          ) : (
            <Row>
              <Col className="card-bordered" md={6}>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <CardBody>
                      <h4 className="text-center text-primary">
                        Association details
                      </h4>
                      <CustomForm fields={fields} handleChange={handleChange} />
                    </CardBody>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <CardBody>
                      <h4 className="text-center text-primary">
                        Admin details
                      </h4>
                      <CustomForm
                        fields={adminDetails}
                        handleChange={handleChange}
                      />
                      <center>
                        <CustomButton
                          onClick={handleSubmit}
                          className="text-center btn-register"
                        >
                          Submit
                        </CustomButton>
                      </center>
                    </CardBody>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Row> */}
      <SuccessAlert
        confirm={() => {
          history.push(`/${society.role.toLowerCase()}/dashboard`);
        }}
        toggle={() => {
          setSuccessLoading(false);
        }}
        confirmText="Ok"
        message={message}
        successLoading={successLoading}
      />
    </div>
  );
};
export default AdminForm;
