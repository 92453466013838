import React from 'react'
import "./sm-navbar.css"
import { Button } from 'reactstrap';
import { getRoleLink } from "views/helper";
import { useHistory, useLocation } from "react-router";


export default function NavBarSm() {
       const location = useLocation()
      const history = useHistory();
      const isAdmin = location.pathname.includes("admin")

  return (
    <nav className="fixed-sm flex">
      <Button
        className={` nav-item ${
          location.pathname.includes("dashboard") ? "active" : ""
        }`}
        onClick={() => {
          history.push(getRoleLink(`/dashboard`));
        }}
      >
        <i className="fas fa-home"></i>
        Dashboard
      </Button>
      {isAdmin ? (
        <Button
          className={` nav-item ${
            location.pathname.includes("membership") ? "active" : ""
          }`}
          onClick={() => {
            history.push(getRoleLink(`/membership`));
          }}
        >
          <i className="fas fa-money-bill-wave"></i>
          Members
        </Button>
      ) : (
        <Button
          className={` nav-item ${
            location.pathname.includes("loan-repayment") ? "active" : ""
          }`}
          onClick={() => {
            history.push(getRoleLink(`/loan-repayment`));
          }}
        >
          <i className="fas fa-money-bill-wave"></i>
          Loan
        </Button>
      )}

      {isAdmin ? (
        <Button
          className={` nav-item ${
            location.pathname.includes("pending-member-payments")
              ? "active"
              : ""
          }`}
          onClick={() => {
            history.push(getRoleLink(`/pending-member-payments`));
          }}
        >
          <i className="fas fa-hand-holding-usd"></i>
          Payments
        </Button>
      ) : (
        <Button
          className={` nav-item ${
            location.pathname.includes("member-payment") ? "active" : ""
          }`}
          onClick={() => {
            history.push(getRoleLink(`/member-payments`));
          }}
        >
          <i className="fas fa-hand-holding-usd"></i>
          Payments
        </Button>
      )}
      <Button
        className={` nav-item ${
          location.pathname.includes("transaction-history") ? "active" : ""
        }`}
        onClick={() => {
          history.push(getRoleLink(`/transaction-history`));
        }}
      >
        <i className="fas fa-book"></i>
        Transactions
      </Button>
      <Button
        className={` nav-item ${
          location.pathname.includes("settings") ? "active" : ""
        }`}
        onClick={() => {
          history.push(getRoleLink(`/settings`));
        }}
      >
        <i className="fas fa-dharmachakra"></i>
        Settings
      </Button>
    </nav>
  );
}
