import React, { Component, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";

import routes from "routes/admin";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth";
import Notifications from "./Notifications";
import { getImageUrl } from "redux/actions/api";
import UserAvatar from "./UserAvatar";
import Swicht from "./Swicht";
import { _fetchApi } from "redux/actions/api";
import toast from "react-hot-toast";
import { Alert, UncontrolledAlert } from "reactstrap";
import { _postApi } from "redux/actions/api";

function Header() {
  const location = useLocation();
  const history = useHistory();
  const isMember = location?.pathname?.includes("/member/");
  const dispatch = useDispatch();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const { society, user } = useSelector((state) => state.auth);

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  const [form, setForm] = useState({
    date_from: "",
    date_to: "",
    message: "",
    id: id,
  });

  const fetchannouncement = () => {
    setLoading(true);
    _postApi(
      `/getannouncement?query_type=get&society_id=${society.id}`,
      {},
      (response) => {
        setLoading(false);
        if (response.success) {
          setAnnouncements(response.results);
        } else {
          // toast.e  rror(response.message || "Failed to fetch announcements.");
        }
      },
      (error) => {
        setLoading(false);
        // toast.error("Error fetching announcements.");
      }
    );
  };
  useEffect(() => {
    fetchannouncement();
  }, []);

  const newAnnounce = announcements[announcements?.length - 1];
  return (
    <Navbar bg="light" expand="md">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>

          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            <div className="d-flex flex-direction-row align-items-center">
              <img
                src={getImageUrl(society.society_logo)}
                alt="society_logo"
                className="mr-4 rounded"
                style={{ maxHeight: 60 }}
              />
              <h4 className="mt-3">{society.society_name}</h4>
            </div>
            {/* {JSON.stringify(society.society_logo)} */}
            {/* <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >
                <i className="nc-icon nc-palette"></i>
                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-zoom-split"></i>
                <span className="d-lg-block">SBrandearch</span>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          {/* {JSON.stringify(newAnnounce)} */}
          {isMember ? (
           
              <p className="text-center text-light">
                <UncontrolledAlert color="primary" fade={false}>
                  {newAnnounce?.message}
                </UncontrolledAlert>
              </p>
             
          ) : null}

          <Nav className="ml-auto flex-row" navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">Account</span>
              </Nav.Link>
            </Nav.Item> */}
            {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDeBrandfault()}
                >
                  Action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Another action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something else here
                </Dropdown.Item>
                <div className="divider"></div>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Separated link
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Nav.Item>
              {/* <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(logout(history))
                }}
              >
                <span className="no-icon">Log out</span>
              </Nav.Link> */}
            </Nav.Item>
            {society.role === "Admin" ? (
              <Nav.Item>
                <Swicht />
              </Nav.Item>
            ) : (
              ""
            )}
            <Nav.Item>
              <Notifications />
            </Nav.Item>
            <Nav.Item>
              <UserAvatar />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
