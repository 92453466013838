import { CustomTable } from "components/UI";
// import UseAutocomplete from "components/UI/Autocomplete";
import BackButton from "components/UI/BackButton";
import CustomCard from "components/UI/CustomCard";
import { _formatNumber } from "components/UI/helpers";
import { formatNumber } from "components/UI/helpers";
import StatusBadge from "components/UI/StatusBadge";
import useQuery from "helpers/useQuery";
import { data } from "jquery";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { _fetchApi } from "redux/actions/api";
import myLoans from ".";
import RepaymentSchedule from "../loanApplication/RepaymentSchedule";

function LoanDetails() {
  const query = useQuery();
  const loan_id = query.get("loan_id");
  const society = useSelector((state) => state.auth.society);
  const user = useSelector((state) => state.auth.user);
  const [loan, setLoan] = useState([]);

  const getMyLoan = useCallback(() => {
    if (loan.length < 1 && loan_id && society.id > 0) {
      _fetchApi(
        `/loan-application/details/${loan_id}/${society.id}`,
        (d) => {
          if (d && d.data) {
            setLoan(d.data);
          }
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }, [user.id, society.id]);

  useEffect(() => {
    getMyLoan();
  }, [getMyLoan]);

  return (
    <div>
      {/* {JSON.stringify(loan)} */}
      <BackButton />
      <Card>
        <CardHeader className="h4 mt-1">{`Loan Details (${loan_id})`}</CardHeader>
        <CardBody>
          <LoanDetailedTable loan={loan} />
          <RepaymentSchedule />
        </CardBody>
      </Card>
    </div>
  );
}

export function LoanDetailedTable({ loan = [] }) {
  const tableFields = [
    // { title: 'Loan ID', value: 'loan_id' },
    {
      title: "Application Date",
      custom: true,
      component: (i) => <div>{moment(i.created_at).format("YYYY-MM-DD")}</div>,
    },
    {
      title: "Member name",
      value: "member_name",
      custom: true,
      component: (i) => <div>{i.member_name}</div>,
    },
    {
      title: "Account name",
      value: "account_name",
      custom: true,
      component: (i) => <div>{i.member_name}</div>,
    },
    // {
    //   title: 'Bank name',
    //   value: 'bank_name',
    //   custom: true,
    //   component: (i) => <div>{i.bank_name}</div>,
    // },
    {
      title: "Loan Type",
      custom: true,
      component: (i) => <span>{i.loan_type}</span>,
    },
    {
      title: "Loan Amount",
      custom: true,
      component: (i) => (
        <div className="text-right">₦{_formatNumber(i.amount)}</div>
      ),
    },
    {
      title: "Loan Period",
      custom: true,
      component: (i) => (
        <div className="">
          {i.period_value} {i.period_duration}
        </div>
      ),
    },
    {
      title: "Loan status",
      custom: true,
      component: (i) => <StatusBadge status={i.status} />,
    },
    {
      title: "First Guarantor Approval",
      custom: true,
      component: (i) => <StatusBadge status={i.grantor1_status} />,
    },
    {
      title: "Second Guarantor Approval",
      custom: true,
      component: (i) => <StatusBadge status={i.grantor2_status} />,
    },
  ];
  return (
    <div>
      <Row>
        {loan.length ? (
          tableFields.map((item, idx) => (
            <Col md={4} className="">
              {item.custom ? (
                <div className="d-flex flex-direction-row align-items-center">
                  <p className="mr-2 font-weight-bold">{item.title}:</p>{" "}
                  <p>{item.component(loan[0])}</p>
                </div>
              ) : (
                <KeyVal key={item.title} value={loan[0][item.value]} />
              )}
            </Col>
          ))
        ) : (
          <></>
        )}
      </Row>
    </div>
    // <Table className="" bordered>
    //   {/* <thead>
    //     <tr>

    //     <th className="text-center">
    //             <strong>ITEM</strong>
    //         </th>
    //         <th lassName="text-center">
    //             <strong>VALUE</strong>
    //         </th>

    //     </tr>
    //   </thead> */}
    //   <tbody>
    //     {loan.length ? (
    //       tableFields.map((item, idx) => (
    //         <tr>
    //           <th scope="row"> {item.title}</th>
    //           {/* <th  scope="row"> {data[item.title]}</th> */}
    //           <td>
    //             {item.custom ? (
    //               <>{item.component(loan[0])}</>
    //             ) : (
    //               loan[0][item.value]
    //             )}
    //           </td>
    //         </tr>
    //       ))
    //     ) : (
    //       <></>
    //     )}
    //   </tbody>
    // </Table>
  );
}

function KeyVal({ key = "", value = "" }) {
  return (
    <div>
      <div className="mr-2">{key}:</div> {value}
    </div>
  );
}

export default memo(LoanDetails);
