import { _updateApi } from "redux/actions/api";

export function approvePendingPayments(
  req_id = "",
  data = {},
  callback = (f) => f,
  error = (f) => f
) {
  console.log(req_id);
  _updateApi(`/contribution/approval/${req_id}`, data, callback, error);
}

export function _approvePendingPayments(
  req_id = "",
  data = {},
  callback = (f) => f,
  error = (f) => f
) {
  console.log(req_id);
  _updateApi(
    `/contribution/aprove-schedule-loan/${req_id}`,
    data,
    callback,
    error
  );
}

export function rejectPendingPayments(
  society_id = "",
  data,
  callback = (f) => f,
  error = (f) => f
) {
  _updateApi(
    `/contribution/reject-payment-requests/${society_id}`,
    data,
    callback,
    error
  );
}
