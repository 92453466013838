import React, { useCallback, useEffect, useState } from "react";
import { BsBullseye } from "react-icons/bs";
import { useSelector } from "react-redux";
import { _postApi } from "redux/actions/api";
import { _customNotify } from "utils/helpers";
import { _warningNotify } from "utils/helpers";
import CustomButtonAdmin from "components/UI/CustomButtonAdmin";
import { UncontrolledAlert } from "reactstrap";

const VoteForm = ({
  tab,
  data = [],
  update = (f) => f,
  getData = (f) => f,
}) => {
  const { user } = useSelector((a) => a.auth);

  const voteNow = (item) => {
    let obj = { ...item, user_id: user.id };
    _postApi(
      `/vote-candidate?query_type=vote`,
      obj,
      (data) => {
        if (data.success) {
          _customNotify(`You voted for ${item.name}`);
          getData();
        } else {
          _warningNotify("You have already voted");
        }
      },
      (err) => {
        _warningNotify(err.message);
      }
    );
  };

  const separatedData = {};
  data?.forEach((item) => {
    const { position } = item;
    if (!separatedData[position]) {
      separatedData[position] = [];
    }
    separatedData[position].push(item);
  });
  const val =
    data.length && data
      ? data.filter(
          (item) => item.status !== "closed"
        )
      : {};
  const displayCondition = val[0]?.status === "pending" || val[0]?.status === "closed";
    return (
      <div className="mt-5">
        {/* {JSON.stringify(data)} */}
        {data.length ? (
          <UncontrolledAlert color="danger" style={{ borderRadius: "20px" }}>
            <h4>ALERT</h4>
            <p>
              Once the vote is cast, it can never be reverse again,Check your
              candidate very well and Click on the thumb icon to vote.
            </p>
          </UncontrolledAlert>
        ) : (
          <UncontrolledAlert color="danger" style={{ borderRadius: "15px" }}>
            <h4>
              Admin have not created any election yet, please contact admin or
              check back later
            </h4>
          </UncontrolledAlert>
        )}

        {tab === 3 && data.length && (
          <div className="mr-2 text-right">
            {/* <CustomButtonAdmin
              className="mr-0"
              color={displayCondition ? "success" : "danger"}
              outline={false}
              onClick={() => {
                update(displayCondition ? "in progress" : "closed", val);
              }}
            >
              {displayCondition ? "Click To Start Vote" : "Click To End Voting"}
            </CustomButtonAdmin> */}
            {/* <CustomButtonAdmin
                className="ml-0 mr-0"
                outline={tab !== 3 ? true : false}
                onClick={() => {
                  setTab(3);
                }}
              >
                Click To Stop Voting
              </CustomButtonAdmin> */}
          </div>
        )}
        <div className="row">
          {Object.keys(separatedData)?.map((position) => {
           let displayCondition = separatedData[position][0].status === "pending"
            // console.log(displayCondition, "asdfghjhgfd");
          return (
            <div key={position} className="col-md-4  border-right ">
              <div className="d-flex justify-content-around align-items-center py-3">

              <h5 className="font-weight-bold mb-0" onClick={() => console.log(separatedData[position])}>Candidates for {position}:</h5>
          {tab === 3 ? <CustomButtonAdmin
            className="mr-0"
            color={displayCondition ? "success" : "danger"}
            outline={false}
            onClick={() => {
              update(displayCondition ? "in progress" : "closed", separatedData[position]);
            }}
          >
            {displayCondition
              ? "Click To Start Vote"
              : "Click To End Voting"}
          </CustomButtonAdmin> : <></>}
              </div>
              {separatedData[position].map((candidate) => (
                <div
                  className="d-flex justify-content-between border-bottom py-2"
                  key={candidate.id}
                >
                  <h5>{candidate.name}</h5>
                  <div className="d-flex justify-content-between">
                    <h5 className="mr-5 font-weight-bold">{candidate.votes}</h5>

                    {tab === 1 ? (
                      <BsBullseye
                        size={30}
                        color="white"
                        style={{
                          backgroundColor: "#00b",
                          borderRadius: "10px",
                          cursor: "pointer",
                          padding: "6px",
                        }}
                        onClick={() => (tab === 1 ? voteNow(candidate) : null)}
                      />
                    ) : (
                      <h5>{candidate.status}</h5>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )})}
        </div>
      </div>
    );
};

export default VoteForm;
